import React from 'react'
import Cards from '../../packages/cards3'
import Location from '../../models/location'
import Entity from '../../models/entity'
import { useGlobals } from '../../globals'
import './styles.scss'
import { Link } from "react-router-dom"
import { transformCategories } from './utils'
import useAppName from '../../hooks/useAppName'


export default function OrderCategories({ match, history }) {

  const globals = useGlobals()
  globals.breadcrumbs = "Categories"

  const appName = useAppName()

  // use Sandra Holding categories unless it's Rosemont
  const urlStoreId = Number(match.params.storeId)
  const storeId = urlStoreId===Entity.storeRosemont // 11
    // ? Entity.storeRosemont 
    // : Entity.companySandraHolding
    ? 380 // Rosemont Holiday Kiosk 
    : 379 // Sandra Holdings Holiday Kiosk

  const [cards, setCards] = React.useState(null)

  React.useEffect(() => {
    async function fetchData() {
      let rows = await Location.getRows({ 
        locationTypeId: Location.locationTypeCategory, 
        entityId: storeId,
        isActive: true,
      }, true) // cached
      rows = transformCategories(rows) // filter, rename, sort categories
      setCards(rows)
    }
    fetchData()
  }, [])

  function clickCard(event) {
    const categoryId = Number(event.currentTarget.id)
    history.push(`/${appName}/items/${categoryId}`) // /order or /wishlist
  }

  return (
    <div className="OrderCategories">
      <div className="header">
        Select a category to browse items:
      </div>
      <Cards
        cards={cards}
        CardComponent={CategoryCard} // defined below
        clickCard={clickCard}
      />
      <div className="continue">
        {/* <Link to="/order/favorites"> */}
        <Link to={`/${appName}/favorites`}>
          <button>Continue to Favorites</button>
        </Link>
      </div>
    </div>
  )
}

function CategoryCard({ card, clickCard, id, index }) {
  const imageUrl = card.exampleImageUrl
  return (
    <div className="card CategoryCard" onClick={clickCard} id={id}>
      <img src={imageUrl} alt="" onError={e=>{e.target.onerror = null; e.target.src="images/image.png"}} />
      <span className="CategoryCard-name">{card.name}</span>
    </div>
  )
}
