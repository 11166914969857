import React from 'react'
import { decorate, observable } from 'mobx'


//. better way to do this?
// class Globals {
//   user
//   breadcrumbs
//   order = {}
// }
// decorate(Globals, {
//   user: observable,
//   breadcrumbs: observable,
//   order: observable,
// })

// export const globals = new Globals()


export const globals = observable({
  user: null,
  breadcrumbs: null,
  order: {
    address: new Map(),
    items: new Map(),
  },
})



// define context to provide global instance to all components
// with the custom hook can just say const globals = useGlobals()
const GlobalContext = React.createContext(null)
export function GlobalProvider({ children }) {
  return (
    <GlobalContext.Provider value={globals}>
      {children}
    </GlobalContext.Provider>
  )
}

export function useGlobals() {
  return React.useContext(GlobalContext)
}
