// fns to help with xlsx/sheetjs

import XLSX from 'xlsx'

export function setFormat(worksheet, col, fmt, headerRows = 0) {
  const range = XLSX.utils.decode_range(worksheet['!ref'])
  for (let row = range.s.r + headerRows; row <= range.e.r; ++row) {
    const ref = XLSX.utils.encode_cell({ r: row, c: col })
    if (worksheet[ref] && worksheet[ref].t === 'n') {
      worksheet[ref].z = fmt
    }
  }
}

// note: only works with xlsx files, not xls or ods?
export function setWidth(worksheet, col, width) {
  if (!worksheet['!cols']) worksheet['!cols'] = []
  const range = worksheet['!cols']
  if (!range[col]) range[col] = {}
  range[col].wch = width // wch is width in characters
}
