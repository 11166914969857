// main app with routes

import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import 'normalize.css'
import { RecoilRoot } from 'recoil'

import { GlobalProvider } from '../../globals'
import Header from '../Header'
import Login from '../Login'
import PasswordReset from '../PasswordReset'
import Logout from '../Logout'
import About from '../About'
import Feedback from '../Feedback'
import Account from '../Account'
import EditCategory from '../EditCategory'
import InventoryCount from '../InventoryCount'
import InventoryBegin from '../InventoryBegin'
import InventoryDiscrepancies from '../InventoryDiscrepancies'
import InventoryReview from '../InventoryReview'
import MaintainCategories from '../MaintainCategories'
import MaintainInventories from '../MaintainInventories'
import MaintainItems from '../MaintainItems'
import MaintainEmployees from '../MaintainEmployees'
import MaintainStores from '../MaintainStores'
// import MaintainTrayTypes from '../MaintainTrayTypes'
import ShowDisplays from '../ShowDisplays'
import ShowItems from '../ShowItems'
import ShowStores from '../ShowStores'
import OrderHeader from '../OrderHeader'
// import OrderFooter from '../OrderFooter'
import OrderStart from '../OrderStart'
import OrderStores from '../OrderStores'
import OrderCategories from '../OrderCategories'
import OrderItems from '../OrderItems'
import OrderFavorites from '../OrderFavorites'
import OrderContact from '../OrderContact'
import OrderThankyou from '../OrderThankyou'
import Commissions from '../Commissions'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './styles.scss'


function NotFound() {
  return (
    <div>Not found</div>
  )
}

// inventory preview is same as inventory review,
// but with a flag to change behavior a bit. 
function InventoryPreview(props) {
  return InventoryReview({ ...props, preview: true })
}


const tallieoRoutes = (

  // need tabindex 0 here so will autofocus to this div - otherwise on safari ios
  // the menu won't open unless you click on something else first.
  <div className="app" tabIndex={0}>

    <Route path="/" component={Header} />
    {/* note: used to have a "contents" element here, but had problems with
    position:sticky on iOS - changed so the route component is responsible
    for setting overflow:auto etc. */}
    <>
      <Switch>
        <Route path="/" exact component={InventoryCount} />
        <Route path="/login" component={Login} />
        <Route path="/reset-password" component={PasswordReset} />
        <Route path="/about" component={About} />
        <Route path="/feedback" component={Feedback} />
        <Route path="/account" component={Account} />
        <Route path="/logout" component={Logout} />

        {/* //. make consistent url scheme - noun/verb, verb-noun, ? */}

        <Route path="/inventory/:inventoryId/begin" component={InventoryBegin} />
        <Route path="/inventory/:inventoryId/count" component={ShowItems} />
        <Route path="/inventory/:inventoryId/preview" component={InventoryPreview} />
        <Route path="/inventory/:inventoryId/review" component={InventoryReview} />
        <Route path="/inventory/discrepancies" component={InventoryDiscrepancies} />

        <Route path="/count/stores" component={ShowStores} />
        <Route path="/count/store/:storeId" component={ShowDisplays} />
        <Route path="/count/display/:displayId" component={ShowItems} />

        <Route path="/maintain-categories" component={MaintainCategories} />
        <Route path="/edit-category/:categoryId" component={EditCategory} />
        <Route path="/maintain-inventories" component={MaintainInventories} />
        <Route path="/maintain-items" component={MaintainItems} />
        <Route path="/maintain-employees" component={MaintainEmployees} />
        <Route path="/maintain-stores" component={MaintainStores} />
        <Route path="/commissions" component={Commissions} />
        {/* <Route path="/maintain-tray-types" component={MaintainTrayTypes} /> */}

        <Route component={NotFound} />
      </Switch>
    </>
  </div>
)

const orderFormRoutes = (
  <Route path="/order">
    <div className="orderApp">
      <OrderHeader />
      <div className="orderApp-contents">
        <Switch>
          <Route path="/order/stores" component={OrderStores} />
          <Route path="/order/categories/:storeId" component={OrderCategories} />
          <Route path="/order/items/:locationId" component={OrderItems} />
          <Route path="/order/favorites" component={OrderFavorites} />
          <Route path="/order/address" component={OrderContact} />
          <Route path="/order/thankyou" component={OrderThankyou} />
          <Route path="/order" component={OrderStart} />
        </Switch>
      </div>
    </div>
  </Route>
)

const wishlistRoutes = (
  <Route path="/wishlist">
    <div className="orderApp">
      {/* <OrderHeader /> */}
      <Route path="/wishlist" component={OrderHeader} />
      <div className="orderApp-contents">
        <Switch>
          <Route path="/wishlist/stores" component={OrderStores} />
          <Route path="/wishlist/categories/:storeId" component={OrderCategories} />
          <Route path="/wishlist/items/:locationId" component={OrderItems} />
          <Route path="/wishlist/favorites" component={OrderFavorites} />
          {/* <Route path="/wishlist/address" component={OrderContact} /> */}
          {/* <Route path="/wishlist/thankyou" component={OrderThankyou} /> */}
          {/* <Route path="/wishlist/:storeId" component={OrderStart} /> */}
          <Route path="/wishlist" component={OrderStart} />
        </Switch>
      </div>
    </div>
  </Route>
)


export default function App() {

  return (
    <RecoilRoot>
      <GlobalProvider>
        <Router>
          <Switch>
            {orderFormRoutes}
            {wishlistRoutes}
            {tallieoRoutes}
          </Switch>
        </Router>
      </GlobalProvider>
    </RecoilRoot>
  )
}
