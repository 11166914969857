// show an image enlarged

import React from 'react'
import { openDialog, closeDialog } from '../react-async-dialog'
import './styles.scss'


export default function showImage(title, message, imageUrl) {
  return new Promise(resolve => {
    openDialog(ShowImage, { title, message, imageUrl, resolve })
  })
}


function ShowImage({ title, message, imageUrl, resolve }) {
  const clickOK = () => {
    closeDialog()
    resolve({ ok: true })
  }
  function handleKeyPress(evt) {
    if (evt.key==="Enter" || evt.key==="Escape") {
      clickOK()
    }
  }
  return (
    <div className='showImage'>
      <h3>{title}</h3>
      <button className="close" onClick={clickOK}>x</button>
      <p>{message}</p>
      <div className="image">
        <img src={imageUrl} alt=""/>
      </div>
      <div className="showImage-buttons">
        <button id="ok" onClick={clickOK} autoFocus onKeyPress={handleKeyPress}>OK</button>
      </div>
    </div>
  )
}
