// used by both displaycount and inventorycount pages

import React from 'react'
import './styles.scss'


export default function ItemCard({
  card,
  highlighted,
  handlers,
  index,
  countMode,
  showButtons,
}) {

  const item = card
  const deltaStr = (item.delta > 0 ? '+' : '') + (item.delta || '')
  const finalCount = item.initialCount + item.delta
  const isDisplayMode = countMode==='display'
  const isInventoryMode = countMode==='inventory'
  // in inventory mode, we need to show the discrepancy including the 
  // LIVE display count value, NOT the fixed/frozen value in the inventory record.
  // const discrepancyStr = (item.countDiscrepancy > 0 ? '+' : '') + (item.countDiscrepancy || '')
  const discrepancyStr = (item.countDiscrepancyLive > 0 ? '+' : '') + (item.countDiscrepancyLive || '')
  
  return (
    <div
      className={"card" + (highlighted ? " highlighted" : "") + (item.delta < 0 ? " negative" : "")}
      id={item.id}
      data-index={index}
    >
      {isDisplayMode &&
        <span className={"card-delta" + (item.delta < 0 ? " negative" : "")}>
          {deltaStr}
        </span>
      }
      {isInventoryMode &&
        <span className={"card-discrepancy" + (item.countDiscrepancyLive < 0 ? " negative" : "")}>
          {discrepancyStr}
        </span>
      }
      <span className="card-image">
        <img src={item.imageUrl} alt="" onError={e=>{e.target.onerror = null; e.target.src="images/image.png"}}/>
      </span>
      <span className="card-code">{item.code}</span>
      <span className="card-finalCount">Count {finalCount}</span>
      {showButtons && <span className="card-buttons">
        <button
          data-id={item.id}
          data-count={finalCount}
          disabled={finalCount < 1}
          className="card-minus"
          onClick={handlers.clickMinus}
        >
          -
        </button>
        <button
          data-id={item.id}
          data-count={finalCount}
          className="card-plus"
          onClick={handlers.clickPlus}
        >
          +
        </button>
      </span>}
    </div>
  )
}
