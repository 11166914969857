import React from 'react'
import preval from 'preval.macro'
import { useGlobals } from '../../globals'
import pkg from '../../../package.json'
import './styles.scss'


// get build-time datetime value
const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`

export default function About() {
  const globals = useGlobals()
  globals.breadcrumbs = "About"
  return (
    <div className="About">
      <b>Tallieo</b> is an inventory-taking application designed for Pandora Jewelry.<br/>
      <br/>
      <b>Goal:</b><br/>
      <ul>
        <li>Reduce loss by tracking inventory quickly and accurately</li>
      </ul>
      <b>Features:</b><br/>
      <ul>
        <li>Easy to use card and spreadsheet-like interface</li>
        <li>Scan barcodes with phone or tablet camera</li>
      </ul>
      <br/>
      This is version {pkg.version}, built {dateTimeStamp}.<br/>
      <br/>
      You can reach us at <a href="mailto:info@tallieo.com">info@tallieo.com</a>, or by the feedback form <a href="/#/feedback">here</a>.
    </div>
  )
}
