import app from 'firebase/app';
import 'firebase/auth';
// import Entity from '../../models/entity'


const config = {
  apiKey: "AIzaSyDk0rRHGi4PQw9tCseUgLBgp9ZhcUqVBQc",
  authDomain: "tallieo.firebaseapp.com",
  databaseURL: "https://tallieo.firebaseio.com",
  projectId: "tallieo",
  storageBucket: "tallieo.appspot.com",
  messagingSenderId: "152780608045",
  appId: "1:152780608045:web:d8ac6bf12abc28b4619ea4"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
  }
  
  // *** Auth API ***
  createUser = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

  login = (email, password) => this.auth.signInWithEmailAndPassword(email, password)

  logout = () => this.auth.signOut();

  resetPassword = email => this.auth.sendPasswordResetEmail(email);
  
  changePassword = password => this.auth.currentUser.updatePassword(password);

  changeEmail = email => this.auth.currentUser.updateEmail(email);

  // deleteUser = uid => this.auth.deleteUser(uid);
}

export default Firebase;