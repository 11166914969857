import React from 'react'
import { Link } from "react-router-dom"
import Inventory from '../../models/inventory'
import * as grid from '../../packages/grid'
import * as lib from '../../lib'
import { useUser } from '../../services/user'
import alert from '../../packages/alert'
import confirm from '../../packages/confirm'
import Progress from '../../packages/progress'
import './styles.scss'


const breadcrumbs = "Inventory Maintenance"
const className = "MaintainInventories"

// get number of rows to show in grid for pagination
//. hardcoded for now, but it works
const appHeaderHeight = 41.33
const footerHeight = 2 * 36.67
const gridHeaderHeight = 32
const gridRowHeight = 28
const scrollbarHeight = 28
const pageSize = Math.floor((window.innerHeight - appHeaderHeight - footerHeight - gridHeaderHeight - scrollbarHeight) / gridRowHeight)


const columnDefs = [
  { field: 'storeName', headerName: "Store", width: 160, editable: false },
  { field: 'categoryName', headerName: "Category", width: 280, editable: false },
  { field: 'startTimestamp', width: 190, valueGetter: getStartDateTime, editable: false },
  { field: 'endTimestamp', width: 190, valueGetter: getEndDateTime, editable: false },
  { field: 'duration (mins)', width: 160, valueGetter: getDuration, editable: false, cellClass: 'right' },
  { field: 'statusId', headerName: "Status", width: 140, valueGetter: getStatus, editable: false },
  { field: 'action', width: 200, editable: false, cellRendererFramework: RenderAction },
]

function RenderAction(props) {
  const [user, updateUser] = useUser()
  if (props.node.group) return null
  const inventoryId = props.data && props.data.id
  const isCompleted = props.data.statusId === Inventory.statusCompleted
  const showDelete = !isCompleted || (isCompleted && user.canDeleteCompletedInventories)
  function clickReview() {
    props.context.history.push(`/inventory/${inventoryId}/review`)
  }
  async function clickDelete() {
    if (isCompleted && !user.canDeleteCompletedInventories) {
      await alert("No permission to delete completed inventories.")
      return
    }
    const newData = []
    props.api.forEachNode((node) => {
      if (node.data.id !== props.data.id) {
        return newData.push(node.data)
      }
    })
    if (await confirm("Delete Inventory", "Are you sure you want to delete this inventory? This cannot be undone.", "Yes, delete", "No")) {
      const ret = await Inventory.deleteRow({ inventoryId })
      if (ret) { //. make sure ret2 actually checks for error state
        props.api.setRowData(newData)
      }
    }
  }
  return (
    <span className="actions">
      {/* <Link to={`/inventory/${inventoryId}/review`} target="_blank" >
        <button>Review</button>
      </Link> */}
      <button onClick={clickReview}>Review</button>
      {showDelete && <button onClick={clickDelete}>Delete</button>}
    </span>
  )
}


function getStartDateTime(params) {
  return lib.getDateTime(params.data.startTimestamp)
}
function getEndDateTime(params) {
  return lib.getDateTime(params.data.endTimestamp)
}
function getDuration(params) {
  if (params.data.endTimestamp && params.data.startTimestamp) {
    const msecs = new Date(params.data.endTimestamp) - new Date(params.data.startTimestamp)
    return Math.round(msecs / 1000 / 60) // + " mins" // if add string here then sorting goes bad - need sort by value
  }
  return ''
}
function getStatus(params) {
  const status = Inventory.getStatusName(params.data.statusId)
  return status
}


const gridOptions = {
  columnDefs,
}

// function Header() {
//   return (
//     <div className="header">
//       Filter/paginate through inventories
//     </div>
//   )
// }


export default function MaintainInventories(props) {

  //. i turned this off as it gave an error once - the hardcoded version works alright
  // const gridElement = document.querySelector('div.grid-grid.ag-theme-balham')  
  // React.useEffect(() => {
  //   if (gridElement) {
  //     const gridHeight = gridElement.clientHeight
  //     pageSize = Math.floor(gridHeight / gridRowHeight)
  //   }
  // }, [gridElement])

  const [user, updateUser] = useUser(props.history)
  
  const storeIds = user.storeIds

  function Footer(props, gridApi, variables, data) {
    async function clickRemoveUnstaged() {
      if (!user.canClearUnstagedInventories) {
        await alert("No permission to remove unstaged inventories.")
        return
      }
      if (await confirm("Remove Unstaged Inventories", "Are you sure you want to remove all unstaged inventories? This cannot be undone.", "Yes, remove them", "No")) {
        const progress = new Progress("Remove Unstaged Inventories", "Removing inventories...", false)
        await Inventory.deleteUnstaged(storeIds, msg => progress.setStatus(msg))
        // remove from grid
        //. reset pagenum to 1 here also - how?
        const rows = await Inventory.getRows({ storeIds, offset: 0, limit: pageSize })
        gridApi.setRowData(rows)
        progress.close()
      }
    }
    return (
      <div className="footer">
        <button onClick={clickRemoveUnstaged}>Remove Unstaged Inventories...</button>
      </div>
    )
  }

  const g = grid.Grid(props, {
    gridOptions,
    Model: Inventory,
    Footer,
    breadcrumbs,
    className,
    pagination: pageSize,
    //. rather not have to pass offset+limit for first page
    variables: { storeIds, offset: 0, limit: pageSize },
  })

  if (!user) return null
  return g
}
