import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Helmet, HelmetProvider } from "react-helmet-async"
import { observer } from 'mobx-react-lite'
import { useGlobals } from '../../globals'
// import pandoraLogo from '../../assets/images/pandora_black.png'
import { MdFavoriteBorder as HeartOutline } from 'react-icons/md'
import { MdFavorite as HeartSolid } from 'react-icons/md'
import './styles.scss'
import { useRecoilValue } from 'recoil'
import { orderState, wishlistState } from '../../recoil/order'
import useAppName from '../../hooks/useAppName'


export default observer(() => {

  const globals = useGlobals()
  const history = useHistory()
  const location = useLocation()
  const appName = useAppName()

  const order = useRecoilValue(orderState)
  const wishlist = useRecoilValue(wishlistState)
  const { items } = appName==='order' ? order : wishlist
  const nitems = Object.keys(items).length

  const showBack = [
    "/order/store",
    "/order/categories",
    "/order/items",
    "/order/favorites",
    "/order/address",
    "/wishlist/items",
    "/wishlist/favorites",
  ].some(part => location.pathname.startsWith(part))

  function clickBack() {
    history.goBack()
  }

  return (
    <HelmetProvider>
      {appName==='wishlist' && <div className="superheader">
        WISHLIST
      </div>}
      <div className="OrderHeader">
        <Helmet>
          {appName==='order'
            ? <title>Pandora - Order Request</title>
            : <title>Pandora - Wishlist</title>
          }
        </Helmet>
        <span className="left">
          {/* <Link to="/order"><img src={pandoraLogo} alt=""/></Link> */}
          {showBack && <span className="link" onClick={clickBack}>&lt; Back | </span>}
          <span>{globals.breadcrumbs}</span>
        </span>
        <span className="right" title="Continue to Favorites">
          <Link to={`/${appName}/favorites`} className={nitems===0 ? 'disabled' : ''}>
            {/* <span>WISHLIST</span> */}
            <span className="favs" className={nitems>0 ? 'red' : 'gray'}>
              {nitems > 0 ? <HeartSolid /> : <HeartOutline />}
            </span>
          </Link>
          <span className="nitems">{nitems > 0 ? nitems : ''}</span>
        </span>
      </div>
    </HelmetProvider>
  )
})
