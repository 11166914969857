import React from 'react'
import { GoLinkExternal as IconLinkExternal } from 'react-icons/go'
import Inventory from '../../../models/inventory'
import * as lib from '../../../lib'



export default function InfoboxDetails({ item }) {
  return (
    <div className="infobox">
      <div className="barcode">SKU: {item.barcode}</div>
      <DisplayPreview item={item} />
      <PreviousInventory item={item} />
    </div>
  )
}

function DisplayPreview({ item }) {
  return (
    <div className="displayCounts">
      <div><strong>Display Counts</strong></div>
      {/* <table className="bordered-table"> */}
      <table>
        {/* <thead>
          <tr>
            <th>Name</th>
            <th>Number</th>
            <th>Count</th>
          </tr>
        </thead> */}
        <tbody>
          {item.displayCases.map(dc => (
            <tr key={dc.locationName+dc.locationNumber}>
              <td>{dc.locationName}</td>
              <td>{dc.locationNumber}</td>
              <td>{dc.currentCount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

function PreviousInventory({ item }) {
  const [lastInventory, setLastInventory] = React.useState(null)
  React.useEffect(() => {
    async function fetchInventory() {
      // need to get LAST inventory with this item at same store and category.
      // pass inventoryId so it knows where to start looking.
      const { inventoryId, itemId } = item
      const lastInventory = await Inventory.getLastInventoryForItem({ inventoryId, itemId })
      setLastInventory(lastInventory)
    }
    fetchInventory()
  }, [item])

  return (
    <div className="previousInventory">
      <div className="subtitle">Previous Inventory</div>
      {/* note: open inventory in another window and don't need to track in browser history */}
      <div>
        {lastInventory
          ? <a href={`/#/inventory/${lastInventory.inventoryId}/review`} target="_blank" rel="noopener noreferrer">
              Open&nbsp;
              <IconLinkExternal/>
            </a> 
          : "(None)"
        }
      </div>
      <table>
        <tbody>
          <tr>
            <td>End Date</td>
            <td>{lastInventory && lib.getDateTime(lastInventory.endTimestamp)}</td>
          </tr>
          <tr>
            <td>Discrepancy</td>
            <td>{lastInventory && lastInventory.countDiscrepancyFixed}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
