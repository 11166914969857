// dialog to get display or inventory count from user

//. uhh, split this into two dialogs - one for display, one for inventory

import React from 'react'
import SimpleKeyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import { withSize } from 'react-sizeme'
import { GoX as IconX } from 'react-icons/go'
import Popup from 'reactjs-popup'
import { openDialog, closeDialog } from '../../../packages/react-async-dialog'
import InfoboxDetails from './infoboxDetails'
import './styles.scss'

export default function getItemCountDialog(
  items,
  index,
  saveCount,
  canAdjustCount,
  countMode
) {
  console.log('getItemCountDialog', items, index)

  return new Promise(resolve => {
    function clickBackground(evt) {
      //. don't close dialog - need to get item somehow - pass index through dom? uhhhh
      // const el = document.querySelector(".getInventoryCount .trayCount span")
      // const count = Number(el.innerText)
      // // saveCount(item, count) //. need item - how get?
      // closeDialog()
      // resolve({ ok: true, count })
    }
    openDialog(
      GetItemCount,
      { items, index, saveCount, canAdjustCount, countMode, resolve },
      clickBackground
    )
  })
}

const keyboardLayouts = {
  numpad4: ['7 8 9', '4 5 6', '1 2 3', '{bksp} 0 {bksp}'],
}
const keyboardDisplay = {
  '{bksp}': '⌫',
  '{enter}': 'Enter',
}

const IconInfo = () => <span className="info">&#9432;</span>
const popupStyle = {
  width: '16em',
}

const withSizeHOC = withSize() // add size property
const GetItemCount = withSizeHOC(
  ({ items, index, saveCount, canAdjustCount, countMode, resolve, size }) => {
    console.log('GetItemCount', items, index)

    // hide/show left/right panels based on screen width
    // const showLeft = size.width > 900
    const showLeft =
      countMode === 'display' ? size.width > 700 : size.width > 900
    const showRight = countMode !== 'display' && size.width > 750
    const showInfoboxPopup = countMode === 'display' ? false : !showRight

    const [itemIndex, setItemIndex] = React.useState(index)
    const item = items[itemIndex]
    console.log('item', item)

    // make sure item exists - eg if scanned barcode
    // this shouldn't happen, so regular alert okay
    if (!item) {
      alert(`Barcode not in this inventory`)
      return null
    }

    const prevItem = itemIndex - 1 >= 0 ? items[itemIndex - 1] : null
    const nextItem = itemIndex + 1 < items.length ? items[itemIndex + 1] : null

    // const [count, setCount] = React.useState(item.trayOnHand)
    const initialCount =
      countMode === 'display' ? item.currentCount : item.trayOnHand
    const [count, setCount] = React.useState(initialCount)
    const [delta, setDelta] = React.useState(0) // change from initial value
    const [history, setHistory] = React.useState(' +' + count)
    const [value, setValue] = React.useState('') // keyboard value
    const keyboardRef = React.useRef(null)

    // const discrepancy = count + item.displayOnHand - item.externalOnHand
    // const countDiscrepancy = countMode==='display' ? 0 : (count + item.displayOnHand - item.externalOnHand)
    const countDiscrepancyLive =
      countMode === 'display'
        ? 0
        : count + item.displayCasesTotal - item.externalOnHand

    // when item changes, update history
    React.useEffect(() => {
      if (countMode === 'display') {
        setHistory(
          item.history || (item.currentCount ? ' +' + item.currentCount : '')
        )
      } else {
        setHistory(
          item.history || (item.trayOnHand ? ' +' + item.trayOnHand : '')
        )
      }
      setValue('')
    }, [item])

    function getHistoryTotal(history) {
      const values = history.split(' ')
      const count = values.reduce((a, b) => Number(a) + Number(b), 0)
      return count
    }

    // when history changes, update the count value
    React.useEffect(() => {
      const newCount = getHistoryTotal(history)
      setCount(newCount)
      item.history = history // save to items array also
    }, [history, item.history])

    // when current count changes, update delta
    React.useEffect(() => {
      setDelta(count - item.initialCount)
    }, [count, item.initialCount])

    // user added another digit to the keyboard value
    function onChange(value) {
      setValue(value)
    }

    function clickEnter() {
      if (value) {
        setHistory(history => history + ' +' + value)
        setValue('')
        keyboardRef.current.setInput('')
      }
    }

    // user hit undo - remove last value from history and recalculate count
    function clickUndo() {
      setHistory(history => {
        const i = history.lastIndexOf(' ')
        const newHistory = i >= 0 ? history.slice(0, i) : ''
        return newHistory
      })
    }

    function clickPrevious() {
      saveValues()
      setItemIndex(itemIndex => itemIndex - 1)
    }
    function clickNext() {
      saveValues()
      setItemIndex(itemIndex => itemIndex + 1)
    }

    // get new history and total count, save to item, clear value and input, save to db
    function saveValues() {
      const newHistory = history + (value ? ' +' + value : '')
      const newCount = getHistoryTotal(newHistory)
      setCount(newCount)
      setHistory(newHistory)
      item.history = newHistory // save to items array also
      setValue('')
      keyboardRef.current.setInput('')
      saveCount(item, newCount)
    }

    const canDecrease = count > 0

    function clickPlus() {
      setHistory(history => history + ' +1')
      setValue('')
      keyboardRef.current.setInput('')
    }

    function clickMinus() {
      setHistory(history => history + ' -1')
      setValue('')
      keyboardRef.current.setInput('')
    }

    function clickClose() {
      saveValues()
      closeDialog()
      resolve({ ok: true })
    }

    return (
      <div className="getItemCount">
        <div className="header">
          <div className="code">
            <span>
              Item {itemIndex + 1} of {items.length}: {item.code}
            </span>
            {showInfoboxPopup && (
              <Popup
                trigger={
                  <span>
                    &nbsp;
                    <IconInfo />
                  </span>
                }
                contentStyle={popupStyle}
              >
                <InfoboxDetails item={item} />
              </Popup>
            )}
          </div>
          <div className="name">{item.name}</div>
          <div className="close" onClick={clickClose}>
            <IconX />
          </div>
        </div>

        {/* contents div contains left, center, and right panes */}
        <div className="contents">
          {showLeft && (
            <div className="left">
              <img
                src={item.largeImageUrl}
                alt=""
                onError={e => {
                  e.target.onerror = null
                  e.target.src = 'images/image.png'
                }}
              />
            </div>
          )}

          <div className="center">
            <div className="top">
              {!showLeft && (
                <div className="image">
                  <img
                    src={item.largeImageUrl}
                    alt=""
                    onError={e => {
                      e.target.onerror = null
                      e.target.src = 'images/image.png'
                    }}
                  />
                </div>
              )}

              {countMode === 'display' ? (
                <div className="displayValues">
                  <div className={'delta' + (delta < 0 ? ' negative' : '')}>
                    Change {(delta > 0 ? '+' : '') + delta}
                  </div>
                  <div className="newAmount">Count {count}</div>
                </div>
              ) : (
                <div className="trayValues">
                  <table>
                    <tbody>
                      <tr>
                        <td>Tray count</td>
                        <td className="trayCount">{count}</td>
                      </tr>
                      <tr>
                        <td>Display count</td>
                        {/* <td>{item.displayOnHand}</td> */}
                        <td>{item.displayCasesTotal}</td>
                      </tr>
                      <tr className="totalInStore">
                        <td>Total in store</td>
                        {/* <td>{count + item.displayOnHand}</td> */}
                        <td>{count + item.displayCasesTotal}</td>
                      </tr>
                      <tr>
                        <td>Expected</td>
                        <td>{item.externalOnHand}</td>
                      </tr>
                      <tr>
                        <td>Discrepancy</td>
                        <td
                          className={
                            countDiscrepancyLive < 0
                              ? 'red'
                              : countDiscrepancyLive > 0
                              ? 'green'
                              : ''
                          }
                        >
                          {(countDiscrepancyLive > 0 ? '+' : '') +
                            countDiscrepancyLive}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className="middle">
              <div className="current-value">
                <button
                  className="undo"
                  onClick={clickUndo}
                  disabled={history.length === 0}
                >
                  {/* {history.length < 4 ? "Clear" : "Undo"} */}
                  Undo
                </button>
                <span className="value">{value}</span>
                <button
                  className="enter"
                  onClick={clickEnter}
                  disabled={!value || value.length === 0}
                >
                  Enter
                </button>
              </div>
              <div className="history">Count History: {history || 'None'}</div>
            </div>

            <div className="bottom">
              <div className="keypad">
                <SimpleKeyboard
                  keyboardRef={r => (keyboardRef.current = r)}
                  layout={keyboardLayouts}
                  layoutName="numpad4"
                  display={keyboardDisplay}
                  onChange={onChange}
                  mergeDisplay
                />
              </div>
              <div className="plusMinusButtons">
                <button
                  id="decrease"
                  onClick={clickMinus}
                  disabled={!canDecrease}
                >
                  -1
                </button>
                <button id="increase" onClick={clickPlus}>
                  +1
                </button>
              </div>
            </div>
          </div>
          {showRight && (
            <div className="right">
              <InfoboxDetails item={item} />
            </div>
          )}
        </div>

        <div className="footer">
          <button
            className="previous"
            onClick={clickPrevious}
            disabled={!prevItem}
          >
            <div>Previous</div>
            <img
              src={prevItem && prevItem.imageUrl}
              alt=""
              onError={e => {
                e.target.onerror = null
                e.target.src = 'images/image.png'
              }}
            />
          </button>
          <button className="next" onClick={clickNext} disabled={!nextItem}>
            <img
              src={nextItem && nextItem.imageUrl}
              alt=""
              onError={e => {
                e.target.onerror = null
                e.target.src = 'images/image.png'
              }}
            />
            <div>Next</div>
          </button>
        </div>
      </div>
    )
  }
)
