const structures = {
  sales: {
    // description: 'Meet minimum Dollar Sale Goal',
    // metric: 'Net Sales',
    description: 'Meet minimum Average Dollar Sale Goal',
    metric: 'Avg Sale',
    goal: '$5,000',
    commission: '0.5%',
    base: 'Net Sales',
  },
  cards: {
    description:
      // 'Meet minimum percentage of credit card applications (approved or declined)',
      'Meet minimum % of Pandora Credit Card Applications to Personal Transactions',
    metric: 'Pct Cards',
    goal: '4.5%',
    commission: '0.5%',
    base: 'Net Sales',
  },
}

export default structures
