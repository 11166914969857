// show a list of items as cards
// used to show items for display count and inventory count.

//. kind of backwards - should have made this more generic and
// called it from those pages.

import React from 'react'
import Entity from '../../models/entity'
import Location from '../../models/location'
import LocationItem from '../../models/locationItem'
import Inventory from '../../models/inventory'
import InventoryItem from '../../models/inventoryItem'
import DisplayCountHeader from './DisplayCountHeader'
import InventoryCountHeader from './InventoryCountHeader'
import getItemCountDialog from './getItemCountDialog'
import alertDialog from '../../packages/alert'
import confirm from '../../packages/confirm'
import { useGlobals } from '../../globals'
import { useUser } from '../../services/user'
import Cards from '../../packages/cards2'
import ItemCard from './ItemCard'
import './styles.scss'

// item property to filter on with the searchbox
const filterProperty = 'code'

export default function ShowItems({ match, history }) {
  // get ids from url
  const displayId = Number(match.params.displayId)
  const inventoryId = Number(match.params.inventoryId)
  const countMode = displayId ? 'display' : 'inventory'

  const globals = useGlobals()
  const [user] = useUser()

  // define state
  //. make this an array to highlight multiple changes
  const [highlightedItemId, setHighlightedItemId] = React.useState(null)

  // fetch items and set state
  //. catch and display errors and timeouts
  const [items, setItems] = React.useState(null) // null indicates data still being loaded
  const [totalExpectedNegative, setTotalExpectedNegative] = React.useState(0)
  const [totalTrayCount, setTotalTrayCount] = React.useState(0)
  React.useEffect(() => {
    async function fetchData() {
      if (countMode === 'display') {
        // display count mode

        const rows = await LocationItem.getRows({ locationId: displayId }) // fetch from db
        setItems(rows)

        // update breadcrumbs
        const display = await Location.getRow({ id: displayId })
        const store = await Entity.getRow({ id: display.entity.id })
        const company = store.entityParents[0].entityByParentid
        const breadcrumbs = [
          { name: 'Display Count - ' + company.name, key: `/count/stores` },
          { name: store.name, key: `/count/store/${store.id}` },
          { name: display.name },
        ]
        globals.breadcrumbs = breadcrumbs
      } else {
        // inventory count mode

        const rows = await InventoryItem.getRows({ inventoryId }) // fetch from db

        // sort by position then item code
        rows.sort((a, b) => (a.code < b.code ? -1 : a.code > b.code ? 1 : 0))
        rows.sort((a, b) =>
          a.position < b.position ? -1 : a.position > b.position ? 1 : 0
        )
        setItems(rows)

        // update breadcrumbs
        const inventory = await Inventory.getRow({ inventoryId })
        const storeName = inventory.store && inventory.store.name
        const categoryName =
          (inventory.category && inventory.category.name) || 'No Category'
        globals.breadcrumbs = `Inventory Count - ${storeName}: ${categoryName}`
      }
    }
    fetchData()
    // }, [countMode, displayId, inventoryId, globals.breadcrumbs]) // triggers refetch of db for no reason, clobbers the latest delta value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countMode, displayId, inventoryId]) //. why this warning? can ignore it?
  // tried this to get around warning but still there
  // function updateBreadcrumbs(breadcrumbs) {
  //   globals.breadcrumbs = breadcrumbs
  // }

  const [filter, setFilter] = React.useState('')
  const [filtered, setFiltered] = React.useState([])

  // when items or filter changes, update the filtered list of items
  React.useEffect(() => {
    if (items && filterProperty && filter) {
      const filterLowercase = filter.toLowerCase()
      const filtered = items.filter(item =>
        item[filterProperty].toLowerCase().includes(filterLowercase)
      )
      setFiltered(filtered)
    } else {
      setFiltered(items)
    }
    // update footer values
    if (items) {
      const totalExpectedNegative = items.reduce(
        (total, current) =>
          total + (current.externalOnHand < 0 ? current.externalOnHand : 0),
        0
      )
      const totalTrayCount = items.reduce(
        (total, current) => total + current.trayOnHand,
        0
      )
      setTotalExpectedNegative(totalExpectedNegative)
      setTotalTrayCount(totalTrayCount)
    }
  }, [items, filter])

  // ask user for count in a dialog -
  // this gets called from each card's onClick event, and from addStyle and scanner btn.
  // saveCount is then called from the dialog.
  // need to pass this to inventory header, so scanner btn can call it.
  async function getCount(index) {
    console.log('getCount', index)
    const canAdjustCount =
      countMode === 'display' ? user.canUpdateDisplayLog : true
    console.log(
      'calling getItemCountDialog',
      filtered,
      index,
      saveCount,
      canAdjustCount,
      countMode
    )
    await getItemCountDialog(
      filtered,
      index,
      saveCount,
      canAdjustCount,
      countMode
    )
  }

  // update count for given item
  // called from getInventoryCount and getDisplayCount dialogs
  async function saveCount(item, count) {
    console.log('saveCount', item, count)

    //. show spinner until done

    // bail if no change
    console.log('bail if no change')
    if (countMode === 'display') {
      if (count === item.currentCount) return
    } else {
      if (count === item.trayOnHand) return
    }

    // try to write to db
    console.log('try to write to db')
    let okay
    if (countMode === 'display') {
      console.log('call LocationItem.setItemCount', displayId, item.id, count)
      okay = await LocationItem.setItemCount(displayId, item.id, count)
    } else {
      console.log(
        'call InventoryItem.setItemCount',
        inventoryId,
        item.id,
        count
      )
      okay = await InventoryItem.setItemCount(inventoryId, item.id, count)
    }

    if (okay) {
      // if worked, update card positions and current item's delta and finalCount
      if (countMode === 'display') {
        item.currentCount = count
      } else {
        item.trayOnHand = count
        // item.countDiscrepancy = count + item.displayOnHand - item.externalOnHand
        // displayCasesTotal is the sum of the current display cases,
        // NOT the fixed amount stored in the inventory record. 2020-11-30 12pm
        // item.countDiscrepancy = count + item.displayCasesTotal - item.externalOnHand
        item.countDiscrepancyLive =
          count + item.displayCasesTotal - item.externalOnHand
      }
      item.delta = count - item.initialCount // value shown in upper left corner of card
      updateCards(item)
    } else {
      //. give more info - item, etc
      await alertDialog('Unable to update database - please try again.')
    }
  }

  // update current item's card with delta and total
  function updateCards(item) {
    const newItem = { ...item }
    const index = getItemIndex(item.id)
    if (index !== -1) {
      // card already in list - update delta
      const newItems = [
        ...items.slice(0, index),
        newItem,
        ...items.slice(index + 1),
      ]
      setItems(newItems)
    } else {
      // card not in list yet - add it to end //. or could sort by style
      const newItems = [...items, newItem]
      setItems(newItems)
    }
    // add green/red highlight around item -
    // a little confusing now with next/prev btns in dialog
    // setHighlightedItemId(item.id)
  }

  // long press card to remove item in display mode
  async function pressCard(evt) {
    if (countMode !== 'display') {
      return
    }
    if (!user.canUpdateDisplayLog) {
      const msg =
        "No permission to modify display log - need 'Admin' permission."
      await alertDialog(msg)
      return
    }
    const itemId = Number(evt.currentTarget.id)
    const item = getItem(itemId, true) // throws error if not found in list
    if (
      await confirm(
        'Remove Item',
        `Would you like to set ${item.code}'s count to zero AND remove the record from this display?`,
        'Yes, remove item',
        'No'
      )
    ) {
      if (await LocationItem.deleteRows({ locationId: displayId, itemId })) {
        const index = getItemIndex(itemId)
        if (index !== -1) {
          // card in list - remove it
          const newCards = [...items.slice(0, index), ...items.slice(index + 1)]
          setItems(newCards)
        }
      }
    }
  }

  // find an item in the current list of items - if not there returns -1
  function getItemIndex(itemId) {
    const index = items.findIndex(card => card.id === itemId)
    return index
  }

  // find an item in the current list of items - optionally throw error if not found
  function getItem(itemId, throwErrorIfMissing = false) {
    const item = items.find(item => item.id === itemId)
    if (item === undefined && throwErrorIfMissing) {
      throw new Error(
        `Missing item ${itemId} from list of display items - please report to admin.`
      )
    }
    return item
  }

  // user clicked on an item card
  function clickCard(event) {
    const index = Number(event.currentTarget.dataset.index)
    getCount(index)
  }

  function clickMinus(event) {
    event.preventDefault()
    event.stopPropagation()
    const id = Number(event.currentTarget.getAttribute('data-id'))
    const item = getItem(id)
    const count = Number(event.currentTarget.getAttribute('data-count'))
    saveCount(item, count - 1)
    item.history = item.history ? item.history + ' -1' : null
  }
  function clickPlus(event) {
    event.preventDefault()
    event.stopPropagation()
    const id = Number(event.currentTarget.getAttribute('data-id'))
    const item = getItem(id)
    const count = Number(event.currentTarget.getAttribute('data-count'))
    saveCount(item, count + 1)
    item.history = item.history ? item.history + ' +1' : null
  }

  const CardHeader =
    countMode === 'display' ? (
      <DisplayCountHeader
        displayId={displayId}
        items={items}
        saveCount={saveCount}
        filter={filter}
        setFilter={setFilter}
      />
    ) : (
      <InventoryCountHeader
        history={history}
        inventoryId={inventoryId}
        filter={filter}
        setFilter={setFilter}
        items={items}
        getCount={getCount}
      />
    )

  const CardFooter =
    countMode === 'display' ? null : (
      <div className="footer">
        <span>Total Expected Negative: {totalExpectedNegative}</span>
        <span>Total Tray Count: {totalTrayCount}</span>
      </div>
    )

  // make higher order component to add more props to cards
  const showButtons = countMode === 'display' ? user.canUpdateDisplayLog : true
  const CardComponent = props =>
    ItemCard({
      ...props,
      countMode,
      showButtons,
    })

  return (
    <div className="ShowItems">
      {CardHeader}
      <Cards
        CardComponent={CardComponent}
        cards={filtered}
        highlightedCardId={highlightedItemId}
        noResultMessage="No items to display"
        clickCard={clickCard}
        pressCard={pressCard}
        handlers={{ clickMinus, clickPlus }}
      />
      {CardFooter}
    </div>
  )
}
