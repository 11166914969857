import React from 'react'
import './styles.scss'


export default function Breadcrumbs({ history, routes }) {

  if (!Array.isArray(routes)) {
    return <span className="breadcrumbs">{routes}</span>
  }

  return (
    <div className="breadcrumbs">
      {routes && routes.map((route, i) => {
        const { name, key } = route
        return <Breadcrumb key={name} history={history} name={name} locationKey={key} />
      })}
    </div>
  )
}

function Breadcrumb({ name, locationKey, history }) {
  function handleClick(event) {
    event.preventDefault()
    if (locationKey) {
      history.push(locationKey)
    }
  }

  return (
    <span className="breadcrumb">
      {locationKey
        ? <a onClick={handleClick} href={locationKey}>{name}</a>
        : <span onClick={handleClick}>{name}</span>
      }
    </span>
  )
}
