import React from 'react'
import Entity from '../../models/entity'
import { withFirebase } from '../../services/Firebase';
import { useGlobals } from '../../globals'
import { useUser } from '../../services/user'
import { alert } from '../../packages/react-async-dialog';
import Help from '../../packages/Help'
import './styles.scss'


// see also Autologin page

const helpMsg = <div>
  Login with your email and password. <br/>
  <br/>
  If you need to change your email, login with the old one, then go to Account Info from the main menu - you can change it there. <br/>
  <br/>
  If you forgot your password, click Reset password and an email will be sent to you. 
</div>

// wrap with firebase HOC
export default withFirebase(({ history, firebase }) => {

  // update header
  const globals = useGlobals()
  globals.breadcrumbs = "Login"

  const [user, updateUser] = useUser()

  // user clicked Login or hit Enter
  function handleLogin(event) {

    document.body.style.cursor = 'wait' // show hourglass

    event.preventDefault()

    // get user input
    const email = document.querySelector("#login-email").value.trim().toLowerCase()
    const password = document.querySelector("#login-password").value

    firebase.login(email, password)
      .then(async () => {
        const user = await Entity.getUser({ email })
        document.body.style.cursor = 'default'
        console.log('user', user)
        if (user) {
          // set login date
          user.lastLogin = new Date().toISOString() // eg "2020-03-30..."
          // add extra info, update globals and localStorage, permissions (user.can*)
          await updateUser(user)
          // go to home page
          history.push('/')
        } else {
          await alert("The email and password are correct but unable to find a record in the database.")
        }
      })
      .catch(async error => {
        document.body.style.cursor = 'default'
        await alert(error.message)
      })
    
    // lookup the user in the db and validate password
    /*const user = await Entity.login({ email, password })
    if (user) {
      globals.user = user
      localStorage.setItem('user', JSON.stringify(user))
      history.push('/') // redirect to dashboard
    } else {
      // // do autologin if in development mode
       if (process.env.NODE_ENV === 'development') {
         history.push('/autologin')
       } else {
        alert("Incorrect email and/or password.")
      }
    }*/
  }

  return (
    <div className="login">
      <form onSubmit={handleLogin} id="pandora-login">
        <table>
          <tbody>
            <tr>
              <td>Email</td>
              <td></td>
            </tr>
            <tr>
              <td><input type="email" name="email" id="login-email" /></td>
              <td></td>
            </tr>
            <tr>
              <td>Password</td>
              <td></td>
              </tr>
            <tr>
              <td><input type="password" name="password" id="login-password" /></td>
              <td></td>
            </tr>
            <tr>
              <td className="login-button">
                <input type="submit" value="Login" id="login-button" />
                <Help msg={helpMsg} />
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <span onClick={() => history.push('/reset-password')} className="reset-password">Reset password</span>
      </form>
    </div>
  )
});
