import React from 'react'
import ReactDOM from 'react-dom'
import Firebase, { FirebaseContext } from './services/Firebase'
import { ModuleRegistry, AllCommunityModules } from '@ag-grid-community/all-modules'
import App from './pages/App'

// ag-grid community edition (free)
ModuleRegistry.registerModules(AllCommunityModules)

// ag-grid enterprise edition (has more features but haven't needed yet)
// LicenseManager.setLicenseKey("Evaluation_License_Not_For_Production_2_February_2020__MTU4MDYwMTYwMDAwMA==c170b56c77b8eaba46c382aa1d8e173d")
// ModuleRegistry.registerModules(AllModules)

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>, 
  document.getElementById('root')
)
