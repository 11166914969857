import { gql } from "apollo-boost"
import apolloClient from "../../services/apolloClient"
import Location from "../location"
import * as constants from "../../constants"

export default class LocationItem {
  // note: including inactive items by default as they might still appear
  // in display counts / inventories.
  static async getRows({ locationId, itemId, isActive }, cached = false) {
    const query = gql`
      query GetLocationItems(
        $locationId: Int
        $itemId: Int
        $isActive: Boolean
      ) {
        __typename
        locationItem(
          where: {
            locationId: { _eq: $locationId }
            itemId: { _eq: $itemId }
            item: { isActive: { _eq: $isActive } }
          }
          order_by: { position: asc_nulls_first, item: { code: asc } }
        ) {
          locationId
          itemId
          position
          currentCount
          item {
            id
            code
            color
            name
            barcode
            price
            isActive
            source: entity {
              id
              name
            }
          }
        }
      }
    `
    const variables = { locationId, itemId, isActive }
    const fetchPolicy = cached ? "cache-first" : "network-only"
    const { data } = await apolloClient.query({
      query,
      variables,
      fetchPolicy,
    })
    const rows = data.locationItem // locationItem is the table name
    rows.forEach((row) => flattenRow(row))
    return rows
  }

  static async getRow({ locationId, itemId }) {
    const rows = await LocationItem.getRows({ locationId, itemId })
    if (rows && rows.length === 1) {
      const row = rows[0]
      return row
    }
    throw new Error(`Can't find row ${locationId}, ${itemId}`)
  }

  static async getItemDisplays({ code, entityId, limit = 50 }, cached = false) {
    const query = gql`
      query GetItemDisplays($code: String, $entityId: Int!, $limit: Int) {
        locationItem(where: {
          item: {code: {_ilike: $code}}, 
          location: {
            locationTypeId: {_eq: ${Location.locationTypeDisplay}}, 
            entityId: {_eq: $entityId}
          },
          currentCount: {_neq: 0},
        }, limit: $limit) {
          itemId
          locationId
          currentCount
          item {
            id
            code
            name
          }
          location {
            id
            name
            entityId
          }
        }
      }
    
    `
    const variables = { code, entityId, limit }
    const fetchPolicy = cached ? "cache-first" : "network-only"
    const { data } = await apolloClient.query({
      query,
      variables,
      fetchPolicy,
    })
    const rows = data.locationItem // locationItem is the table name
    rows.forEach((row) => flattenRow2(row))
    console.log(rows)
    return rows

    //. better way?
    function flattenRow2(row) {
      row.code = row.item.code
      row.name = row.item.name
      row.locationName = row.location.name
      row.imageUrl = constants.baseImageUrlSmall + row.code + ".jpg"
    }
  }

  static async getItemCount(locationId, itemId) {
    try {
      const row = await LocationItem.getRow({ locationId, itemId })
      return row.currentCount
    } catch (e) {
      console.log(e)
      //. only return 0 if error is "can't find row..." else throw again?
      return 0
    }
  }

  static async getItemPosition(locationId, itemId) {
    const row = await LocationItem.getRow({ locationId, itemId })
    return row.position
  }

  // update row - returns true if db wrote changes properly
  static async updateRow(data, changes = {}) {
    console.log("updaterow", data, changes)
    // merge existing and new values
    const newData = { ...data, ...changes }
    // select desired fields
    const { locationId, itemId, position, currentCount } = newData
    const variables = { locationId, itemId, position, currentCount }
    try {
      const mutation = gql`
        mutation UpdateLocationItem(
          $locationId: Int!
          $itemId: Int!
          $position: Float
          $currentCount: Int
        ) {
          __typename
          update_locationItem(
            _set: { position: $position, currentCount: $currentCount }
            where: {
              locationId: { _eq: $locationId }
              itemId: { _eq: $itemId }
            }
          ) {
            returning {
              locationId
              itemId
              position
              currentCount
            }
          }
        }
      `
      console.log(variables)
      const ret = await apolloClient.mutate({ mutation, variables })
      // make sure got written correctly
      if (ret.data.update_locationItem.returning.length === 1) {
        const row = ret.data.update_locationItem.returning[0]
        console.log(row)
        return true
      }
      console.log(ret)
      console.log("db not updated properly!")
    } catch (e) {
      alert(e.message)
    }
    return false
  }

  static async setItemCount(locationId, itemId, count) {
    let row
    try {
      row = await LocationItem.getRow({ locationId, itemId })
      console.log(row)
    } catch (e) {
      console.log(e)
      row = await LocationItem.addRow({ locationId, itemId })
    }
    const okay = await LocationItem.updateRow(row, { currentCount: count })
    return okay
    // //. make sure got written correctly
    // if (ret.data.update_locationItem.returning.length === 1) {
    //   if (ret.data.update_locationItem.returning[0].currentCount === currentCount) {
    //     return true
    //   }
    //   console.log('db not updated properly!')
    // }
    // return false
  }

  //. currentCount should default to null - not used by categories
  static async addRow({ locationId, itemId, position, currentCount = 0 }) {
    try {
      const action = "insert_locationItem"
      const mutation = gql`
        mutation AddRow($locationId: Int!, $itemId: Int!, $position: Float, $currentCount: Int) {
          __typename
          ${action}(objects: {locationId: $locationId, itemId: $itemId, position: $position, currentCount: $currentCount}) {
            returning { locationId, itemId, position, currentCount }
          }
        }
      `
      const variables = { locationId, itemId, position, currentCount }
      console.log(action, variables)
      const ret = await apolloClient.mutate({ mutation, variables })
      // console.log(ret)
      if (ret && ret.data && ret.data[action] && ret.data[action].returning) {
        const row = ret.data[action].returning[0]
        // flattenRow(row)
        console.log(row)
        return row
      }
      throw new Error("Should have gotten one record back for new row")
    } catch (e) {
      //alert(e.message)
      // ignore uniqueness violation, which happens in the edit categories page sometimes
      if (e.message.includes("Uniqueness violation")) {
        console.error(e.message)
      } else {
        alert(e.message)
      }
    }
  }

  // delete one or more rows from table -
  // itemId can be null, in which case all records assoc with locationId are removed.
  // and vice-versa.
  static async deleteRows({ locationId, itemId }) {
    if (!locationId && !itemId) {
      alert("Error: can't have blank location and item")
      return false
    }
    try {
      const action = "delete_locationItem"
      const mutation = gql`
        mutation DeleteRows($locationId: Int, $itemId: Int) {
          __typename
          ${action}(where: {locationId: {_eq: $locationId}, itemId: {_eq: $itemId}}) {
            affected_rows
          }
        }
      `
      const variables = { locationId, itemId }
      console.log(action, variables)
      const ret = await apolloClient.mutate({ mutation, variables })
      console.log(ret)
      return true
    } catch (e) {
      alert(e.message)
      return false
    }
  }

  static async getNextPosition({ locationId }, cached = false) {
    const query = gql`
      query GetNextPosition($locationId: Int) {
        __typename
        locationItem_aggregate(where: { locationId: { _eq: $locationId } }) {
          aggregate {
            max {
              position
            }
          }
        }
      }
    `
    const variables = { locationId }
    const fetchPolicy = cached ? "cache-first" : "network-only"
    const ret = await apolloClient.query({ query, variables, fetchPolicy })
    console.log(ret)
    const maxPosition =
      ret && ret.data && ret.data.locationItem_aggregate.aggregate.max.position
    const nextPosition = maxPosition + 1
    return nextPosition
  }

  static async countRows({ locationId }) {
    const rows = await LocationItem.getRows({ locationId })
    return rows.length
  }
}

//. better way?
function flattenRow(row) {
  row.id = row.itemId
  row.code = row.item.code
  row.color = row.item.color
  row.name = row.item.name
  row.price = row.item.price
  row.barcode = row.item.barcode
  row.isActive = row.item.isActive
  row.initialCount = row.currentCount // each time page is fetched, want to remember the initial count
  row.delta = 0 // initialize delta
  //. get image url - make fn
  row.imageUrl =
    constants.baseImageUrlSmall + row.code + ".jpg" + constants.itemImageSuffix
  row.largeImageUrl =
    constants.baseImageUrlLarge + row.code + ".jpg" + constants.itemImageSuffix
  // source: entity { id, name }
  row.sourceId = row.item.source.id
  row.sourceName = row.item.source.name
}
