import React from 'react'
import Popup from "reactjs-popup"
import './styles.css'


// show some text in a div, unless window width drops below a breakpoint (set in pixels),
// in which case just show a little i, which when clicked on pops up the text in a window.

export default function({ text, breakpoint, popupWidth='12em' }) {

  const [showPopup, setShowPopup] = React.useState(window.innerWidth < breakpoint)
  
  React.useEffect(() => {
    window.addEventListener('resize', handleResizeEvent)
    return () => {
      window.removeEventListener('resize', handleResizeEvent)
    }
  }, [])
  
  function handleResizeEvent() {
    const showPopup = window.innerWidth < breakpoint
    setShowPopup(showPopup)
  }
  
  const popupStyle = {
    width: popupWidth,
  }
  
  return (
    <span className="infobox">
      {showPopup
        ?
        <Popup
          position="right top"
          trigger={<span className="infobox-icon">&#9432;</span>}
          contentStyle={popupStyle}
        >
          <div className="infobox-text-popup">
            {text}
          </div>
        </Popup>
      :
        <span className="infobox-text">
          {text}
        </span>
      }
    </span>
  )
}
