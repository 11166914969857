// grid
// generic grid - a wrapper around ag-grid

//. this needs refactoring - too many options passed in etc
// better to use hoc ?

import React from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { useGlobals } from '../../globals'
import { useUser } from '../../services/user'
import "./styles.scss"


export function Grid(props, {
  gridOptions, // ag-grid options
  Model, // a model class with getRows(variables) method, eg Entity
  variables={}, // if pass null won't fetch any data initially, just show empty grid
  Header, // optional header component
  Footer, // optional footer component
  className='', // optional name for containing div class
  breadcrumbs, // optional string or array
  overlays={}, // optional overlay elements { loading, noRows }
  pagination=0, // pass nrows in page to turn on pagination
  editCallback = row => {}, // called after changes made to a row
  context={}, // optional context object to pass to header and footer
  bottomRowCallback=null, // optional pinned bottom row - takes rows as param
}) {

  // update breadcrumbs
  const globals = useGlobals()
  if (breadcrumbs) globals.breadcrumbs = breadcrumbs

  const [gridApi, setGridApi] = React.useState(null)
  const [data, setData] = React.useState(null)

  async function onCellValueChanged(params) {
    // currently we're sending the entire row of data instead of just individual cell value
    const field = params.colDef.field
    const value = params.newValue
    const row = { ...params.data, [field]: value }
    const ret = await Model.updateRow(row)
    console.log(ret)
    editCallback(row)
  }
  
  const defaultColDef = {
    width: 60, // px
    resizable: true,
    editable: true,
    sortable: true,
    filter: true,
    suppressMovable: true,
    menuTabs: [], // no column menus
    onCellValueChanged,
    // tooltipValueGetter: params => params.value, // annoying //. turn on for descriptions etc
  }
  
  const defaultGridOptions = {
    defaultColDef,
    // onCellDoubleClicked: params => editObject(params.data, Model),
    groupDefaultExpanded: -1, // expand everything
    context: props, // this gets passed to all grid callbacks in their params, eg cellRendererFramework
    deltaRowDataMode: true, // this tells the grid we are doing updates when setting new data
    getRowNodeId: data => data.id, // need this for deltaRowDataMode
    animateRows: true,
    onRowDragMove: onRowDragMove.bind({ data, setData }),
    enableBrowserTooltips: true, // to enable for a column, add eg tooltipField: 'name' to coldef
  }
  
  // retrieve data when grid is ready
  function onGridReady(params) {
    setGridApi(params.api)
    async function fetchData() {
      const rows = await Model.getRows(variables) // get data from db
      setData(rows)
      console.log(rows)
      params.api.setRowData(rows) // send data to the grid
      if (bottomRowCallback) {
        const bottomRow = bottomRowCallback(rows)
        params.api.setPinnedBottomRowData(bottomRow)
      }
    }
    // fetch data if variables is given - 
    // allows us to have grids that return all rows by default (pass {}),
    // or that require specifying parameters before fetching data (pass null).
    if (variables) {
      fetchData()
    } else {
      params.api.setRowData(null) // this will clear any loading overlay message
    }
  }

  const [user] = useUser(props.history)

  if (!user) return null
  return (
    <div className={"grid " + className}>
      {Header && Header(props, gridApi, variables, setData, context)}
      <div className="grid-grid ag-theme-balham">
        <AgGridReact
          {...defaultGridOptions}
          {...gridOptions}
          rowData={null}
          onGridReady={onGridReady}
          overlayLoadingTemplate={overlays.loading}
          overlayNoRowsTemplate={overlays.noRows}
        />
      </div>
      {pagination>0 && PaginationControls(gridApi, Model, pagination, variables)}
      {Footer && Footer(props, gridApi, variables, data)}
    </div>
  )
}


//. use our Pagination package instead of this

function PaginationControls(gridApi, Model, pagination, variables) {
  const limit = pagination
  const [page, setPage] = React.useState(0)

  async function fetchPage(offset) {
    //gridApi.showLoadingOverlay()
    const newVariables = { ...variables, offset, limit }
    // const rows = await Model.getRows({ offset, limit }) // get data from db
    const rows = await Model.getRows(newVariables) // get data from db

    if (rows.length >= 1) {
      gridApi.setRowData(rows) // send data to the grid
      gridApi.hideOverlay()
      return true
    }

    gridApi.setRowData([])
    gridApi.hideOverlay()
    return false
  }

  const handleFirst = () => {
    const offset = 0
    fetchPage(offset)
    setPage(0)
  }

  const handleNext = () => {
    const offset = limit * (page + 1)
    const hasMoreToFetch = fetchPage(offset)
    
    if (hasMoreToFetch) {
      setPage(page + 1)
    }
  }

  const handlePrevious = () => {
    const offset = limit * (page - 1)
    const hasMoreToFetch = fetchPage(offset)

    if (hasMoreToFetch) {
      setPage(page - 1)
    }
  }

  return (
    <div className="pg-controls">
      <span>
        <button disabled={page === 0} onClick={handleFirst}>First</button>
        <button disabled={page === 0} onClick={handlePrevious}>Previous</button>
      </span>
      <span>Page {page + 1}</span>
      <button onClick={handleNext}>Next</button>
    </div>
  )
}


export function renderCheckbox(params) {
  console.log('renderCheckbox', params)
  const input = document.createElement('input')
  input.type = "checkbox"
  input.checked = params.value
  input.setAttribute("id", params.colDef.field)
  input.disabled = !params.colDef.editable
  if (params.colDef.editable) {
    input.addEventListener('change', async function onChange(event){
      const value = this.checked
      params.setValue(value) // this triggers onCellValueChanged which updates the db
    })
  }
  return input
}



// note: bound 'data' and 'setData' to 'this' above
export function onRowDragMove(params) {
  // this is from their sample code
  const movingNode = params.node
  const overNode = params.overNode
  const rowNeedsToMove = movingNode !== overNode
  if (rowNeedsToMove) {
    const movingData = movingNode.data
    const overData = overNode.data
    const fromIndex = this.data.indexOf(movingData)
    const toIndex = this.data.indexOf(overData)

    // insert row in new position - nowork
    // //. maybe need to set path, tripId etc here also for it to render properly? 
    // // nowork even with redraw nodes
    // if (movingData.type==='load') {
    //   let tripId
    //   if (overData.type==='load') {
    //     tripId = overData.tripId
    //   } else if (overData.type==='trip') {
    //     tripId = overData.id
    //   }
    //   if (tripId) {
    //     movingData.path[1] = tripId
    //     movingData.tripId = tripId
    //   }
    //   console.log(movingData)
    // }

    // copy data and move row
    const newRows = [...this.data]
    const element = newRows[fromIndex]
    newRows.splice(fromIndex, 1)
    newRows.splice(toIndex, 0, element)

    this.setData(newRows)

    // tell grid
    params.api.setRowData(newRows)

    //. refresh grid - nowork
    // params.api.refreshCells()
    // params.api.redrawRows([movingNode])

    // clear any selection
    params.api.clearFocusedCell()
  }
}

// // at this point the dragged row is already in the correct place, 
// // but need to update the pos order in db, taking avg of surrounding row pos's.
// export async function onRowDragEnd(params) {
//   // params has { api, columnApi, params, node, overIndex, overNode, type, vDirection, y }
//   console.log(params)

//   // const dragType = params.node.data.type // eg 'load'
//   // const targetType = params.overNode.data.type // eg 'trip'

//   // move load within a trip - works okay
//   const pos = getNewRowPos(params.node, true, 'load')
//   await this.setLoadProps(params.node.data, { pos })

//     // } else if (targetType === 'trip') {
//     //   //. handle moving a trip load to one in another date also
//     //   const tripId = params.overNode.data.id
//     //   if (tripId === constants.UNASSIGNED) {
//     //     // move row to end of unassigned trip space
//     //     const pos = getTripMaxPos(params, tripId)
//     //     await this.setLoadProps(params.node.data, { tripId, pos: pos + 1 })  
//     //   } else {
//     //     // find last non-blank row and add there
//     //     const nonblankRow = getLastNonblankRow(params, tripId)
//     //     const pos = nonblankRow ? getNewRowPos(nonblankRow, false, 'load') : 0
//     //     await this.setLoadProps(params.node.data, { tripId, pos })
//     //   }

//     // } else {
//     //   // date - ignore
//     // }

//   // } else if (dragType === 'trip') {

//   //   const dragDate = params.node.data.date
//   //   const targetDate = params.overNode.data.date || params.overNode.data.id //. weird

//   //   if (targetType === 'load' || dragDate !== targetDate) {
//   //     alert("To change date of a trip, use right click Schedule Trip")
//   //     this.refreshRows()

//   //   // } else if (targetType === 'load') {
//   //   //   //. get the tripid for the load and add the dragged trip after it

//   //   } else if (targetType === 'trip') {
//   //     //. get the target id and add the dragged trip after it
//   //     const pos = getNewRowPos(params.node, true, 'trip')
//   //     // await this.setTripProps(params.node.data, { date: targetDate, pos })
//   //     await this.setTripProps(params.node.data, { pos })

//   //   }
//   //   //  else if (targetType === 'date') {
//   //   //   // set the trip date and put it at end of list
//   //   // }

//   // } else if (dragType === 'date') {
//   //   // ignore
//   // }

//   // clearGridSelection(params)
//   // params.api.refreshCells() // nowork
//   // params.api.redrawRows() // nowork

// }
