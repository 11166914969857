import React from 'react'
import { openDialog, closeDialog } from '../react-async-dialog'
import './styles.scss'


export default async function confirm(title, message, yesButton="OK", noButton="Cancel") {
  return new Promise(resolve => {
    openDialog(Confirm, { title, message, yesButton, noButton, resolve })
  })
}


function Confirm({ title, message, yesButton, noButton, resolve }) {

  function clickOK() {
    closeDialog()
    // resolve({ ok: true })
    resolve(true)
  }
  
  function clickCancel() {
    closeDialog()
    // resolve({ ok: false })
    resolve(false)
  }
  
  return (
    <div className="confirm">
      <div className="title">{title}</div>
      <div className="message">{message}</div>
      <div className="buttons">
        <button id="cancel" onClick={clickCancel}>{noButton}</button>
        <button id="ok" onClick={clickOK}>{yesButton}</button>
      </div>
    </div>
  )
}
