// get contact info / address, click submit button

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useGlobals } from '../../globals'
import './styles.scss'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { orderState, summaryState } from '../../recoil/order'
import { sendEmail } from '../../services/email'
import { alert } from '../../packages/react-async-dialog'

export default observer(function OrderContact({ history }) {
  const globals = useGlobals()
  globals.breadcrumbs = 'Contact Info'

  const [order, setOrder] = useRecoilState(orderState)
  const resetOrder = useResetRecoilState(orderState)
  const summary = useRecoilValue(summaryState)

  const { contact } = order

  React.useEffect(() => {
    document.querySelector('.orderApp-contents').scrollTop = 0
    updateButtonState()
  }, [])

  // update part of the order object
  function updateValue(event) {
    const target = event.currentTarget
    const part = target.id // eg 'phone'
    const value = target.type === 'checkbox' ? target.checked : target.value
    console.log(part, target, value)
    setOrder({ ...order, contact: { ...contact, [part]: value } })
    updateButtonState()
  }

  // disable continue button if any required field is empty
  function updateButtonState() {
    const keys =
      'firstname,lastname,email,phone,address,city,state,zipcode,acknowledgement'.split(
        ','
      )
    const disabled = keys.some(key => {
      const ctrl = document.querySelector('input#' + key)
      return (
        ctrl &&
        (ctrl.type === 'checkbox'
          ? !ctrl.checked
          : ctrl.value.trim().length === 0)
      )
    })
    document.querySelector('.continue button').disabled = disabled
  }

  // user changed delivery option
  function changeDelivery(event) {
    const deliveryOption = event.target.value
    setOrder({ ...order, contact: { ...contact, deliveryOption } })
  }

  // send emails -
  // POST to url with body containing json with data to send email
  async function clickSubmit() {
    // check phone
    const phone = document.querySelector('input#phone')
    if (!phone.checkValidity()) {
      await alert(
        'Invalid phone format - need ddd-ddd-dddd or dddddddddd format: ' +
          phone.value
      )
      return
    }

    // check email
    const email = document.querySelector('input#email')
    if (!email.checkValidity()) {
      await alert('Invalid email format: ' + email.value)
      return
    }

    document.querySelector('.continue button').disabled = true

    // send the email(s)
    sendEmail(order, summary)

    // clear the order
    resetOrder()

    // goto thankyou page
    history.push(`/order/thankyou`)
  }

  return (
    <div className="OrderContact">
      <form>
        <div>
          All fields are required
          <br />
          <br />
        </div>

        <table>
          <tbody>
            <tr>
              <td>First Name</td>
              <td>&nbsp;&nbsp;</td>
              <td>Last Name</td>
            </tr>
            <tr>
              <td>
                <Input
                  part="firstname"
                  contact={contact}
                  updateValue={updateValue}
                  updateButtonState={updateButtonState}
                />
              </td>
              <td>&nbsp;&nbsp;</td>
              <td>
                <Input
                  part="lastname"
                  contact={contact}
                  updateValue={updateValue}
                  updateButtonState={updateButtonState}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <tbody>
            <tr>
              <td>Phone</td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Input
                  part="phone"
                  type="tel"
                  pattern="[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}"
                  contact={contact}
                  updateValue={updateValue}
                  updateButtonState={updateButtonState}
                />
              </td>
            </tr>

            <tr>
              <td>Email</td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Input
                  part="email"
                  type="email"
                  contact={contact}
                  updateValue={updateValue}
                  updateButtonState={updateButtonState}
                />
              </td>
            </tr>

            <tr>
              <td colSpan={3}>Billing Address</td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Input
                  part="address"
                  contact={contact}
                  updateValue={updateValue}
                  updateButtonState={updateButtonState}
                />
              </td>
            </tr>

            <tr>
              <td>City</td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Input
                  part="city"
                  contact={contact}
                  updateValue={updateValue}
                  updateButtonState={updateButtonState}
                />
              </td>
            </tr>

            <tr>
              <td className="state">State</td>
              <td></td>
              <td className="zipcode">Zipcode</td>
            </tr>
            <tr>
              <td>
                <Input
                  part="state"
                  contact={contact}
                  updateValue={updateValue}
                  updateButtonState={updateButtonState}
                />
              </td>
              <td>&nbsp;&nbsp;</td>
              <td>
                <Input
                  part="zipcode"
                  contact={contact}
                  updateValue={updateValue}
                  updateButtonState={updateButtonState}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div className="options">
          <div>Delivery Options</div>
          <input
            type="radio"
            name="delivery-options"
            id="pickup"
            value="pickup"
            checked={contact.deliveryOption === 'pickup'}
            onChange={changeDelivery}
          />
          <label htmlFor="pickup">
            <b>In-Store Pickup</b>
          </label>
          <br />
          <input
            type="radio"
            name="delivery-options"
            id="ship"
            value="ship"
            checked={contact.deliveryOption === 'ship'}
            onChange={changeDelivery}
          />
          <label htmlFor="ship">
            <b>Ship To Me</b>
          </label>
        </div>

        {/* //. read from db */}
        {/* eg
        Orders must be placed before December 16th to ensure delivery before
        Christmas. Or convenient in-store pickup is available.
        or
        Orders must be placed by April 30th to receive by or before Mother's
        Day. ** This is not guaranteed.  
        */}
        {/* <p>{order.messages && order.messages.holiday}</p> */}
        {order.messages && (
          <>
            {order.messages.holiday}
            <br />
          </>
        )}

        <p>
          <b>Please note: for in-store pickups, a $10 deposit is required.</b>
        </p>

        <p>
          For in store pickup we accept Klarna, Afterpay and Pandora Credit
          Cards
        </p>

        <p>We'll contact you to collect payment information</p>

        <p className="acknowledgement">
          <input
            id="acknowledgement"
            type="checkbox"
            checked={contact.acknowledgement ? 'on' : ''}
            onChange={updateValue}
          />
          <label htmlFor="acknowledgement">
            I understand that my selections may not be available
          </label>
        </p>

        <div className="promotions">
          <input
            id="promotions"
            type="checkbox"
            checked={contact.promotions ? 'on' : ''}
            onChange={updateValue}
          />
          <label htmlFor="promotions">
            Check here to receive occasional promotional emails and updates from
            our store
          </label>
        </div>

        <br />
      </form>

      <div className="continue">
        <button onClick={clickSubmit}>Submit Request</button>
      </div>

      <br />
    </div>
  )
})

// note: don't define this inside the previous function component,
// as it'll lose focus after each tab press!
function Input({
  type = 'text',
  part,
  required = false,
  contact,
  updateValue,
  updateButtonState,
  pattern = null,
}) {
  return (
    <input
      id={part}
      type={type}
      defaultValue={contact[part]}
      onInput={updateButtonState}
      onBlur={updateValue}
      required={required}
      pattern={pattern}
    />
  )
}
