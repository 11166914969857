import React from 'react'
import { withFirebase } from '../../services/Firebase';
import { useGlobals } from '../../globals'
import './styles.scss'
import { alert } from '../../packages/react-async-dialog';


//wrap component with firebase HOC
export default withFirebase(({ history, firebase }) => {

  const globals = useGlobals()
  globals.breadcrumbs = "Password Reset"

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const passwordReset = () => {
    const email = document.querySelector('#email-reset').value.trim().toLowerCase()

    //check if email is empty or not an email
    if (email === '') return alert(`Don't leave the email field empty`)

    if (!validateEmail(email)) return alert('Please input a valid email address')

    firebase.resetPassword(email)
      .then(() => {
        alert('Password Recovery Email sent!')
        history.push('/login')
      })
      .catch(e => alert(e.message))
  }

  return (
    <div className="password-reset">
      <p>Please enter your email to reset the password</p>
      <div>
        <input id="email-reset" />
        <button onClick={passwordReset}>Send Email</button>
      </div>
    </div>
  )
})
