// Pressable
// wrap child components and allow long press event on them.
// uses long-press-event library

// Usage:
// <Pressable clickCard={clickCard} pressCard={pressCard} id={card.id} >
//   <div>Click or Long Press Me</div>
// </Pressable>


import React from 'react'
// import 'long-press-event/dist/long-press-event.min.js'
import './long-press-event' // brought in code so could override default delay
import './styles.scss'


//. need better way
// // turn off context menu everywhere
// // otherwise long press triggers it on mobile
// window.oncontextmenu = function(event) {
//   event.preventDefault()
//   event.stopPropagation()
//   return false
// }


export default function Pressable({id, index, clickCard, pressCard, children, className, style}) {

  // add long-press event listener
  React.useEffect(() => {
    if (pressCard) {
      var el = document.getElementById(id)
      function handleLongPress(event) {
        event.preventDefault() // stop event bubbling
        pressCard(event)
      }
      el.addEventListener('long-press', handleLongPress)
      return () => el.removeEventListener('long-press', handleLongPress)
    }
  }, [id, pressCard])

  return (
    <div
      onContextMenu={evt => evt.preventDefault()}
      onClick={clickCard}
      className={"pressable " + className}
      id={id}
      data-index={index}
      style={style}
    >
      {children}
    </div>
  )

}
