// a semi-generic card container

//. this was extended by ruben with pagination - merge/replace with cards2

import React from 'react'
import Pressable from '../pressable2'
import './styles.scss'


export default ({ 
  cards, 
  Card,
  clickCard, 
  pressCard, 
  highlightedCardId,
  handlers = {}, // handlers get passed to child cards
  noResultMessage = "No results",
  children,
  Model,
  variables,
  setCards,
  limit,
  Header,
  parentId,
  headerContext,
}) => {

  // const [timedOut, setTimedOut] = useState(false) //. implement this

  const loading = (cards === null)
  const noResults = !loading && cards && (cards.length === 0)
  const [filter, setFilter] = React.useState('')
  // const showDescription = !loading && !noResults

  return (
    <>
      {Header && Header(Model, setCards, limit, cards, parentId, setFilter, headerContext)}
      <div className="cards">
        {cards && cards.length > 0 && <div className="cards-list">
          {cards.map((card, index) => (
            <Pressable
              className="card-container"
              clickCard={clickCard}
              pressCard={pressCard}
              key={card.id}
              id={card.id}
              index={index}
            >
              <Card
                card={card}
                highlighted={highlightedCardId === card.id}
                handlers={handlers}
                id={card.id}
              />
            </Pressable>
          ))}
        </div>}
        {children}
      </div>

      {loading && <div className="cards-message">Loading...</div>}
      {noResults && <div className="cards-message">{noResultMessage}</div>}
      {/* {timedOut && <div className="cards-message">Network problems?</div>} */}
      {limit && PaginationControls(Model, setCards, limit, variables, filter)}
    </>
  )
}


function PaginationControls(Model, setCards, limit, variables, filter) {
  const [page, setPage] = React.useState(0)

  async function fetchPage(offset) {
    setCards(null)
    const code = filter !== '' ? filter : null
    const rows = await Model.getRows({ ...variables, offset, code }) // get data from db
    setCards(rows) // send data to the cards
  }

  const handleFirst = () => {
    const offset = 0
    fetchPage(offset)
    setPage(0)
  }

  const handleNext = () => {
    const offset = limit * (page + 1)
    fetchPage(offset)
    setPage(page + 1)
  }

  const handlePrevious = () => {
    const offset = limit * (page - 1)
    fetchPage(offset)
    setPage(page - 1)
  }

  return <div className="card-pg-controls">
    <span>
      <button disabled={page === 0} onClick={handleFirst}>First</button>
      <button disabled={page === 0} onClick={handlePrevious}>Previous</button>
    </span>
    <span>Page #{page + 1}</span>
    <button onClick={handleNext}>Next</button>
  </div>
}
