import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { observer } from 'mobx-react-lite'
import logo from '../../assets/images/tallieo.png'
import tallieo from '../../assets/images/tallieo3cTransparent.png'
import Menu from '../../parts/Menu'
import { useGlobals } from '../../globals'
import Breadcrumbs from '../../parts/Breadcrumbs'
import * as constants from '../../constants'
import './styles.scss'

export default observer(function ({ history }) {
  const globals = useGlobals()
  return (
    <HelmetProvider>
      <div className={'app-header ' + constants.environment}>
        <Helmet>
          <title>
            {'Tallieo ' +
              (globals.breadcrumbs
                ? ' - ' + getTitle(globals.breadcrumbs)
                : '')}
          </title>
        </Helmet>
        <span className="left">
          <span className="home">
            <a href="/#/">
              <img className="large" src={tallieo} alt="TALLIEO" />
              {/* <img className="small" src={logo} alt="TALLIEO" /> */}
              <img
                className="small"
                src={globals.breadcrumbs ? logo : tallieo}
                alt="TALLIEO"
              />
            </a>
          </span>
          <Breadcrumbs history={history} routes={globals.breadcrumbs} />
        </span>
        <span className="right">
          {constants.environment === 'development' && (
            <span className="test-message">Using Test Database</span>
          )}
          <Menu history={history} />
        </span>
      </div>
    </HelmetProvider>
  )
})

function getTitle(breadcrumbs) {
  if (!Array.isArray(breadcrumbs)) {
    return breadcrumbs
  }
  return breadcrumbs.slice(-1)[0].name
}
