// order request data.
// uses recoil to manage state.
// didn't like mobx as get confused how to set up and use, so tried this -
// much simpler.

// originally just had order request state, which was simpler, but alyssa wanted
// order and wishlist history separate, so split them out. couldn't find a way
// to make the atom variable over the localstorage key, which was the crucial point.

import { atom, selector } from 'recoil'

//. eventually let them edit these values and store in the value table.
export const minimumForFreeShipping = 49.0
const shippingCost = 14.0
const messages = {
  // holiday: `Orders must be placed by April 30th to receive by or before Mother's Day. ** This is not guaranteed.`,
  // holiday: `Orders must be placed before December 14th for delivery before Christmas. Or convenient in-store pickup is available. Please note due to shipping delays, we cannot guarantee delivery dates.`,
  // holiday: `For shipping, orders must be placed before December 14th for delivery before Christmas. Delivery dates are not guaranteed.`,
}

// order request
const orderLocalStorageKey = 'order-v8' // change version when change state structure (or message), so code doesn't break
// use this to pass info to the serverless function
//. define jsdoc
const orderDefaultState = {
  store: {}, // { id, name, phone, email, address }
  items: {}, // { [itemId]: { id, name, sizeTable, quantity, size } }
  // contact: { deliveryOption: 'ship' }, // { name, email, phone, address, city, state, zipcode, promotions, deliveryOption }
  contact: { deliveryOption: 'pickup' }, // { name, email, phone, address, city, state, zipcode, promotions, deliveryOption }
  messages, // { holiday }
}

// wishlist
const wishlistLocalStorageKey = 'wishlist-v3' // change version when change state structure, so code doesn't break
//. define jsdoc
const wishlistDefaultState = {
  store: {}, // { id, name, phone, email, address }
  items: {}, // { [itemId]: { id, name, sizeTable, quantity, size } }
}

// get and set data with browser's localStorage.
// just pass the localStorage key and set in the atom's effects_UNSTABLE key.
// when atom values change, this will write it to localStorage,
// and load from localStorage on startup.
// from https://recoiljs.org/docs/guides/atom-effects/#local-storage-persistence
const localStorageEffect =
  key =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }
    onSet(newValue => {
      if (newValue instanceof Object && Object.keys(newValue).length === 0) {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, JSON.stringify(newValue))
      }
    })
  }

// define the order request recoil atom, which components will subscribe to
export const orderState = atom({
  key: 'orderState',
  default: orderDefaultState,
  effects_UNSTABLE: [localStorageEffect(orderLocalStorageKey)],
})

// define the wishlist recoil atom, which components will subscribe to
export const wishlistState = atom({
  key: 'wishlistState',
  default: wishlistDefaultState,
  effects_UNSTABLE: [localStorageEffect(wishlistLocalStorageKey)],
})

// define a recoil selector, which defines calculated values based
// on other atoms and selectors.
// get subtotal, shipping, and total based on order request items.
// if any price is zero, call it TBD and propagate through to totals.
export const summaryState = selector({
  key: 'summaryState',
  get: ({ get }) => {
    const order = get(orderState)
    const items = Object.values(order.items)
    const subtotal = items.reduce((subtotal, item) => {
      const price = item.sizeTable[item.size]
      return price ? subtotal + item.quantity * price : 'TBD'
    }, 0)
    const shipping =
      subtotal >= minimumForFreeShipping
        ? 0
        : subtotal > 0
        ? shippingCost
        : 'TBD'
    const total = subtotal >= 0 && shipping >= 0 ? subtotal + shipping : 'TBD'
    return {
      subtotal: subtotal >= 0 ? '$' + subtotal.toFixed(2) : 'TBD',
      shipping:
        shipping > 0
          ? '$' + shipping.toFixed(2)
          : shipping === 0
          ? 'FREE'
          : 'TBD',
      total: total >= 0 ? '$' + total.toFixed(2) : 'TBD',
    }
  },
})
