// input/output - files to data structs, data structs to files

import Papa from 'papaparse'
import XLSX from 'xlsx'
import * as sheet from './sheet'

/**
 * read employees xls
 * @param contents - binary file data
 * @returns list of [{ number, name }]
 */
export function readEmployees(contents) {
  const data = new Uint8Array(contents)
  const workbook = XLSX.read(data, { type: 'array' })
  const sheetName = workbook.SheetNames[0] // tab 0 has ALL ees, tab 1 just actives
  const worksheet = workbook.Sheets[sheetName]
  const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
  const numbernames = rows.slice(1).map(row => [row[1], row[0]]) // number, name
  const ees = []
  for (const [number, name] of numbernames) {
    ees.push({ number, name })
  }
  // Employee number 0 (kwi) groups returns that cannot be associated
  // with an employee number and buckets returns such as defects.
  ees.push({ number: 0, name: 'kwi' })
  console.log({ ees })
  return ees
}

/**
 * read sales csv
 * @param contents text csv data
 * @returns list of [{ 'Store Number', 'Employee Number', 'Employee Name', '# of Transactions', 'Net Units', 'Net Sales'}]
 */
export function readSales(contents) {
  // the csv file is really weird - has 3 sections that repeat data -
  // so we just split it into thirds and get first third.
  let csv = contents.split('Grand Total')[0]
  // start parsing csv at the row with 'Client Number'
  csv = csv.slice(csv.indexOf('Client Number'))
  // parse csv
  // `result` is { data, errors, meta: { aborted, cursor, delimeter, fields }}
  // data is array of rows like { foo: 0, bar: 3 }
  // fields is array of field names
  const result = Papa.parse(csv, {
    header: true,
    skipEmptyLines: true,
    dynamicTyping: true,
    transformHeader: h => h.trim(),
  })
  // filter and cleanup data
  let rows = result.data
  rows = rows.filter(row => Number(row['Client Number']) > 0)
  for (const row of rows) {
    row['Employee Name'] = row['Employee Name'].trim()
  }
  return rows
}

/**
 * read credit card xlsx file
 * just need the count of employees from here
 * @contents the binary file data
 * @returns list of [{'Employee ID', ...}]
 */
export function readCards(contents) {
  const data = new Uint8Array(contents)
  const workbook = XLSX.read(data, { type: 'array' })
  const sheetName = workbook.SheetNames[0]
  const worksheet = workbook.Sheets[sheetName]
  const cards = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
  return cards
}

/**
 * write commissions report
 * convert sales/commission data to row layout, then to xls
 * @param storedict - { [storenum]: { [eenum]: {}}}
 * @param stores - { [storenum]: { name }}
 * @param alert - async alert fn
 */
export async function writeCommissions(storedict, stores, alert) {
  const filename = 'EmployeeCommissionsReport.xls'
  const rows = []
  rows.push([new Date().toString()])
  rows.push(['Employee Commissions Report'])
  const eeHeader = [
    'Employee Number',
    'Employee Name',
    '',
    'Units',
    'Dollars',
    '',
    'Commission',
  ]
  rows.push(eeHeader)

  let grandUnits = 0
  let grandSales = 0
  let grandCommission = 0

  // iterate over stores
  const storenums = Object.keys(storedict)
  for (const storenum of storenums) {
    const store = storedict[storenum]
    const storename = stores[storenum].name
    const storeHeader = ['Store Number', `${storenum} - ${storename}`]
    rows.push(storeHeader)

    let storeUnits = 0
    let storeSales = 0
    let storeCommission = 0

    // iterate over ees
    const ees = Object.values(store)
    for (const ee of ees) {
      const row = [
        ee.number,
        ee.name,
        '',
        ee.units,
        ee.sales,
        '',
        ee.commission,
      ]
      rows.push(row)
      storeUnits += ee.units
      storeSales += ee.sales
      storeCommission += ee.commission
    }

    const storeFooter = [
      'Store Total',
      '',
      '',
      storeUnits,
      storeSales,
      '',
      storeCommission,
    ]
    rows.push(storeFooter)

    grandUnits += storeUnits
    grandSales += storeSales
    grandCommission += storeCommission
  }

  const sheetFooter = [
    'Grand Total',
    '',
    '',
    grandUnits,
    grandSales,
    '',
    grandCommission,
  ]
  rows.push(sheetFooter)

  // convert to xls
  const workbook = XLSX.utils.book_new()
  const worksheet = XLSX.utils.aoa_to_sheet(rows)
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Report')

  // format columns
  const currency = '$#,##0.00;($#,##0.00)'
  for (let col of [4, 6]) {
    sheet.setFormat(worksheet, col, currency)
  }
  sheet.setWidth(worksheet, 1, 30)

  // save/download
  try {
    XLSX.writeFile(workbook, filename)
    await alert('Commission report downloaded to ' + filename)
  } catch (e) {
    await alert('Error: ' + e.message)
  }
}

/**
 * write detail report
 * @param eedict - summary data
 * @param alert - async alert fn
 */
export async function writeDetails(structures, eedict, alert) {
  const filename = 'EmployeeCommissionDetails.xls'

  const rows = []

  // show structures
  const header1 = 'Metric,Goal,Commission,Base,Description'.split(',')
  rows.push(header1)
  for (const structure of Object.values(structures)) {
    const row = [
      structure.metric,
      structure.goal,
      structure.commission,
      structure.base,
      structure.description,
    ]
    rows.push(row)
  }
  rows.push([])

  const header2 = 'Employee Number,Employee Name,Transactions,Units Sold,Net Sales,Avg Sale,Sales Commission,Card Applications,Pct Cards,Cards Commission,Total Commission'.split(
    ','
  )
  rows.push(header2)
  const ees = Object.values(eedict).filter(
    ee => ee.transactions !== 0 || ee.units !== 0
  )
  for (const ee of ees) {
    const row = [
      ee.number,
      ee.name,
      ee.transactions,
      ee.units,
      ee.sales,
      ee.avgSale,
      ee.salesCommission,
      ee.applications,
      ee.percentCards,
      ee.cardsCommission,
      ee.commission,
    ]
    rows.push(row)
  }

  // convert to xls
  const workbook = XLSX.utils.book_new()
  const worksheet = XLSX.utils.aoa_to_sheet(rows) // array of arrays
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Details')

  // format columns
  const currency = '$#,##0.00;($#,##0.00)'
  const percent = '0.0%'
  for (let col of [4, 5, 6, 9, 10]) {
    sheet.setFormat(worksheet, col, currency)
  }
  sheet.setFormat(worksheet, 8, percent)
  sheet.setWidth(worksheet, 1, 30)

  // save/download
  try {
    XLSX.writeFile(workbook, filename)
    await alert('Detail report downloaded to ' + filename)
  } catch (e) {
    await alert('Error: ' + e.message)
  }
}
