// email
// use a google cloud function to process email

// we post some data to a google cloud function, defined in functions/index.js,
// which builds the text and html email,
// and sends it to the sendgrid smtp server, which sends the email.

// see /functions for actual email content

import { environment } from '../constants'

const fromEmail = 'memorablecharms@tallieo.com' // currently MUST be this (or any tallieo.com addr?)
// const alyssaEmail = 'Alyssa Gutkin <AGutkin@pandora-sh.com>' // wants a copy of every email
const alyssaEmail = 'Alyssa Reinhertz <AReinhertz@pandora-sh.com>' // wants a copy of every email
const myEmail = 'Brian Burns <bburns.km@gmail.com>' // for testing

const isProduction = environment === 'production' // node_env AND url is tallieo.web.app

const postUrl = isProduction
  ? 'https://us-central1-tallieo.cloudfunctions.net/sendMail'
  : 'http://localhost:5001/tallieo-test/us-central1/sendMail'

export async function sendEmail(order, summary) {
  const toEmail = isProduction
    ? (order.store && order.store.email) + ', ' + alyssaEmail
    : myEmail

  const data = {
    store: order.store,
    datetime: new Date().toLocaleString(),
    to: toEmail,
    from: fromEmail,
    contact: order.contact,
    items: order.items,
    summary,
    messages: order.messages,
  }
  const response = await fetch(postUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  const text = await response.text()
  console.log(text)
}
