import React from 'react'
import Entity from '../../models/entity'
import { useGlobals } from '../../globals'
import { useUser } from '../../services/user'
import './styles.scss'
import { withFirebase } from '../../services/Firebase'
import alert from '../../packages/alert'


//wrap with firebase HOC
export default withFirebase(function({ history, firebase }) {

  const globals = useGlobals()
  globals.breadcrumbs = "Account Information"

  const [user, updateUser] = useUser(history)
  if (!user) return null
  
  //set initial value + for use in mutation object
  const [name, setName] = React.useState(user.name);
  const [email, setEmail] = React.useState(user.email);

  const changePassword = () => {
    const newPassword = document.querySelector('#user-password').value
    if (newPassword === '') return alert(`Don't leave the new password field empty`)
    firebase.changePassword(newPassword)
      .then(alert('Password succesfully changed'))
      .catch(error => alert(`Couldn't update password:` + error.message))
  }

  const saveName = async () => {
    const changes = { name }
    if (user.name !== changes.name) {
      saveUser(changes)
    }
  }

  const saveEmail = async () => {
    const changes = { email }
    if (user.email !== changes.email) {
      firebase.changeEmail(email)
        .then(async () => {
          console.log('firebase email changed')
          saveUser(changes)
        })
        .catch(async error => await alert(error.message))
    }
  }

  async function saveUser(changes) {
    //declare only required data for mutation
    const data = { 
      id: user.id, 
      name: user.name,
      email: user.email,
      number: user.number, 
      isActive: user.isActive,
      isEmployee: user.isEmployee,
      isStoreAdmin: user.isStoreAdmin,
      isCompanyAdmin: user.isCompanyAdmin,
      isCorporateAdmin: user.isCorporateAdmin,
    }
    const ret = await Entity.updateRow(data, changes)
    if (ret) {
      const newUser = {...user, ...changes}
      // add extra info, update globals and localStorage
      await updateUser(newUser)
      await alert('User data updated succesfully')
    } else {
      await alert(`Couldn't update user data`)
    }
  }

  return (
    <div className="account">
      <table>
        <tbody>
          <tr>
            <td>Name (Last, First)</td>
            <td>
              <input value={name} onChange={(event) => setName(event.target.value)} id="user-name" />
              <button onClick={saveName}>Save</button>
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>
              <input value={email} onChange={(event) => setEmail(event.target.value.trim().toLowerCase())} id="user-email" autoComplete="new-password" />
              <button onClick={saveEmail}>Save</button>
            </td>
          </tr>
          <tr>
            <td>New Password</td>
            <td>
              <input type="password" id="user-password" autoComplete="new-password" />
              <button onClick={changePassword}>Save</button>
            </td>
          </tr>
          <tr>
            <td>Badge Number</td>
            <td>{user.number}</td>
          </tr>
          <tr>
            <td>Store(s)/Company</td>
            <td>{user.parents.map(parent => parent.name).join(', ')}</td>
          </tr>
          <tr>
            <td>Is Active</td>
            <td>{user.isActive ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td>Is Employee</td>
            <td>{user.isEmployee ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td>Is Store Admin</td>
            <td>{user.isStoreAdmin ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td>Is Company Admin</td>
            <td>{user.isCompanyAdmin ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td>Is Corporate Admin</td>
            <td>{user.isCorporateAdmin ? "Yes" : "No"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
})
