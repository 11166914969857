import React from 'react'
import Cards from '../../packages/cards3'
import Location from '../../models/location'
import LocationItem from '../../models/locationItem'
import { observer } from 'mobx-react-lite'
import { useGlobals } from '../../globals'
import { MdFavoriteBorder as HeartOutline } from 'react-icons/md'
import { MdFavorite as HeartSolid } from 'react-icons/md'
import './styles.scss'
import { useRecoilState } from 'recoil'
import { orderState, wishlistState } from '../../recoil/order'
import showImage from '../../packages/showImage'
import { Link } from 'react-router-dom'
import { transformCategories } from '../OrderCategories/utils'
import useAppName from '../../hooks/useAppName'

export default observer(function OrderItems({ match, history }) {
  const appName = useAppName()

  // just fallback to some category so don't load all items if null!
  const locationId = Number(match.params.locationId) || 561

  const [cards, setCards] = React.useState(null)
  const [order, setOrder] = useRecoilState(orderState)
  const [wishlist, setWishlist] = useRecoilState(wishlistState)
  const { items } = appName === 'order' ? order : wishlist

  const globals = useGlobals()

  React.useEffect(() => {
    async function fetchData() {
      const rows = await LocationItem.getRows(
        {
          locationId,
          isActive: true,
        },
        true
      ) // cached
      const cards = getCards(rows) // parse out size suffixes and prices
      cards.sort((a, b) => b.id - a.id) // sort most recent items first (where id is a proxy for date added)
      setCards(cards)
      // set breadcrumbs
      const display = await Location.getRow({ id: locationId })
      const displays = transformCategories([display]) // rename friendlier
      globals.breadcrumbs = displays[0].name
    }
    fetchData()
  }, [])

  // parse out suffixes and prices from cards.
  // rows is array of { id, name, code, price }, with code eg '12345-60'.
  // returns array of { id, name, code, quantity, size, sizeTable, priceRange },
  // with code eg '12345', size '60'.
  function getCards(rows) {
    const cardDict = {}
    for (const row of rows) {
      const [codebase, size] = row.code.split('-') // eg '12345-60' -> '12345', '60'
      if (!cardDict[codebase]) {
        cardDict[codebase] = {
          ...row,
          sizeTable: {},
          code: codebase,
        }
      }
      // cardDict[codebase].sizeTable[size || ''] = row.price
      cardDict[codebase].sizeTable[size || 'sizeless'] = row.price
    }
    // get priceRange - eg '$50' if one price, '$50+' if range, or 'TBD' if none
    Object.values(cardDict).forEach(card => {
      const prices = Object.values(card.sizeTable)
      const lowPrice = Math.min(...prices)
      const highPrice = Math.max(...prices)
      const range = lowPrice !== highPrice
      card.priceRange =
        lowPrice === Infinity
          ? 'TBD'
          : '$' + Math.round(lowPrice) + (range ? '+' : '')
    })
    const cards = Object.values(cardDict)
    return cards
  }

  // toggle item in/out of favorites
  function clickHeart(event) {
    const itemId = Number(event.currentTarget.id)
    const card = cards.find(card => card.id === itemId)
    const itemsCopy = { ...items }
    if (items[itemId]) {
      delete itemsCopy[itemId]
    } else {
      const sizes = Object.keys(card.sizeTable)
      const size = sizes.length > 1 ? 'none' : sizes[0] // assign a size or 'none'
      itemsCopy[itemId] = { ...card, quantity: 1, size }
    }
    if (appName === 'order') {
      setOrder({ ...order, items: itemsCopy })
    } else {
      setWishlist({ ...wishlist, items: itemsCopy })
    }
  }

  // show image in dialog
  // copied - keep in synch with OrderFavorites
  async function clickImage(event) {
    event.stopPropagation()
    const itemId = Number(event.currentTarget.id)
    const card = cards.find(card => card.id === itemId)
    const title = card.name
    const message = 'Scroll to see complete image, if needed.'
    const imageUrl = card.largeImageUrl
    await showImage(title, message, imageUrl)
  }

  return (
    <div className="OrderItems">
      <div className="header">Tap image to enlarge, tap heart to favorite.</div>
      <Cards
        cards={cards}
        highlightedCardIds={items}
        CardComponent={ItemCard} // defined below
        clickCard={clickHeart}
        handlers={{ clickImage, clickHeart }}
      />
      <div className="continue">
        {/* <Link to="/order/favorites"> */}
        <Link to={`/${appName}/favorites`}>
          <button>Continue to Favorites</button>
        </Link>
      </div>
    </div>
  )
})

function ItemCard({ card, clickCard, id, highlighted, index, handlers }) {
  const item = card
  return (
    <div className="card" onClick={clickCard} id={id}>
      <span className="name">{card.name}</span>
      <span className="code">{card.code}</span>
      <span className="price">{card.priceRange}</span>
      <span className="image" onClick={handlers.clickImage} id={id}>
        <img
          src={item.imageUrl}
          alt=""
          onError={e => {
            e.target.onerror = null
            e.target.src = 'images/image.png'
          }}
        />
      </span>
      <span
        className={'heart' + (highlighted ? ' red' : ' gray')}
        onClick={handlers.clickHeart}
        id={id}
      >
        {highlighted ? <HeartSolid /> : <HeartOutline />}
      </span>
    </div>
  )
}
