// add/remove items, and drag and drop within and between categories

// uses react-dragula, which is mostly a dom-based lib -
// need to jump through some hoops to work with react,
// mainly by keeping the dom in synch with react state manually.
// also took some work to get it to work with empty lists -
// added a dummy placeholder that shows when list is empty.

import React from 'react'
import dragula from 'react-dragula'
import 'dragula/dist/dragula.css'
import autoScroll from 'dom-autoscroller'
import Select from 'react-select'
import { useGlobals } from '../../globals'
import { useUser } from '../../services/user'
import ItemSearchDropdown from '../../parts/ItemSearchDropdown'
import alert from '../../packages/alert'
import confirm from '../../packages/confirm'
import getChoice from '../../packages/getChoice'
import getItem from '../../parts/getItem'
import Item from '../../models/item'
import Entity from '../../models/entity'
import Location from '../../models/location'
import LocationItem from '../../models/locationItem'
import * as lib from '../../lib'
import './styles.scss'

const placeholderMessage = 'Drag and drop items here'

// initialize dragula
// see https://github.com/bevacqua/dragula
const drake = dragula({
  // don't let user drag the placeholder items for empty lists or disabled items.
  invalid: (el, handle) => {
    if (
      el.classList.contains('placeholder') ||
      el.classList.contains('disabled')
    ) {
      return true
    }
  },
  // don't let user drop an item before the placeholder items for empty lists.
  // sibling is the element after the current drop location.
  accepts: (el, target, source, sibling) => {
    if (sibling && sibling.classList.contains('placeholder')) {
      return false
    }
    return true
  },
})

export default function EditCategory({ history, match }) {
  const globals = useGlobals()
  const [user] = useUser(history) // redirects if not logged in

  const [sourceId, setSourceId] = React.useState(null)

  // left side is 1, right is 2
  const categoryId1 = match.params.categoryId // from url
  const [categoryName1, setCategoryName1] = React.useState('')
  const [categoryName2, setCategoryName2] = React.useState('')
  const [items1, setItems1] = React.useState([])
  const [items2, setItems2] = React.useState([])
  const [categoryId2, setCategoryId2] = React.useState(0) // don't use null or does endless loop
  const [selections, setSelections] = React.useState({})

  const readonly =
    !user.canEditCategories ||
    (sourceId === Entity.corporationPandora && !user.canEditCorporateCategories)

  const canEdit = item =>
    user.canEditCorporateCategories ||
    item.sourceId !== Entity.corporationPandora
  const canCopyRemove = item => user.canEditCategories

  // when categoryId1 changes update breadcrumbs
  //. make a hook for this? so verbose
  React.useEffect(() => {
    async function getCategory() {
      const category = await Location.getRow({ id: categoryId1 })
      globals.breadcrumbs = [
        { name: 'Category Maintenance', key: '/maintain-categories' },
        { name: category.name },
      ]
      setCategoryName1(category.name)
    }
    getCategory()
  }, [categoryId1])

  // when categoryId1 (left side) changes (ie on load page) fetch data
  React.useEffect(updateCategory1, [categoryId1])
  function updateCategory1() {
    async function fetchData() {
      document.body.style.cursor = 'wait'
      const rows1 = await LocationItem.getRows({ locationId: categoryId1 })
      setItems1(rows1)
      const category1 = await Location.getRow({ id: categoryId1 }) // get this for sourceId also
      const sourceId = category1.entity && category1.entity.id
      setSourceId(sourceId)
      console.log(category1)
      document.body.style.cursor = 'default'
    }
    fetchData()
    drake.containers[0] = document.querySelector('#left')
  }

  // when categoryId2 (right side) changes fetch data
  React.useEffect(updateCategory2, [categoryId2])
  function updateCategory2() {
    async function fetchData() {
      document.body.style.cursor = 'wait'
      const rows2 = await LocationItem.getRows({ locationId: categoryId2 })
      setItems2(rows2)
      document.body.style.cursor = 'default'
    }
    if (categoryId2) {
      fetchData()
      drake.containers[1] = document.querySelector('#right')
    }
  }

  // reconstruct an item object from a dom element that has info in its data- attributes.
  // keep in synch with ItemCard below.
  function domToItem(el) {
    const item = {
      id: Number(el.dataset.id),
      code: el.dataset.code,
      name: el.dataset.name,
      barcode: el.dataset.barcode,
      position: Number(el.dataset.position) || 0,
      imageUrl: el.dataset.imageurl,
      sourceName: el.dataset.sourcename,
    }
    return item
  }

  function getElementIndex(el) {
    return [...el.parentNode.children].indexOf(el)
  }

  React.useEffect(() => {
    // handle drop
    // el is the element being dropped,
    // target is the div container it's being dropped in.
    // let isHandlingDrop = false // this flag is to prevent multiple drop events firing occasionally - bug workaround
    drake.on('drop', async (el, target) => {
      // if (isHandlingDrop) return
      // isHandlingDrop = true
      console.log('drop', el, target)

      // nowork
      // if (!user.canCloneCategoriesAndItems) {
      //   await alert("No permission to modify categories.")
      //   drake.cancel(true)
      //   return
      // }

      // get item moved, from dom data attributes
      const itemMoved = domToItem(el)
      const itemId = itemMoved.id
      const sourceCategoryId = Number(el.dataset.categoryid)

      // get target list properties
      const targetSide = target.dataset.side
      const targetCategoryId = Number(target.dataset.categoryid) // note 'id'
      let targetChildren = [...target.children] // convert from DOMlist to regular array
      const targetIndex = getElementIndex(el)
      console.log(targetChildren, targetIndex)

      // dragula inserted the element into the dom -
      // now calculate new position from previous and next positions, if any.
      const previousElement = targetChildren[targetIndex - 1]
      const nextElement = targetChildren[targetIndex + 1]
      console.log(previousElement, nextElement)
      const previousPosition =
        Number(previousElement && previousElement.dataset.position) || 0
      const nextPosition =
        Number(nextElement && nextElement.dataset.position) || 0
      console.log(previousPosition, nextPosition)
      let newPosition
      if (!previousElement && !nextElement) {
        newPosition = 1 // after placeholder
      } else if (!previousElement && nextElement) {
        newPosition = nextPosition - 1
      } else if (previousElement && !nextElement) {
        newPosition = previousPosition + 1
      } else {
        newPosition = (previousPosition + nextPosition) / 2
      }
      console.log('newpos', newPosition)

      if (el.dataset.side === target.id) {
        // moved within same side, ie 'left' or 'right'

        // write new position to db
        const row = {
          locationId: targetCategoryId,
          itemId,
          position: newPosition,
          currentCount: 0,
        }
        if (await LocationItem.updateRow(row)) {
          // note: we don't have access to the current items list in this fn,
          // so we'll build up the list from the dom, then update the items list with setItems.
          // which means state is duplicated in the items variables and the dom -
          // must keep in synch.

          // update dom element's position - both data-position attribute and the position input
          el.dataset.position = newPosition
          const elInput = document.querySelector(`#item${itemId}position`)
          if (elInput) elInput.value = String(newPosition)

          // get new item list and update state so react knows what's happening
          targetChildren = targetChildren.filter(
            el => el.className !== 'placeholder'
          )
          const targetItems = targetChildren.map(domToItem) // must do this AFTER editing position, above
          if (targetSide === 'left') {
            setItems1(targetItems)
          } else {
            setItems2(targetItems)
          }
        } else {
          await alert('Error writing new position to database')
        }
      } else {
        // moved item to OTHER side

        // make item invisible and cancel the DOM move, or react will throw error when
        // tries to remove the element.
        el.style.display = 'none'
        drake.cancel(true)

        // make sure element isn't already in the list
        const itemIdStr = String(itemId)
        const i = targetChildren.findIndex(
          el2 => el2 !== el && el2.id === itemIdStr
        )
        if (i !== -1) {
          el.style.display = 'flex'
          await alert('Item is already in category')
          // isHandlingDrop = false
          return
        }

        //. ideally do this move in a transaction - how do?

        // remove item from current category in db
        console.log(
          'remove item',
          el.dataset.side,
          categoryId1,
          categoryId2,
          sourceCategoryId
        )
        if (
          await LocationItem.deleteRows({
            locationId: sourceCategoryId,
            itemId,
          })
        ) {
          // add item to other category in db, at correct position
          console.log('add item', itemId, targetCategoryId, newPosition)
          if (
            await LocationItem.addRow({
              locationId: targetCategoryId,
              itemId,
              position: newPosition,
              currentCount: 0,
            })
          ) {
            // both worked, so set items1 and items2 state to refresh lists

            const sourceSide = el.dataset.side // 'left' or 'right'
            let sourceChildren = [...el.parentElement.children] // convert from DOMlist to regular array
            sourceChildren = sourceChildren.filter(
              el => el.className !== 'placeholder'
            )

            // from source category, want to delete the item from items list and call setItems
            const sourceItems = sourceChildren.map(domToItem)
            const index = sourceItems.findIndex(item => item.id === itemId)
            if (index !== -1) {
              sourceItems.splice(index, 1) // delete item
            } else {
              await alert("didn't find source element to delete")
            }
            console.log(sourceItems)
            if (sourceSide === 'left') {
              setItems1(sourceItems)
            } else {
              setItems2(sourceItems)
            }

            // for target category, want to insert the item into the items list and call setItems

            // first update the target item's dom props
            // update dom element's position - both data-position attribute and the position input
            el.dataset.position = newPosition
            const elInput = document.querySelector(`#item${itemId}position`)
            if (elInput) elInput.value = String(newPosition)

            // get target items from dom
            targetChildren = targetChildren.filter(
              el => el.className !== 'placeholder'
            )
            const targetItems = targetChildren.map(domToItem)
            console.log(targetItems)

            // // insert new item at index where it was initially dropped
            // targetItems.splice(targetIndex, 1, itemMoved)
            // console.log(targetItems)

            if (targetSide === 'left') {
              setItems1(targetItems)
            } else {
              setItems2(targetItems)
            }
          } else {
            // there's a bug where this gets triggered >1 times,
            // so just ignore it.
            // await alert("Error adding item to new category")
            console.error('Error adding item to new category')
          }
        } else {
          await alert('Error removing item from old category')
        }
        // isHandlingDrop = false
      }
    })

    // setup autoscroll
    const scroll = autoScroll(
      [
        document.querySelector('#left-container'),
        document.querySelector('#right-container'),
      ],
      {
        margin: 20,
        maxSpeed: 7,
        scrollWhenOutside: true,
        autoScroll: function () {
          // only scroll when the pointer is down, and there is a child being dragged
          return this.down && drake.dragging
        },
      }
    )
    console.log(scroll)
  }, []) // do setup only once, after initial render

  function clickCard(event) {
    // add item to list of selected items
    // this doesn't really do anything yet - lets you select cards,
    // but can only drag/drop one at a time.
    event.stopPropagation() // don't trigger clickBackground
    const id = event.currentTarget.id
    event.persist()
    console.log(event, id)
    if (event.ctrlKey) {
      setSelections({ ...selections, [id]: true }) // add to selections
    } else {
      setSelections({ [id]: true })
    }
  }

  function clickBackground() {
    setSelections({})
  }

  // user selected an item from the itemsearch dropdown - add to main category
  // async function addItem(itemId) {
  async function addItem(suggestion) {
    const itemId = suggestion.id
    console.log(sourceId, user)
    if (readonly) {
      await alert('No permission to add items to categories here.')
      return
    }
    const index = items1.findIndex(item => item.id === itemId)
    if (index !== -1) {
      await alert('That item is already in the category')
      return
    }

    // see what categories the item is currently in, if any, and ask if want to add it here
    let categories = await Item.getCategories({ id: itemId })
    if (categories.length > 0) {
      // get dict of valid sources for lookup speed
      // (exclude pandora, because don't want to remove items from those categories)
      const validSourceIds = {}
      for (const source of user.sources) {
        if (source.id !== Entity.corporationPandora) {
          //. magic
          validSourceIds[source.id] = true
        }
      }
      // only include category if it's from a valid source
      categories = categories.filter(
        category => validSourceIds[category.entity.id] === true
      )
      if (categories.length > 0) {
        const categoryNames = categories.map(category => category.name)
        const msg = `Item is currently in the following categories: ${categoryNames.join(
          ', '
        )}. Do you want to move it here (removing other references), or copy it here (leaving the others also)?`
        const options = [
          { value: 'move', label: 'Move' },
          { value: 'copy', label: 'Copy' },
        ]
        const ret = await getChoice('Add Item', msg, options, 'move')
        if (!ret.ok) {
          return // hit cancel
        }
        if (ret.value === 'move') {
          for (const category of categories) {
            await LocationItem.deleteRows({ locationId: category.id, itemId })
          }
        }
      }
    }

    // // see what categories the item is currently in, if any, and ask if want to add it here
    // const locationItems = await LocationItem.getRows({ itemId })
    // if (locationItems && locationItems.length > 0) {
    //   const locationIds = locationItems.map(locationItem => locationItem.locationId)
    //   const categories = []
    //   // get dict of valid sources for lookup speed
    //   // (exclude pandora, because don't want to remove items from those categories)
    //   const validSourceIds = {}
    //   for (const source of user.sources) {
    //     if (source.id !== Entity.corporationPandora) { //. magic
    //       validSourceIds[source.id] = true
    //     }
    //   }
    //   // now go through locations - if it's from a valid source AND is a category, add it to list
    //   for (const locationId of locationIds) {
    //     const location = await Location.getRow({ id: locationId })
    //     // skip if entityId not in list of valid sources
    //     if (!validSourceIds[location.entity.id]) continue
    //     if (location.locationTypeId === Location.locationTypeCategory) {
    //       categories.push(location)
    //     }
    //   }
    //   if (categories.length > 0) {
    //     const categoryNames = categories.map(category => category.name)
    //     const msg = `Item is currently in the following categories: ${categoryNames.join(', ')}. Do you want to move it here (removing other references), or copy it here (leaving the others also)?`
    //     const options = [{value:'move', label:'Move'}, {value:'copy', label:'Copy'}]
    //     const ret = await getChoice("Add Item", msg, options, 'move')
    //     if (!ret.ok) {
    //       return // hit cancel
    //     }
    //     if (ret.value === 'move') {
    //       for (const category of categories) {
    //         await LocationItem.deleteRows({ locationId: category.id, itemId })
    //       }
    //     }
    //   }
    // }
    await addItemToLeftCategory(itemId)
  }

  async function addItemToLeftCategory(itemId, optionalPosition) {
    const locationId = categoryId1 // left side
    const position =
      optionalPosition || (await LocationItem.getNextPosition({ locationId })) // find end of list
    const row = await LocationItem.addRow({ locationId, itemId, position }) // add to list
    if (row) {
      // add new item to items1 state, which should refresh and add to the dom
      const item = await Item.getRow({ id: itemId, user })
      if (item) {
        const newItems = [...items1]
        const newItem = { ...row, ...item } // add position to item info
        newItems.push(newItem)
        setItems1(newItems) // this should update the left container
        // refresh dragula also
        drake.containers[0] = document.querySelector('#left')
        document.querySelector('.items#left').lastChild.scrollIntoView()
      } else {
        await alert('Error reading item from db')
      }
    } else {
      // ignore error - bug causes multiple errors
      // await alert("Error adding item to category")
      console.error(`Error adding item to category`)
    }
  }

  function changeSecondCategory(option) {
    setCategoryName2(option.label)
    drake.containers = drake.containers.slice(0, 1) // remove any existing container
    setCategoryId2(option.value) // triggers load of category items
  }

  function refreshOrder() {
    // fetch LocationItems, set items for both category lists
    drake.containers = []
    updateCategory1()
    updateCategory2()
  }

  async function clickEdit(event, locationId, itemId) {
    const item = await Item.getRow({ id: itemId, user })
    const readonly =
      !user.canEditItems ||
      (item.sourceId === Entity.corporationPandora &&
        !user.canEditCorporateCategories)
    const ret = await getItem('Edit Item', '', item, user, readonly) // note readonly flag
    console.log(ret)
    if (ret.ok) {
      // refresh image
      if (ret.imageFile) {
        lib.showImageFile(
          ret.imageFile,
          `.CategoryItemCard#item${itemId} .image img`
        )
      }
      const data = ret.item
      data.id = itemId
      if (await Item.updateRow(data)) {
        refreshOrder() // update ui
      }
    }
  }

  async function clickCloneEdit(event, locationId, itemId) {
    if (readonly) {
      await alert('No permission to clone and add items to categories here.')
      return
    }
    const item = await Item.getRow({ id: itemId, user })
    item.sourceId = sourceId
    const ret = await getItem('Copy and Edit Item', '', item, user)
    if (ret.ok) {
      console.log(ret.item)
      const newItem = ret.item
      const newItemId = await Item.addRow(newItem) // create item
      if (newItemId) {
        const originalPosition = await LocationItem.getItemPosition(
          locationId,
          itemId
        )
        await addItemToLeftCategory(newItemId, originalPosition) // add to locationItem table and ui
        // remove original item from locationItem table and ui
        if (await LocationItem.deleteRows({ locationId, itemId })) {
          refreshOrder() // remove from list also
        }
      }
    }
  }

  async function clickRemove(event, locationId, itemId) {
    if (readonly) {
      await alert('No permission to remove item from category.')
      return
    }
    if (await confirm('Remove Item', 'Remove this item from the category?')) {
      // if (await confirm("Remove Item", "Remove this item from the category, and check if it can be deleted?")) {
      if (await LocationItem.deleteRows({ locationId, itemId })) {
        // remove from list also
        //. better to splice the correct items list and let react re-render the dom
        refreshOrder()

        // // now see if item is used anywhere else and delete it if not
        // const categoryRefs = await LocationItem.getRows({ itemId })
        // const isReferencedByOtherCategory = categoryRefs.some(ref => ref.locationId !== locationId)
        // if (isReferencedByOtherCategory) {
        //   await alert("Item is referenced by another category and can't be deleted.")
        //   return
        // }
        // const isUsedInInventory = (await InventoryItem.countRows({ itemId })) > 0
        // if (isUsedInInventory) {
        //   await alert("Item is used in an inventory and can't be deleted.")
        //   return
        // }
        // // delete the item
        // if (await confirm("Delete Item", "Item isn't used anywhere else - delete it?")) {
        //   await Item.deleteRow({ itemId })
        // }
      }
    }
  }

  async function clickCreateItem() {
    if (readonly) {
      await alert('No permission to create items here.')
      return
    }
    const blank = {
      // sourceId: user.sources[0].id,
      sourceId,
      code: '',
      name: '',
      color: '',
      barcode: '',
      price: null,
      isActive: true,
    }
    const ret = await getItem('Create Item', '', blank, user)
    console.log(ret)
    if (ret.ok) {
      const item = ret.item
      const itemId = await Item.addRow(item) // create item
      if (itemId) {
        await addItemToLeftCategory(itemId) // add to locationItem table and ui
      }
    }
  }

  const itemSearchVariables = {
    user,
  }

  if (!user) return null
  return (
    <div className="EditCategory" onClick={clickBackground}>
      <div className="header">
        <div className="top">
          {/* <Infobox text={instructions} breakpoint={10000} /> */}
          <button onClick={clickCreateItem}>Create Item...</button>
          <button className="refresh-button" onClick={refreshOrder}>
            Resort Items
          </button>
          <SecondCategoryDropdown onChange={changeSecondCategory} />
          <ItemSearchDropdown
            label="Search/Add Items: "
            selectItem={addItem}
            query={Item.getRows}
            variables={itemSearchVariables}
          />
        </div>
        <div className="bottom">
          {categoryName1 && (
            <div className="category left">
              <div className="name">{categoryName1}</div>
              <div className="number">{items1.length} items</div>
            </div>
          )}
          {categoryName2 && (
            <div className="category right">
              <div className="name">{categoryName2}</div>
              <div className="number">{items2.length} items</div>
            </div>
          )}
        </div>
      </div>
      <div className="body">
        <div className="category">
          {/* dom-autoscroller needs a container to wrap the dragula container */}
          <div id="left-container">
            <div
              className="items"
              id="left"
              data-side="left"
              data-categoryid={categoryId1}
            >
              {items1.length === 0 && (
                <div className="placeholder">{placeholderMessage}</div>
              )}
              {/* >>>> be careful - similar code below! <<<< */}
              {items1.map(item => (
                <ItemCard
                  key={'left' + item.id}
                  categoryId={categoryId1}
                  item={item}
                  clickCard={clickCard}
                  clickCloneEdit={clickCloneEdit}
                  clickEdit={clickEdit}
                  clickRemove={clickRemove}
                  selected={selections[item.id]}
                  side="left"
                  readonly={readonly}
                  canEdit={!readonly && canEdit(item)}
                  canCopyRemove={!readonly && canCopyRemove(item)}
                />
              ))}
              {/* >>>> be careful - similar code below! <<<< */}
            </div>
          </div>
        </div>

        <div className="category">
          <div id="right-container">
            <div
              className="items"
              id="right"
              data-side="right"
              data-categoryid={categoryId2}
            >
              {categoryName2 && items2.length === 0 && (
                <div className="placeholder">{placeholderMessage}</div>
              )}
              {/* >>>> be careful - similar code above! <<<< */}
              {items2.map(item => (
                <ItemCard
                  key={'right' + item.id}
                  categoryId={categoryId2}
                  item={item}
                  clickCard={clickCard}
                  clickCloneEdit={clickCloneEdit}
                  clickEdit={clickEdit}
                  clickRemove={clickRemove}
                  selected={selections[item.id]}
                  side="right"
                  readonly={readonly}
                  canEdit={!readonly && canEdit(item)}
                  canCopyRemove={!readonly && canCopyRemove(item)}
                />
              ))}
              {/* >>>> be careful - similar code above! <<<< */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ItemCard({
  item,
  clickCard,
  clickCloneEdit,
  clickEdit,
  clickRemove,
  selected,
  side,
  categoryId,
  readonly,
  canEdit,
  canCopyRemove,
}) {
  async function updatePosition(event) {
    const position = Number(event.currentTarget.value || 0) || 0 // convert blanks and chars to 0
    const row = {
      locationId: categoryId,
      itemId: item.id,
      position,
      currentCount: 0,
    }
    if (await LocationItem.updateRow(row)) {
      // update element value and data-position value
      const el = document.getElementById(`item${item.id}position`)
      if (el) {
        el.value = position
        el.dataset.position = position
      }
    } else {
      await alert(
        'Error updating position in database - please refresh page and try again.'
      )
    }
  }
  // nowork - why?
  // function handleMouseDown(event) {
  //   console.log(event)
  //   event.stopPropagation()
  //   event.preventDefault()
  // }

  // note the 'disabled' class, which is used to prevent drag and drop
  return (
    <div
      className={
        'CategoryItemCard' +
        (selected ? ' selected' : '') +
        (readonly ? ' disabled' : '')
      }
      id={'item' + item.id}
      onClick={clickCard}
      // note: data attributes must be lowercase!
      // keep in synch with domToItem function.
      data-side={side}
      data-id={item.id}
      data-code={item.code}
      data-name={item.name}
      data-barcode={item.barcode}
      data-position={item.position}
      data-imageurl={item.imageUrl}
      data-categoryid={categoryId}
      data-sourcename={item.sourceName}
    >
      <div className="image">
        <img
          src={item.imageUrl}
          alt=""
          onError={e => {
            e.target.onerror = null
            e.target.src = 'images/image.png'
          }}
        />
      </div>
      <div className="properties">
        <div className="code">{item.code}</div>
        <div className="name">{item.name}</div>
        <div className="barcode">Barcode: {item.barcode}</div>
        <div className="source">Source: {item.sourceName}</div>
        <div className="position">
          Position:&nbsp;
          <input
            type="text"
            id={`item${item.id}position`}
            defaultValue={item.position}
            onBlur={updatePosition}
            // onMouseDown={handleMouseDown}
            // onMouseMove={handleMouseDown}
          />
        </div>
        <div className="corner">
          <button
            disabled={!canEdit}
            onClick={e => clickEdit(e, categoryId, item.id)}
            tabIndex={-1}
          >
            Edit
          </button>
          <button
            disabled={!canCopyRemove}
            onClick={e => clickCloneEdit(e, categoryId, item.id)}
            tabIndex={-1}
          >
            Copy+Edit
          </button>
          <button
            title="Remove item from category"
            disabled={!canCopyRemove}
            onClick={e => clickRemove(e, categoryId, item.id)}
            tabIndex={-1}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  )
}

// dropdown for second category
function SecondCategoryDropdown({ onChange }) {
  const [user] = useUser()
  const [groupedCategories, setGroupedCategories] = React.useState([])
  React.useEffect(() => {
    async function fetchCategories() {
      let groupedCategories = []
      const cached = false
      // loop over available sources (eg Pandora, Sandra Holding, stores)
      for (const source of user.sources) {
        // skip pandora if no permission - otherwise user could
        // dragdrop to/from pandora cats
        if (
          !user.canEditCorporateCategories &&
          source.id === Entity.corporationPandora
        ) {
          continue
        }
        const cats = await Location.getRows(
          {
            entityId: source.id,
            locationTypeId: Location.locationTypeCategory,
          },
          cached
        )
        const options = cats.map(cat => ({ label: cat.name, value: cat.id }))
        groupedCategories = groupedCategories.concat({
          label: source.name,
          options,
        })
      }
      setGroupedCategories(groupedCategories)
    }
    fetchCategories()
  }, []) //. don't pass user or does endless loop?
  return (
    <div className="category-dropdown">
      <span>Second Category:&nbsp;</span>
      <Select options={groupedCategories} onChange={onChange} />
    </div>
  )
}
