import React from 'react'
import Entity from '../../models/entity'
import EntityParent from '../../models/entityParent'
import * as grid from '../../packages/grid'
import { useUser } from '../../services/user'
import getEmployee from '../../parts/getEmployee'
import { withFirebase } from '../../services/Firebase'
import alert from '../../packages/alert'
import Help from '../../packages/Help'
import * as constants from '../../constants'
import './styles.scss'

const breadcrumbs = 'Employee Maintenance'
const entityTypeId = Entity.entityTypePerson
const className = 'MaintainEmployees'

const helpMsg = (
  <div>
    <strong>Employee Maintenance</strong>
    <br />
    <br />
    Email and password can be set by the user in their Account Info page.
    <br />
    <br />
    To remove an employee, mark them inactive. <br />
    <hr />
    Permissions: <br />
    <br />
    Employee
    <br />
    - count already staged inventories - cannot close it
    <br />
    - view display log - cannot make any changes
    <br />
    <br />
    Store admin
    <br />
    - upload IVR
    <br />
    - stage inventories
    <br />
    - edit display log
    <br />
    - close inventories
    <br />
    - clear unstaged inventories
    <br />
    - cannot make changes to categories, items, employees, stores
    <br />
    <br />
    Company admin
    <br />
    - create items for own location
    <br />
    - clone categories and items
    <br />
    - create/edit users
    <br />
    - clear unstaged inventories
    <br />
    - delete completed inventories
    <br />
    - import planograms to own store
    <br />
    <br />
    Corporate admin
    <br />
    - can do everything else not listed
    <br />
  </div>
)

function RenderActions(params) {
  const [user, updateUser] = useUser()
  async function clickEdit() {
    // need to get latest data from grid - params.data is stale (wrapped in closure)
    const row = params.api.getRowNode(params.data.id) // id is still valid though
    const employee = row.data
    const instructions =
      'Note: Email and password can be changed by the user in their Account Info page.'
    const ret = await getEmployee('Edit Employee', instructions, employee, user)
    if (ret.ok) {
      console.log(ret.employee)
      const entity = ret.employee
      if (await Entity.updateRow(entity)) {
        if (ret.modified.stores) {
          // remove all existing entityParent records
          await EntityParent.deleteRows({ entityId: entity.id })
          // add new entityParent records
          for (const parentId of entity.parentIds) {
            await EntityParent.addRow({ entityId: entity.id, parentId })
          }
        }
        // update display
        const row = { ...entity }
        // fetch parent rows and stick on here for display
        row.parents = await Entity.getRowsByArray({ idArray: entity.parentIds })
        row.parentNames = row.parents.map(parent => parent.name).join(', ')
        const transaction = { update: [row] }
        params.api.updateRowData(transaction) // update grid
        // update user object if needed
        if (row.id === user.id) {
          const newUser = { ...user, ...row }
          await updateUser(newUser) // add extra info, update globals and localStorage
        }
      }
    }
  }
  // // note: if delete a user, the email/pw is still registered with firebase auth -
  // // not sure how to remove that record. need users uid.
  // // ie need to login as user to be able to delete their firebase auth record?
  // async function clickDelete() {
  //   console.log(params)
  //   const row = params.data
  //   const entityId = row.id
  //   if (await confirm("Remove Employee", "Are you sure you want to remove this employee? This can only be done if there are no associated records in other tables.")) {
  //     //. move all this code into model? deleteEmployee method?
  //     // check if an auditor
  //     const count1 = await Inventory.countRows({ auditor1Id: entityId })
  //     const count2 = await Inventory.countRows({ auditor2Id: entityId })
  //     if (count1 || count2) {
  //       await alert("Employee is an auditor in the inventory records and can't be deleted.")
  //       return
  //     }
  //     // remove entityParent records
  //     const parentIds = row.parents.map(parent => parent.id)
  //     for (const parentId of parentIds) {
  //       await EntityParent.deleteRows({ entityId, parentId })
  //     }
  //     // delete main record
  //     if (await Entity.deleteRow({ id: entityId })) {
  //       //. change pw to 'deleted'

  //       // remove from grid also
  //       const transaction = { remove: [row] }
  //       params.api.updateRowData(transaction)
  //     } else {
  //       await alert("Error removing employee record " + entityId)
  //     }
  //   }
  // }
  return (
    <span className="actions">
      <button onClick={clickEdit} disabled={!user.canEditEmployees}>
        Edit
      </button>
      {/* <button onClick={clickDelete}>Delete</button> */}
    </span>
  )
}

export default withFirebase(function MaintainEmployees(props) {
  const [user, updateUser] = useUser(props.history)

  // this controls what type of records are shown - active, inactive, or both (when null)
  const [isActive, setIsActive] = React.useState(true)

  const editable = user.canEditEmployees

  const columnDefs = [
    { field: 'name', width: 190, tooltipField: 'name', editable: false },
    {
      field: 'parentNames',
      headerName: 'Store/Company',
      width: 160,
      editable: false,
      tooltipField: 'parentNames',
    },
    {
      field: 'number',
      headerName: 'Ee Num',
      editable: false,
      width: 110,
    }, // don't allow editing because could overwrite other badgenum
    {
      field: 'isActive',
      headerName: 'Active',
      width: 90,
      cellRenderer: grid.renderCheckbox,
      cellClass: 'center',
      cellRendererParams: { Model: Entity },
      editable,
    },
    {
      field: 'isEmployee',
      headerName: 'Employee',
      width: 120,
      cellRenderer: grid.renderCheckbox,
      cellClass: 'center',
      cellRendererParams: { Model: Entity },
      editable,
    },
    {
      field: 'isStoreAdmin',
      headerName: 'Store Admin',
      width: 90,
      cellRenderer: grid.renderCheckbox,
      cellClass: 'center',
      cellRendererParams: { Model: Entity },
      editable,
    },
    {
      field: 'isCompanyAdmin',
      headerName: 'Co. Admin',
      width: 100,
      cellRenderer: grid.renderCheckbox,
      cellClass: 'center',
      cellRendererParams: { Model: Entity },
      editable,
    },
    {
      field: 'isCorporateAdmin',
      headerName: 'Corp. Admin',
      width: 110,
      cellRenderer: grid.renderCheckbox,
      cellClass: 'center',
      cellRendererParams: { Model: Entity },
      editable: user.canModifyCorporateAdminFlag,
    },
    { field: 'email', width: 200, editable: false, tooltipField: 'email' }, // can't change email here - must do through account page
    {
      field: 'actions',
      width: 100,
      cellRendererFramework: RenderActions,
      editable: false,
    },
  ]

  const gridOptions = {
    columnDefs,
    headerHeight: 50, // px (default 25)
    getRowNodeId: data => data.id, // need this so can call api.getRowNode(id)
  }

  let parentIds = []
  if (user && user.isStoreAdmin) {
    parentIds = parentIds.concat(user.storeIds)
  }
  if (user && user.isCompanyAdmin) {
    parentIds = parentIds.concat(user.companyIds)
  }
  if (user && user.isCorporateAdmin) {
    parentIds = parentIds.concat(user.corporateIds)
  }

  const variables = { entityTypeId, parentIds, isActive }

  async function editCallback(row) {
    if (row.id === user.id) {
      const newUser = { ...user, ...row }
      await updateUser(newUser)
    }
  }

  // function Header({ history, firebase }, gridApi, variables, setData) {
  function Header({ history, firebase }, gridApi) {
    const [user] = useUser()

    async function clickAddEmployee() {
      if (!user.canEditEmployees) {
        await alert('No permission to add employees.')
        return
      }
      const defaults = {
        isActive: true,
        isEmployee: true,
        isStoreAdmin: false,
        isCompanyAdmin: false,
        isCorporateAdmin: false,
      }
      const instructions =
        "Note: the initial password is 'password', and should be changed by the user in their Account Info page."
      const ret = await getEmployee(
        'Add Employee',
        instructions,
        defaults,
        user,
        true
      )
      if (ret.ok) {
        const entity = await Entity.addRow(ret.employee) // also adds entityParent records as needed
        if (entity) {
          firebase
            .createUser(entity.email, constants.defaultPassword) // register with firebase auth
            .then(() => {
              const transaction = { add: [entity] }
              gridApi.updateRowData(transaction)
              gridApi.ensureNodeVisible(entity)
              alert(instructions)
            })
            .catch(error =>
              alert(
                `Couldn't register user with Firebase authentication:` +
                  error.message
              )
            )
        }
      }
    }

    const status =
      isActive === true ? 'active' : isActive === false ? 'inactive' : 'both'

    async function changeStatus(e) {
      console.log(e, e.currentTarget)
      const status = e.currentTarget.value
      const isActive =
        status === 'active' ? true : status === 'inactive' ? false : null
      setIsActive(isActive)
      variables.isActive = isActive
      const rows = await Entity.getRows(variables)
      gridApi.setRowData(rows)
    }

    return (
      <div className="header">
        {/* <span className="search">Search: <input type="text" /></span> */}
        <span className="show-status">
          <span>Show&nbsp;</span>
          <select
            name="show-status"
            id="show-status"
            value={status}
            onChange={changeStatus}
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="both">Both</option>
          </select>
        </span>
        <span className="right">
          <button onClick={clickAddEmployee}>Add Employee...</button>
          <Help msg={helpMsg} />
        </span>
      </div>
    )
  }

  const g = grid.Grid(props, {
    gridOptions,
    className,
    Model: Entity,
    variables,
    Header,
    breadcrumbs,
    editCallback,
  })

  if (!user) return null
  return g
})

// no longer needed
// function Footer({ history, firebase }, gridApi, variables, data) {
//   const validateEmail = (email) => {
//     const re = /\S+@\S+\.\S+/;
//     return re.test(email);
//   }
//   const clickUpload = async () => {
//     if (await confirm('Are you sure you want to upload these employees to Firebase?')) {
//       data.forEach(employee => {
//         if (employee.isActive && validateEmail(employee.email)) {
//           // pad out user's password to 6 characters (required by firebase auth)
//           const password = employee.password.padEnd(6, 'a')
//           console.log('Create', employee.email, password)
//           firebase.createUser(employee.email, password)
//             .then(() => console.log(`${employee.email} user created`))
//             .catch(e => console.log(e.message))
//         }
//       })
//     }
//   }
//   if (process.env.NODE_ENV !== 'development') return null
//   return (
//     <div className="footer">
//       <button onClick={clickUpload}>Upload Employees to Firebase Auth...</button>
//     </div>
//   )
// }
