// business logic

/**
 * initialize data structs
 * @param ees - list of { number, name } from xls
 * @param eedict - breakdown by ee
 */
export function initialize(ees, eedict, storedict) {
  for (const ee of ees) {
    ee.sales = 0
    ee.transactions = 0
    ee.units = 0
    ee.applications = 0
    ee.percentCards = 0
    ee.salesCommission = 0
    ee.cardsCommission = 0
    ee.commission = 0
    eedict[ee.number] = ee
  }
}

/**
 * calculate sales and commissions
 * @param sales - rows of sales records from sales csv
 * @param structure - json with commission structure { goal, commission }
 * @param eedict - breakdown sales by ee
 * @param storedict - breakdown sales by store then ee
 */
export function calcSales(sales, structure, eedict, storedict) {
  const metric = structure.metric
  const goal = getDollarValue(structure.goal)
  const commission = getPercentage(structure.commission)
  console.log('sales', { metric, goal, commission })

  // read sales, transactions, units for each valid ee
  const rows = sales
  for (const row of rows) {
    console.log({ row })
    const storenum = row['Store Number']
    const eenum = row['Employee Number']
    if (eedict[eenum]) {
      const sales = getDollarValue(row['Net Sales'])
      const transactions = Number(row['# of Transactions'])
      const units = Number(row['Net Units'])

      // update eedict
      eedict[eenum].sales += sales
      eedict[eenum].transactions += transactions
      eedict[eenum].units += units

      // update storedict
      // because we need to break down each ee's sales etc by store
      if (!storedict[storenum]) {
        storedict[storenum] = {}
      }
      if (!storedict[storenum][eenum]) {
        storedict[storenum][eenum] = { sales: 0, transactions: 0, units: 0 }
      }
      const ee = storedict[storenum][eenum]
      ee.number = eenum
      ee.name = eedict[eenum].name
      ee.sales += sales
      ee.transactions += transactions
      ee.units += units
      ee.salesCommission = 0
      ee.cardsCommission = 0
    }
  }
  console.log({ storedict })

  // calculate sales commission for each valid ee
  for (const ee of Object.values(eedict)) {
    const sales = ee.sales
    const transactions = ee.transactions
    ee.avgSale =
      transactions > 0 ? Number((sales / transactions).toFixed(2)) : 0
    const reachedGoal =
      (metric == 'Net Sales' && sales >= goal) ||
      (metric === 'Avg Sale' && ee.avgSale >= goal)
    if (reachedGoal) {
      const salesCommission = Number((commission * sales).toFixed(2))
      ee.salesCommission = salesCommission
    }
  }
  console.log({ eedict })
}

/**
 * calculate card transactions and commissions
 * @param cards - rows from application xls
 * @param structure - the commission structure json
 * @param eedict - breakdown data by ee
 */
export function calcCards(cards, structure, eedict) {
  const metric = structure.metric
  const goal = getPercentage(structure.goal)
  const commission = getPercentage(structure.commission)
  console.log('cards', { metric, goal, commission })
  const header = cards[0]
  const rows = cards.slice(1)
  const colEmployee = header.findIndex(col => col === 'Employee ID')

  // get card transactions for each ee
  for (const row of rows) {
    const employeeId = row[colEmployee]
    if (eedict[employeeId]) {
      eedict[employeeId].applications += 1
    }
  }

  // get percent card sales and commission
  for (const ee of Object.values(eedict)) {
    ee.percentCards =
      ee.transactions > 0
        ? Number((ee.applications / ee.transactions).toFixed(3))
        : 0
    if (ee.percentCards >= goal) {
      ee.cardsCommission = Number((commission * ee.sales).toFixed(2))
    }
  }

  console.log({ eedict })
}

/**
 * calculate commissions
 * @param storedict - breakdown data by store then ee
 * @param eedict - breakdown data by ee
 */
export function calcCommissions(storedict, eedict) {
  // breakdown by ee
  for (const ee of Object.values(eedict)) {
    ee.commission = ee.salesCommission + ee.cardsCommission
  }
  // breakdown by store then ee
  // need to distribute the commissions to stores based on fraction at store
  const stores = Object.values(storedict)
  for (const store of stores) {
    const ees = Object.values(store)
    for (const ee of ees) {
      const eenum = ee.number
      const netsales = Number(eedict[eenum].sales)
      const storeFraction = netsales > 0 ? ee.sales / netsales : 0
      ee.salesCommission = Number(
        (eedict[eenum].salesCommission * storeFraction).toFixed(2)
      )
      ee.cardsCommission = Number(
        (eedict[eenum].cardsCommission * storeFraction).toFixed(2)
      )
      ee.commission = ee.salesCommission + ee.cardsCommission
    }
  }
  console.log('final storedict', { storedict })
}

function getDollarValue(str) {
  return Number(str.replace(/[$,]/g, ''))
}
function getPercentage(str) {
  return Number(str.replace(/[%]/g, '')) / 100
}
