// search for an item in a dropdown

import React from 'react'
import Autosuggest from 'react-autosuggest'
import './styles.scss'


export default function ItemSearchDropdown({ 
  label,
  placeholder='',
  selectItem, // optional callback when item selected
  Card=ItemSearchCard,
  query,
  variables,
  limit=50,
}) {

  const [value, setValue] = React.useState('')
  const [suggestions, setSuggestions] = React.useState([])

  function onChange(event, { newValue, method }) {
    setValue(newValue)
  }
  
  async function onSuggestionsFetchRequested({ value, reason }) {
    if (reason === 'input-changed') {
      const code = `%${value}%`
      const rows = await query({ ...variables, code, limit }) // fetch rows
      setSuggestions(rows)
    }
  }

  function onSuggestionsClearRequested() {
    setSuggestions([])
  }

  function shouldRenderSuggestions(value) {
    return value.trim().length > 2
  }

  function onSuggestionSelected(event, { suggestion }) {
    if (selectItem) {
      console.log(suggestion)
      // selectItem(suggestion.id)
      selectItem(suggestion)
      setValue('')
    }
  }

  function getSuggestionValue(suggestion) {
    return suggestion && (suggestion.code + ": " + suggestion.name)
  }

  const inputProps = {
    value,
    onChange,
    placeholder,
  }

  return (
    <span className="ItemSearchDropdown">
      {label && <span className="label">{label}</span>}
      <Autosuggest 
        suggestions={suggestions}
        shouldRenderSuggestions={shouldRenderSuggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={Card}
        inputProps={inputProps}
      />
    </span>
  )
}


function ItemSearchCard(item) {
  return (
    <div className="ItemSearchCard suggestion">
      <img src={item.imageUrl} alt="" onError={e=>{e.target.onerror = null; e.target.src="images/image.png"}} />
      <div>
        <div>{item.code}</div>
        <div className="name">{item.name}</div>
        <div className="barcode">Barcode: {item.barcode}</div>
        <div className="source">Source: {item.sourceName}</div>
      </div>
    </div>
  )
}
