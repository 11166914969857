import { gql } from 'apollo-boost'
import apolloClient from '../../services/apolloClient'
import * as constants from '../../constants'
import Inventory from '../inventory'
// import Location from '../location'
import LocationItem from '../locationItem'

export default class InventoryItem {
  static async getRows({ inventoryId, itemId }, cached = false) {
    // get inventory definition
    const inventory = await Inventory.getRow({ inventoryId, itemId }, cached)
    const { locationId, storeId } = inventory
    console.log(inventory)

    // get category definition to get positions
    // (in lieu of doing via graphql which i dont know how to do)
    const locationItems = await LocationItem.getRows({ locationId }, cached)
    const positions = {}
    locationItems.forEach(li => (positions[li.itemId] = li.position))
    // console.log(positions)

    // note: hardcoding to isActive items
    const table = 'inventoryItem'
    const query = gql`
      query GetInventoryItems($inventoryId: Int!, $storeId: Int, $itemId: Int) {
        __typename
        ${table}(where: {inventoryId: {_eq: $inventoryId}, itemId: {_eq: $itemId}, item: {isActive: {_eq: true}}}) {
          # first grab stuff from the inventoryItem table
          inventoryId
          itemId
          price
          cost
          displayOnHand
          trayOnHand
          externalOnHand
          # then join on the item table to get each item's info
          item {
            code
            color
            name
            price
            cost
            barcode
          }
          # also join on viewStoreDisplays to get the items in display cases
          viewStoreDisplays(where: { entityId: {_eq: $storeId} }) {
            locationId
            locationName
            locationNumber
            currentCount
          }
        }
      }
    `

    // do query
    const variables = { inventoryId, storeId, itemId }
    const fetchPolicy = cached ? 'cache-first' : 'network-only'
    const { data } = await apolloClient.query({ query, variables, fetchPolicy })
    const rows = data[table]
    console.log('query result for', variables, rows)

    // bring stuff up from depths
    rows.forEach(row => flattenRow(row))

    // assign positions to rows
    rows.forEach(row => (row.position = positions[row.itemId]))
    console.log('rows with positions', rows)

    // sort by 'order', which prioritizies discrepancies
    rows.forEach(
      row =>
        (row.order =
          row.countDiscrepancyFixed < 0
            ? row.countDiscrepancyFixed * 1000
            : -row.countDiscrepancyFixed)
    )
    rows.sort((a, b) => (a.code < b.code ? -1 : 1)) // sort by code within each order
    rows.sort((a, b) => a.order - b.order)

    return rows
  }

  static async updateRow(data, changes = {}) {
    // merge existing and new values
    const merged = { ...data, ...changes }
    const {
      inventoryId,
      itemId,
      price,
      cost,
      displayOnHand,
      trayOnHand,
      externalOnHand,
    } = merged
    const variables = {
      inventoryId,
      itemId,
      price,
      cost,
      displayOnHand,
      trayOnHand,
      externalOnHand,
    }
    try {
      const action = 'update_inventoryItem'
      const mutation = gql`
        mutation UpdateInventoryItem($inventoryId: Int!, $itemId: Int!, $price: Float, $cost: Float, $displayOnHand: Int, $trayOnHand: Int, $externalOnHand: Int) {
          __typename
          ${action}(_set: {price: $price, cost: $cost, displayOnHand: $displayOnHand, trayOnHand: $trayOnHand, externalOnHand: $externalOnHand}, where: {inventoryId: {_eq: $inventoryId}, itemId: {_eq: $itemId}}) {
            affected_rows
          }
        }
      `
      const ret = await apolloClient.mutate({ mutation, variables })
      // check that ONE row was affected
      if (
        ret &&
        ret.data &&
        ret.data[action] &&
        ret.data[action].affected_rows === 1
      ) {
        return true
      }
    } catch (e) {
      alert(e.message)
    }
  }

  static async getRow(variables) {
    const rows = await InventoryItem.getRows(variables)
    if (rows && rows.length === 1) {
      const row = rows[0]
      return row
    }
    // // setItemCount below expects this error if row not found
    // throw new Error(
    //   `Found ${rows && rows.length} rows with ${JSON.stringify(variables)}`
    // )
    return null
  }

  static async deleteRows({ inventoryId }) {
    const action = 'delete_inventoryItem'
    const mutation = gql`
      mutation DeleteRows($inventoryId: Int!) {
        __typename
        ${action}(where: {inventoryId: {_eq: $inventoryId}}) {
          affected_rows
        }
      }
    `
    const variables = { inventoryId }
    try {
      const ret = await apolloClient.mutate({ mutation, variables })
      // return ret && ret.data && ret.data[action] && ret.data[action].affected_rows
      console.log(ret)
      return true
    } catch (e) {
      console.error(e)
      alert(e)
    }
  }

  static async setItemCount(inventoryId, itemId, count) {
    console.log('setItemCount', inventoryId, itemId, count)
    // let row
    // try {
    //   row = await InventoryItem.getRow({ inventoryId, itemId })
    // } catch(e) {
    //   row = await InventoryItem.addRow({ inventoryId, itemId })
    // }
    console.log('call getRow', { inventoryId, itemId })
    let row = await InventoryItem.getRow({ inventoryId, itemId })
    console.log('got row', row)
    if (!row) {
      console.log('call addRow', { inventoryId, itemId, count })
      // row = await InventoryItem.addRow({ inventoryId, itemId })
      row = await InventoryItem.addRow({
        inventoryId,
        itemId,
        trayOnHand: count,
      })
      console.log('got row', row)
      return row
    }
    console.log('call updateRow', { row, count })
    const okay = await InventoryItem.updateRow(row, { trayOnHand: count })
    return okay
  }

  static async addRow({
    inventoryId,
    itemId,
    price = 0,
    cost = 0,
    displayOnHand = 0,
    trayOnHand = 0,
    externalOnHand = 0,
  }) {
    try {
      const action = 'insert_inventoryItem'
      const mutation = gql`
        mutation AddRow($inventoryId: Int!, $itemId: Int!, $price: Float, $cost: Float, $displayOnHand: Int, $trayOnHand: Int, $externalOnHand: Int) {
          __typename
          ${action}(objects: {inventoryId: $inventoryId, itemId: $itemId, price: $price, cost: $cost, displayOnHand: $displayOnHand, trayOnHand: $trayOnHand, externalOnHand: $externalOnHand }) {
            returning { inventoryId, itemId }
          }
        }
      `
      const variables = {
        inventoryId,
        itemId,
        price,
        cost,
        displayOnHand,
        trayOnHand,
        externalOnHand,
      }
      const ret = await apolloClient.mutate({ mutation, variables })
      if (ret && ret.data && ret.data[action] && ret.data[action].returning) {
        const row = ret.data[action].returning[0]
        return row
      }
      throw new Error('Should have gotten one record back for new row')
    } catch (e) {
      alert(e.message)
    }
  }

  static async addRows(rows) {
    const objects = rows
      .map(
        row =>
          `{inventoryId: ${row.inventoryId}, itemId: ${row.itemId}, price:${row.price}, cost:${row.cost}, externalOnHand:${row.quantity} }`
      )
      .join(',')
    const action = 'insert_inventoryItem'
    const mutation = gql`
      mutation AddRows {
        __typename
        ${action}(objects: [ ${objects} ]) { affected_rows }
      }
    `
    const ret = await apolloClient.mutate({ mutation })
    if (
      ret &&
      ret.data &&
      ret.data[action] &&
      ret.data[action].affected_rows === objects.length
    ) {
      return true
    }
  }

  static async countRows({ itemId }, cached = false) {
    // const rows = await Entity.getRows(variables)
    const action = 'inventoryItem_aggregate'
    const query = gql`
      query CountRows($itemId: Int) {
        __typename
        ${action}(where: {itemId: {_eq: $itemId}}) {
          aggregate { count }
        }
      }
    `
    const variables = { itemId }
    const fetchPolicy = cached ? 'cache-first' : 'network-only'
    const ret = await apolloClient.query({ query, variables, fetchPolicy })
    const count =
      ret && ret.data && ret.data[action] && ret.data[action].aggregate.count
    return count
  }
}

function flattenRow(row) {
  row.id = row.itemId
  row.code = row.item.code
  row.color = row.item.color
  row.name = row.item.name
  row.barcode = row.item.barcode

  // use item's price/cost if not specified in the inventoryItem table
  row.price = row.price || row.item.price
  row.cost = row.cost || row.item.cost

  // get display log counts
  row.displayCases = row.viewStoreDisplays
  row.displayCasesTotal = row.displayCases.reduce(
    (prev, curr) => prev + curr.currentCount,
    0
  )

  // this gives the frozen value, which we need for the invreview -
  // for invcount want 'live' value
  row.countDiscrepancyFixed =
    row.trayOnHand + row.displayOnHand - row.externalOnHand
  row.countDiscrepancyLive =
    row.trayOnHand + row.displayCasesTotal - row.externalOnHand

  row.priceDiscrepancy = row.price * row.countDiscrepancyFixed
  row.costDiscrepancy = row.cost * row.countDiscrepancyFixed

  // initialize history of +1's etc
  row.adjustHistory = ''
  row.initialCount = row.trayOnHand
  row.currentCount = row.trayOnHand
  row.delta = 0

  //. make fn getImageUrl(row.code)
  row.imageUrl =
    constants.baseImageUrlSmall + row.code + '.jpg' + constants.itemImageSuffix
  row.largeImageUrl =
    constants.baseImageUrlLarge + row.code + '.jpg' + constants.itemImageSuffix
}

// // sum up locationItem.currentCount values
// function getDisplayCasesTotal(row) {
//   let count = 0
//   for (let dc of row.displayCases) {
//     count += dc.currentCount
//   }
//   return count
// }
