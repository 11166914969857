import { gql } from 'apollo-boost'
import moment from 'moment'
import apolloClient from '../../services/apolloClient'
import InventoryItem from '../inventoryItem'
import alert from '../../packages/alert'
import Progress from '../../packages/progress'
import * as lib from '../../lib'


export default class Inventory {

  // enums
  static statusUnstaged = 1
  static statusStaged = 2
  static statusStarted = 3
  static statusPaused = 4 // not used yet
  static statusCompleted = 5
  static statuses = 'Unstaged,Staged,Started,Paused,Completed'.split(',')
  static getStatusName(statusId) {
    return Inventory.statuses[statusId - 1]
  }

  // inventory table has 12k+ records - hence limit - need to paginate/filter through them
  //. pass user instead of storeIds to match other models
  // filterType is 'today', 'past', or undefined
  static async getRows(
    { inventoryId, storeIds, statusId, filterType, limit, offset },
    cached = false
  ) {
    // bug: if didn't pass filterType, it returned all inventories -
    // which was okay for the invmaint screen, but deleteUnstaged then deleted ALL
    // inventories for the stores.
    // unintended consequences.
    // mitigation - more testing, checklist, backups
    // const statusPhrase = filterType === 'today' ? "statusId: {_eq: $statusId},"
    //   : filterType === 'past' ? `statusId: {_neq: ${Inventory.statusCompleted}},`
    //   : ''
    // const timestampPhrase = filterType === 'today' ? "startTimestamp: {_gt: $startTimestamp},"
    //   : filterType === 'past' ? "startTimestamp: {_lt: $startTimestamp},"
    //   : ''
    let statusPhrase
    let timestampPhrase
    if (filterType === 'today') {
      statusPhrase = 'statusId: {_eq: $statusId},'
      timestampPhrase = 'startTimestamp: {_gt: $startTimestamp},'
    } else if (filterType === 'past') {
      statusPhrase = `statusId: {_neq: ${Inventory.statusCompleted}},`
      timestampPhrase = 'startTimestamp: {_lt: $startTimestamp},'
    } else {
      statusPhrase = 'statusId: {_eq: $statusId},'
      timestampPhrase = ''
    }

    const query = gql`
      query GetInventories(
        $inventoryId: Int, 
        $storeIds: [Int!], 
        $statusId: Int, 
        $startTimestamp: timestamptz, 
        $limit: Int, 
        $offset: Int
      ) {
        __typename
        inventory(limit: $limit, offset: $offset, where: {
          storeId: {_in: $storeIds}, 
          id: {_eq: $inventoryId},
          ${statusPhrase}
          ${timestampPhrase}
        }, order_by: {startTimestamp: desc, location: {name: asc}}) {
          id
          store: entity { id, name }
          category: location { id, name }
          startTimestamp
          endTimestamp
          auditor1Id
          auditor2Id
          entityByAuditor1id { id, name }
          entityByAuditor2id { id, name }
          storeId
          locationId
          statusId
        }
      }
    `
    const startTimestamp = filterType ? moment().startOf('day') : null
    const fetchPolicy = cached ? 'cache-first' : 'network-only'
    const variables = {
      inventoryId,
      storeIds,
      statusId,
      limit,
      offset,
      startTimestamp,
    }
    console.log('Inventory.getRows', variables)
    console.log(statusPhrase)
    const { data } = await apolloClient.query({ query, variables, fetchPolicy })
    const rows = data.inventory // inventory is the table name
    rows.forEach(row => flattenRow(row))
    return rows
  }

  static async getRow({ inventoryId }) {
    const rows = await Inventory.getRows({ inventoryId })
    const row = rows && rows.length === 1 && rows[0]
    return row
  }

  static async updateRow(data, changes = {}) {
    // merge existing and new values
    const merged = { ...data, ...changes }
    const { id, endTimestamp, statusId, auditor1Id, auditor2Id } = merged
    const variables = { id, endTimestamp, statusId, auditor1Id, auditor2Id }
    try {
      const action = 'update_inventory'
      const mutation = gql`
        mutation UpdateInventory($id: Int!, $endTimestamp: timestamptz, $statusId: Int, $auditor1Id: Int, $auditor2Id: Int) {
          __typename
          ${action}(_set: {endTimestamp: $endTimestamp, statusId: $statusId, auditor1Id: $auditor1Id, auditor2Id: $auditor2Id}, where: {id: {_eq: $id}}) {
            affected_rows
          }
        }
      `
      const ret = await apolloClient.mutate({ mutation, variables })
      return (
        ret &&
        ret.data &&
        ret.data[action] &&
        ret.data[action].affected_rows === 1
      )
    } catch (e) {
      alert(e.message)
    }
  }

  // get inventory discrepancy report for download
  static async getDiscrepancyItems({ inventoriesIds }, cached = false) {
    const query = gql`
      query getDiscrepancyReport($inventoriesIds: [Int!]) {
        __typename
        inventory(where: { id: { _in: $inventoriesIds } }) {
          id
          startTimestamp
          endTimestamp
          storeId
          location {
            id
            name
          }
          entity {
            id
            name
          }
          inventoryItems {
            itemId
            item {
              id
              name
              code
              price
              cost
            }
            price
            cost
            displayOnHand
            trayOnHand
            externalOnHand
          }
        }
      }
    `
    const variables = { inventoriesIds }
    const fetchPolicy = cached ? 'cache-first' : 'network-only'
    const { data } = await apolloClient.query({ query, variables, fetchPolicy })
    const rows = data.inventory // inventory is the table name
    console.log(rows)
    const items = flattenInventories(rows)
    return items
  }

  // get previous inventory for given item in same store
  // used by inventory count expansion row
  static async getLastInventoryForItem(
    { inventoryId, itemId },
    cached = false
  ) {
    // get store associated with the inventory
    const inventory = await Inventory.getRow({ inventoryId })
    const storeId = inventory.storeId
    console.log(inventory, storeId)
    const query = gql`
      query GetLastInventory(
        $inventoryId: Int!
        $storeId: Int!
        $itemId: Int!
      ) {
        __typename
        inventory(
          limit: 1
          order_by: { id: desc }
          where: {
            storeId: { _eq: $storeId }
            endTimestamp: { _is_null: false }
            id: { _lt: $inventoryId }
            inventoryItems: { itemId: { _eq: $itemId } }
          }
        ) {
          id
          endTimestamp
          inventoryItems(where: { itemId: { _eq: $itemId } }) {
            displayOnHand
            trayOnHand
            externalOnHand
          }
        }
      }
    `
    const variables = { inventoryId, storeId, itemId }
    const fetchPolicy = cached ? 'cache-first' : 'network-only'
    const { data } = await apolloClient.query({ query, variables, fetchPolicy })
    const rows = data.inventory // inventory is the table name
    // should get 0 or 1 rows
    if (rows && rows.length === 1) {
      const row = rows[0]
      const inventoryItem = row.inventoryItems && row.inventoryItems[0]
      const countDiscrepancyFixed =
        inventoryItem &&
        inventoryItem.displayOnHand +
          inventoryItem.trayOnHand -
          inventoryItem.externalOnHand
      const inventory = {
        inventoryId: row.id,
        endTimestamp: row.endTimestamp,
        countDiscrepancyFixed,
      }
      return inventory
    }
  }

  static async addRow({
    storeId,
    locationId,
    auditor1Id,
    auditor2Id,
    startTimestamp,
    statusId,
  }) {
    try {
      const mutation = gql`
        mutation AddRow(
          $storeId: Int
          $locationId: Int
          $auditor1Id: Int
          $auditor2Id: Int
          $startTimestamp: timestamptz
          $statusId: Int
        ) {
          __typename
          insert_inventory(
            objects: {
              storeId: $storeId
              locationId: $locationId
              auditor1Id: $auditor1Id
              auditor2Id: $auditor2Id
              startTimestamp: $startTimestamp
              statusId: $statusId
            }
          ) {
            returning {
              id
            }
          }
        }
      `
      const variables = {
        storeId,
        locationId,
        auditor1Id,
        auditor2Id,
        startTimestamp,
        statusId,
      }
      const ret = await apolloClient.mutate({ mutation, variables })
      // console.log(ret)
      if (
        ret &&
        ret.data &&
        ret.data.insert_inventory &&
        ret.data.insert_inventory.returning
      ) {
        const row = ret.data.insert_inventory.returning[0]
        // console.log(row)
        return row
      }
      throw new Error('Should have gotten one record back for new row')
    } catch (e) {
      alert(e.message)
    }
  }

  static async deleteRow({ inventoryId }) {
    // delete related records first
    // alternative would be to turn on cascade delete in the db
    if (await InventoryItem.deleteRows({ inventoryId })) {
      // now the main record
      const action = 'delete_inventory'
      const mutation = gql`
        mutation DeleteRow($inventoryId: Int!) {
          __typename
          ${action}(where: {id: {_eq: $inventoryId}}) {
            affected_rows
          }
        }
      `
      const variables = { inventoryId }
      const ret = await apolloClient.mutate({ mutation, variables })
      return (
        ret &&
        ret.data &&
        ret.data[action] &&
        ret.data[action].affected_rows > 0
      )
    }
  }

  // count number of inventory records associated with given location/category
  // and optional itemId and auditors.
  // used by maintain categories before deleting category,
  // and edit category before removing item,
  // and maintain ees before deleting
  // and maintain stores before deleting.
  static async countRows(
    { storeId, locationId, itemId, auditor1Id, auditor2Id },
    cached = false
  ) {
    const action = 'inventory_aggregate'
    const query = gql`
      query CountRows($storeId: Int, $locationId: Int, $itemId: Int, $auditor1Id: Int, $auditor2Id: Int) {
        __typename
        ${action}(where: {storeId: {_eq: $storeId}, locationId: {_eq: $locationId}, auditor1Id: {_eq: $auditor1Id}, auditor2Id: {_eq: $auditor2Id}, inventoryItems: {itemId: {_eq: $itemId}}}) {
          aggregate { count }
        }
      }
    `
    const variables = { storeId, locationId, itemId, auditor1Id, auditor2Id }
    const fetchPolicy = cached ? 'cache-first' : 'network-only'
    const ret = await apolloClient.query({ query, variables, fetchPolicy })
    const count =
      ret && ret.data && ret.data[action] && ret.data[action].aggregate.count
    return count
  }

  // remove references to the given location/category from all inventory records.
  // used by category maintenance screen before deleting category.
  static async clearCategoryReferences({ locationId }) {
    try {
      const action = 'update_inventory'
      const mutation = gql`
        mutation ClearCategoryReferences($locationId: Int!) {
          __typename
          ${action}(where: {locationId: {_eq: $locationId}}, _set: {locationId: null}) {
            affected_rows
          }
        }
      `
      const variables = { locationId }
      console.log('clearCategoryReferences', variables)
      const ret = await apolloClient.mutate({ mutation, variables })
      // const count = ret && ret.data && ret.data[action] && ret.data[action].affected_rows
      // return count
      return ret
    } catch (e) {
      alert(e.message)
    }
  }

  // delete unstaged inventories
  static async deleteUnstaged(storeIds, updateCallback) {
    console.log('deleteUnstaged', storeIds)
    try {
      const rows = await Inventory.getRows({
        storeIds,
        statusId: Inventory.statusUnstaged,
      })
      let i = 1
      for (const row of rows) {
        const msg = `${i} of ${rows.length}: ${row.categoryName}`
        updateCallback(msg)
        const inventoryId = row.id
        await Inventory.deleteRow({ inventoryId })
        i += 1
      }
    } catch (e) {
      console.error(e)
      await alert(e.message)
      return false
    }
    return true
  }

  static async stage(inventoryId) {
    const inventory = await Inventory.getRow({ inventoryId })
    const statusId = Inventory.statusStaged
    const ok = await Inventory.updateRow(inventory, { statusId })
    return ok
  }

  // dump total displayOnHand from various displays to displayOnHand field
  //. batch these updates into one request - might be faster
  static async review(inventoryId) {
    const progress = new Progress(
      'Review Inventory',
      'Transferring display counts'
    )
    const rows = await InventoryItem.getRows({ inventoryId })
    console.log(rows)
    let i = 1
    for (const row of rows) {
      progress.setStatus(i + ' of ' + rows.length + ': ' + row.code)
      // const changes = { displayOnHand: row.displayOnHand }
      const changes = { displayOnHand: row.displayCasesTotal }
      console.log(row, changes)
      await InventoryItem.updateRow(row, changes)
      i += 1
    }
    //.
    // await InventoryItem.updateRows(rows)
    progress.close()
  }

  static async finalize(inventoryId, auditors) {
    const row = await Inventory.getRow({ inventoryId })
    const changes = {
      endTimestamp: new Date(),
      statusId: Inventory.statusCompleted,
      auditor1Id: auditors[0] && auditors[0].id,
      auditor2Id: auditors[1] && auditors[1].id,
    }
    const ok = await Inventory.updateRow(row, changes)
    return ok
  }

  // // import inventory value report (ivr) file.
  // // called from Dashboard/InventoryCount with contents of csv-ish file.
  // static async importInventoryCounts(s, user) {
  //   await importInventory(s, user)
  // }
}


//. better way?
function flattenRow(row) {
  row.storeName = row.store.name
  row.categoryName = row.category === null ? 'No Category' : row.category.name
  // note: id not Id
  row.auditor1Name = row.entityByAuditor1id && row.entityByAuditor1id.name
  row.auditor2Name = row.entityByAuditor2id && row.entityByAuditor2id.name
}

// this is used eg for downloading the inventory discrepancy report
function flattenInventories(inventories) {
  let newItems = []

  inventories.forEach(inventory => {
    const {
      entity,
      location,
      startTimestamp,
      endTimestamp,
      inventoryItems,
    } = inventory

    inventoryItems.forEach(inventoryItem => {
      // const { price, cost, displayOnHand, trayOnHand, externalOnHand, item } = inventoryItem
      const { trayOnHand, displayOnHand, externalOnHand, item } = inventoryItem
      const storeOnHand = displayOnHand + trayOnHand
      const countDiscrepancyFixed = storeOnHand - externalOnHand
      // const retailPrice = price || item.price
      // const wholesaleCost = cost || item.cost
      const retailPrice = item.price
      const wholesaleCost = item.cost

      // if (countDiscrepancyFixed !== 0) {
      newItems.push({
        Store: entity.name,
        Category: location.name,
        StartDate: lib.getDateTime(startTimestamp),
        EndDate: lib.getDateTime(endTimestamp),
        Style: item.code,
        Name: item.name,

        TrayCount: Number(trayOnHand),
        DisplayCount: Number(displayOnHand),
        StoreCount: Number(storeOnHand),
        ExpectedCount: Number(externalOnHand),
        CountDiscrepancy: Number(countDiscrepancyFixed),

        StoreCost: Number(storeOnHand * wholesaleCost),
        ExpectedCost: Number(externalOnHand * wholesaleCost),
        CostDiscrepancy: Number(wholesaleCost * countDiscrepancyFixed),

        StorePrice: Number(storeOnHand * retailPrice),
        ExpectedPrice: Number(externalOnHand * retailPrice),
        PriceDiscrepancy: Number(retailPrice * countDiscrepancyFixed),

        UnitCost: Number(wholesaleCost),
        UnitPrice: Number(retailPrice),
      })
      // }
    })
  })

  return newItems
}
