// show a dialog with a list of choices

import React from 'react'
import { openDialog, closeDialog } from '../react-async-dialog'
import './styles.scss'

// options is a list of [{ value, label }, ...]
// returns with { ok, value }, where ok is true if user hit ok.
export default async function getChoice(title, message, options, defaultValue) {
  return new Promise(resolve => {
    openDialog(GetChoice, { title, message, options, defaultValue, resolve })
  })
}


function GetChoice({ title, message, options, defaultValue, resolve }) {
  const [value, setValue] = React.useState(String(defaultValue))
  function clickOption(event) {
    const optionValue = event.currentTarget.id
    const value = optionValue.split('::')[1] //?
    setValue(value)
  }
  function clickOK() {
    closeDialog()
    resolve({ ok: true, value })
  }
  function clickCancel() {
    closeDialog()
    resolve({ ok: false })
  }
  function handleKeyPress(evt) {
    if (evt.key === "Enter") {
      clickOK()
    }
  }
  return (
    <div className='getChoice' onKeyPress={handleKeyPress}>
      <h3>{title}</h3>
      <p>{message}</p>
      {options.map(option => (
        <div 
          onClick={clickOption} 
          key={option.label} 
          id={"getOption::" + option.value} 
          className={"choice" + (String(option.value)===value ? " selected" : "")}>
          {option.label}
        </div>
      ))}
      <div className="buttons">
        <button id="cancel" onClick={clickCancel}>Cancel</button>
        <button id="ok" onClick={clickOK}>OK</button>
      </div>
    </div>
  )
}
