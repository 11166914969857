import { gql } from "apollo-boost"
import EntityParent from '../entityParent'
import apolloClient from '../../services/apolloClient'


export default class Entity {

  // enums
  static entityTypePerson = 1
  static entityTypeStore = 2
  static entityTypeTenant = 3
  static entityTypeCorporation = 4

  static corporationPandora = 1
  static companySandraHolding = 2
  // static storeTestStore = 4
  // static personTestStorePerson = 5
  // static personTestCompanyAdmin = 8
  // static personPandoraAdmin = 9
  // static personSandraHoldingAdmin = 11
  static storeRosemont = 11

  static async getRows({ id, number, entityTypeId, isActive, parentIds, email }, cached=false) {
    const query = gql`
      query GetEntities($id: Int, $email: String $number: String, $entityTypeId: Int, $isActive: Boolean, $parentIds: [Int!]) {
        __typename
        entity(where: {
          id: {_eq: $id}, 
          email: {_eq: $email}, 
          number: {_eq: $number}, 
          entityTypeId: {_eq: $entityTypeId}, 
          isActive: {_eq: $isActive}, 
          entityParents: {parentId: {_in: $parentIds}}
        }, order_by: {name: asc}) {
          id
          name
          number
          email
          isActive
          isStoreAdmin
          isCompanyAdmin
          isCorporateAdmin
          isEmployee
          entityTypeId
          entityParents {
            entityByParentid { id, name, entityTypeId }
          }
          numAuditors
          phone
          address
        }
      }
    `
    const variables = { id, number, entityTypeId, isActive, parentIds, email }
    const fetchPolicy = cached ? "cache-first" : "network-only"
    console.log('getEntities', variables)
    const { data } = await apolloClient.query({ query, variables, fetchPolicy })
    const rows = data.entity // entity is the table name
    rows.forEach(row => flattenRow(row))
    return rows
  }

  static async getRow({ id, number, entityTypeId, email }, cached=false) {
    const rows = await Entity.getRows({ id, number, entityTypeId, email }, cached)
    console.log(rows)
    if (rows && rows.length === 1) {
      const entity = rows[0]
      return entity
    }
    return null
  }

  // get store ids associated with the given entity.
  // a person could be associated with a store or two, or a company, or just pandora -
  // this method drills down to get list of all stores person can have access to. 
  // this info is used in various searches to restrict the info a person can see.
  //. what if included all 2800 stores though? wont scale. need better approach.
  static async getStoreIds({ id }, cached=false) {
    const entity = await Entity.getRow({ id }, cached)
    const storeIds = []
    // could be stores, or companies, or corporations - 
    // want to drill down until reach stores
    console.log(entity.parents)
    for (const parent of entity.parents) {
      if (parent.entityTypeId === Entity.entityTypeStore) {
        storeIds.push(parent.id)
      } else if (parent.entityTypeId === Entity.entityTypeTenant) {
        // want all stores assoc with company
        // need to query entityParent table
        const rows = await EntityParent.getRows({ parentId: parent.id }, cached)
        for (const row of rows) {
          if (row.entity.entityTypeId === Entity.entityTypeStore) {
            storeIds.push(row.entityId)
          }
        }
        // or all companies assoc with corporation
      } else if (parent.entityTypeId === Entity.entityTypeCorporation) {
        const companies = await EntityParent.getRows({ parentId: parent.id }, cached)
        for (const company of companies) {
          const rows = await EntityParent.getRows({ parentId: company.entityId }, cached)
          for (const row of rows) {
            if (row.entity.entityTypeId === Entity.entityTypeStore) {
              storeIds.push(row.entityId)
            }
          }
        }
      }
    }
    return storeIds
  }

  // get stores (complete records) associated with the given entity
  static async getStores({ id, isActive }, cached=false) {
    const storeIds = await Entity.getStoreIds({ id }, cached)
    let stores = await Entity.getRowsByArray({ idArray: storeIds, isActive }, cached)
    stores = stores.filter(store => store.isActive)
    return stores
  }

  // get complete records for the given entityIds
  static async getRowsByArray({ idArray, isActive }, cached=false) {
    const query = gql`
      query GetEntitiesByArray($idArray: [Int!], $isActive: Boolean) {
        __typename
        entity(where: {
          id: {_in: $idArray}, 
          isActive: {_eq: $isActive}
        }, order_by: {name: asc}) {
          id
          name
          number
          email
          isActive
          entityTypeId
          address
          phone
        }
      }
    `
    const fetchPolicy = cached ? "cache-first" : "network-only"
    const variables = { idArray, isActive }
    const { data } = await apolloClient.query({ query, variables, fetchPolicy })
    const rows = data.entity // entity is the table name
    // rows.forEach(row => flattenRow(row))
    return rows
  }

  // get company ids associated with the given entity
  static async getCompanyIds({ id }) {
    const entity = await Entity.getRow({ id })
    const companyIds = new Set()
    for (const parent of entity.parents) {
      if (parent.entityTypeId === Entity.entityTypeTenant) {
        companyIds.add(parent.id)
      } else if (parent.entityTypeId === Entity.entityTypeStore) {
        const rows = await EntityParent.getRows({ entityId: parent.id })
        for (const row of rows) {
          if (row.entityByParentid.entityTypeId === Entity.entityTypeTenant) {
            companyIds.add(row.parentId)
          }
        }
      } else if (parent.entityTypeId === Entity.entityTypeCorporation) {
        const rows = await EntityParent.getRows({ parentId: parent.id })
        for (const row of rows) {
          if (row.entity.entityTypeId === Entity.entityTypeTenant) {
            companyIds.add(row.entityId)
          }
        }
      }
    }
    return [...companyIds]
  }

  static async getUser({ email }) {
    const user = await Entity.getRow({ email, entityTypeId: Entity.entityTypePerson })
    return user
  }

  // add extra info to user object
  static async addExtraUserInfo(user) {
    // get ids
    user.storeIds = await Entity.getStoreIds({ id: user.id })
    user.companyIds = await Entity.getCompanyIds({ id: user.id })
    user.corporateIds = [Entity.corporationPandora] //. for now
    // get objects
    user.stores = await Entity.getRowsByArray({ idArray: user.storeIds })
    user.stores = user.stores.filter(store => store.isActive)
    user.companies = await Entity.getRowsByArray({ idArray: user.companyIds })
    user.corporations = await Entity.getRowsByArray({ idArray: user.corporateIds })
    user.sources = [].concat(user.corporations).concat(user.companies).concat(user.stores)
  }

  static async updateRow(data, changes) {
    // merge existing and new values
    const variables = { ...data, ...changes }
    // remove non-db values (cause graphql error) //.. better way?
    delete variables.__typename
    delete variables.entityParents
    delete variables.parents
    delete variables.parentNames
    delete variables.parentIds
    delete variables.password
    delete variables.corporateIds
    delete variables.corporations
    delete variables.companyIds
    delete variables.companies
    delete variables.storeIds
    delete variables.sources
    delete variables.phone
    delete variables.address
    const action = "update_entity"
    try {
      const mutation = gql`
        mutation UpdateEntity($id: Int!, $name: String, $number: String, $email: String, 
          $isActive: Boolean, $entityTypeId: Int, 
          $isEmployee: Boolean, $isStoreAdmin: Boolean, $isCompanyAdmin: Boolean,
          $isCorporateAdmin: Boolean,
          $numAuditors: Int
        ) {
          __typename
          ${action}(_set: {name: $name, number: $number, email: $email, 
            isActive: $isActive, isEmployee: $isEmployee, 
            isStoreAdmin: $isStoreAdmin, isCompanyAdmin: $isCompanyAdmin,
            isCorporateAdmin: $isCorporateAdmin,
            numAuditors: $numAuditors
          }, where: {id: {_eq: $id}}) 
            { returning { id, name, number, email, isActive, entityTypeId, isEmployee, 
              isStoreAdmin, isCompanyAdmin, isCorporateAdmin, numAuditors } }
        }
      `
      console.log(action, variables)
      const ret = await apolloClient.mutate({ mutation, variables })
      return ret && ret.data && ret.data[action]
    } catch (e) {
      alert(action + ' ' + e.message)
    }
  }


  // data is { name, number, email, entityTypeId, isActive etc, parentIds }
  static async addRow(data) {
    const defaults = {
      isActive: true,
      isStoreAdmin: false,
      isCompanyAdmin: false,
      isCorporateAdmin: false,
      isEmployee: false,
    }
    const variables = { ...defaults, ...data }
    const { parentIds } = data
    delete variables.parentIds
    try {
      const mutation = gql`
        mutation insert_entity($name: String!, $number: String, $email: String, 
          $entityTypeId: Int!, $isActive: Boolean, $isStoreAdmin: Boolean, 
          $isCompanyAdmin: Boolean, $isCorporateAdmin: Boolean, $isEmployee: Boolean) {
            __typename
            insert_entity(objects: { name: $name, number: $number, email: $email, 
              entityTypeId: $entityTypeId, isActive: $isActive, isStoreAdmin: $isStoreAdmin, 
              isCompanyAdmin: $isCompanyAdmin, isCorporateAdmin: $isCorporateAdmin,
              isEmployee: $isEmployee }) {
            returning { id, name, number, email, isActive, entityTypeId, isEmployee, 
              isStoreAdmin, isCompanyAdmin, isCorporateAdmin, numAuditors }
          }
        }
      `
      console.log('insert_entity', variables)
      const ret = await apolloClient.mutate({ mutation, variables })
      if (ret) {
        const entityId = ret.data.insert_entity.returning[0].id
        // add entityParent records as needed
        for (const parentId of parentIds) {
          await EntityParent.addRow({ entityId, parentId })
        }
        // fetch parent rows and stick on here
        const parents = await Entity.getRowsByArray({ idArray: parentIds })
        const parentNames = parents.map(parent => parent.name).join(", ")
        const row = { ...variables, id: entityId, parents, parentNames }
        return row
      }
    } catch (e) {
      alert(e.message)
    }
  }

  static async deleteRow({ id }) {
    // delete related records first
    await EntityParent.deleteRows({ entityId: id })
    // now the main record
    const action = "delete_entity"
    const mutation = gql`
      mutation DeleteRow($id: Int!) {
        __typename
        ${action}(where: {id: {_eq: $id}}) {
          #affected_rows
          returning { id }
        }
      }
    `
    const variables = { id }
    const ret = await apolloClient.mutate({ mutation, variables })
    // return ret && ret.data && ret.data[action] && (ret.data[action].affected_rows > 0)
    return ret && ret.data && ret.data[action]
  }


  static async countRows(variables) {
    const rows = await Entity.getRows(variables)
    return rows.length
  }

}


//. better way?
function flattenRow(row) {
  // note Parentid not ParentId
  //. this just gets one parent - need to get an array
  // maybe an array like [{id:3,name:"Foo"},...]
  // row.parentName = row.entityParents[0] && row.entityParents[0].entityByParentid && row.entityParents[0].entityByParentid.name
  row.parents = row.entityParents.map(p => p.entityByParentid)
  row.parentNames = row.parents.map(parent => parent.name).join(", ")
}
