import { gql } from 'apollo-boost'
import apolloClient from '../../services/apolloClient'

export default class Value {
  static async getRows({ key }, cached = false) {
    const variables = { key }
    const table = 'value'
    const query = gql`
      query GetValues($key: String) {
        ${table}(where: {key: {_eq: $key}}) {
          __typename
          key
          value
        }
      }
    `
    const fetchPolicy = cached ? 'cache-first' : 'network-only'
    const { data } = await apolloClient.query({ query, variables, fetchPolicy })
    const rows = data[table]
    console.log({ rows })
    return rows
  }

  static async getRow(variables) {
    const rows = await Value.getRows(variables)
    if (rows && rows.length === 1) {
      const value = rows[0].value
      return value
    }
    return null
  }

  static async updateRow(data, changes = {}) {
    // merge existing and new values
    const merged = { ...data, ...changes }
    const { key, value } = merged
    const variables = { key, value }
    try {
      const mutation = gql`
        mutation UpdateValue($key: String!, $value: String) {
          update_value(_set: { value: $value }, where: { key: { _eq: $key } }) {
            __typename
            affected_rows
            returning {
              __typename
              key
              value
            }
          }
        }
      `
      console.log('update_value', variables)
      const ret = await apolloClient.mutate({ mutation, variables })
      console.log(ret)
      return ret
    } catch (e) {
      // alert(e.message)
      console.log(e.message)
    }
    return null
  }

  static async addRow(data) {
    const { key, value } = data
    const variables = { key, value }
    try {
      const mutation = gql`
        mutation AddValue($key: String!, $value: String) {
          insert_value(objects: { key: $key, value: $value }) {
            __typename
            returning {
              __typename
              key
              value
            }
          }
        }
      `
      const ret = await apolloClient.mutate({ mutation, variables })
      console.log(ret)
      return ret
    } catch (e) {
      // alert(e.message)
      console.log(e.message)
    }
    return null
  }

  static async upsertRow({ key, value }) {
    const ret = await Value.addRow({ key, value })
    if (!ret) {
      const ret2 = await Value.updateRow({ key, value })
      return ret2
    }
    return ret
  }

  static async deleteRow({ key }) {
    try {
      const action = 'delete_value'
      const mutation = gql`
        mutation DeleteRow($key: String!) {
          ${action}(where: {key: {_eq: $key}}) {
            __typename
            returning {
              __typename
              key
              value
            }
          }
        }
      `
      const variables = { key }
      const ret = await apolloClient.mutate({ mutation, variables })
      return ret
    } catch (e) {
      alert(e.message)
    }
  }
}
