import getObject from '../../packages/getObject'

/**
 * bring up dialog to edit a commission structure
 */
export default async function getStructure(title, structure) {
  const fieldTypes = {
    description: { type: 'text' },
    metric: { type: 'string' },
    goal: { type: 'number' },
    commission: { type: 'number' },
    base: { type: 'string' },
  }
  const instructions = `Enter values for commission structure:`
  const ret = await getObject(title, instructions, structure, fieldTypes)
  return ret // with { ok, obj }
}
