import React from 'react'
import Entity from '../../models/entity'
import FileReader from '../../packages/file-reader'
import alert from '../../packages/alert'
import defaultStructures from './structures'
import * as io from './io'
import * as logic from './logic'
import Value from '../../models/value'
import getStructure from './getStructure'
import './styles.scss'

export default function () {
  const [structures, setStructures] = React.useState({})

  const companyId = 330 //. just metzger for now
  const keyPrefix = `companyId=${companyId}/commissions/`

  React.useEffect(() => {
    async function fetchData() {
      //. get companyId from user, then keyPrefix
      //. will want to fetch all keyvalues with key ilike '330/commissions/%',
      // parse out the % as the key, etc.
      const salesKey = keyPrefix + 'sales'
      const cardsKey = keyPrefix + 'cards'
      const sales =
        JSON.parse((await Value.getRow({ key: salesKey })) || 'null') ||
        defaultStructures.sales
      const cards =
        JSON.parse((await Value.getRow({ key: cardsKey })) || 'null') ||
        defaultStructures.cards
      setStructures({ sales, cards })
    }
    fetchData()
  }, [])

  const [ees, setEes] = React.useState(null)
  const [sales, setSales] = React.useState(null)
  const [cards, setCards] = React.useState(null)
  const [enableCalculate, setEnableCalculate] = React.useState(false)

  function parseEmployees(contents) {
    const ees = io.readEmployees(contents)
    setEes(ees)
    setEnableCalculate(ees && sales && cards)
  }

  function parseSales(contents) {
    console.log({ contents })
    const sales = io.readSales(contents)
    setSales(sales)
    setEnableCalculate(ees && sales && cards)
  }

  function parseCards(contents) {
    const cards = io.readCards(contents)
    setCards(cards)
    setEnableCalculate(ees && sales && cards)
  }

  async function editStructure(event) {
    const key = event.target.id
    const value = structures[key]
    const ret = await getStructure('Edit ' + key, value) // bring up dialog
    if (ret.ok) {
      const newValue = ret.obj
      // update database
      const tableKey = keyPrefix + key // eg '330/commissions/sales'
      const ret2 = await Value.upsertRow({
        key: tableKey,
        value: JSON.stringify(newValue),
      })
      // update view
      if (ret2) {
        setStructures(structures => ({ ...structures, [key]: newValue }))
      }
    }
  }

  async function calculate() {
    console.log({ ees, sales, cards })

    // initialize dicts
    const eedict = {}
    const storedict = {}
    logic.initialize(ees, eedict, storedict)
    console.log({ eedict, storedict })

    // do calculations
    logic.calcSales(sales, structures.sales, eedict, storedict)
    logic.calcCards(cards, structures.cards, eedict)
    logic.calcCommissions(storedict, eedict)

    // get dict of stores for names
    const storeList = await Entity.getRows({
      entityTypeId: Entity.entityTypeStore,
    })
    const stores = {}
    for (const store of storeList) {
      stores[store.number] = store
    }

    // save results
    io.writeCommissions(storedict, stores, alert)
    io.writeDetails(structures, eedict, alert)
  }

  return (
    <div className="commissions">
      <div className="page">
        <div className="title">Commissions</div>
        <hr />

        <div className="input">
          <Structures structures={structures} editStructure={editStructure} />
        </div>

        <div className="input">
          <div className="title">Employees</div>
          <div>(Employee Info HHM Clock Seq xls)</div>
          <FileReader parser={parseEmployees} filetypes=".xls" binary />
        </div>

        <div className="input">
          <div className="title">Sales</div>
          <div>(Transaction Analysis csv)</div>
          <FileReader parser={parseSales} filetypes=".csv" />
        </div>

        <div className="input">
          <div className="title">Credit Cards</div>
          <div>(Application Summary Report xlsx)</div>
          <FileReader parser={parseCards} filetypes=".xlsx" binary />
        </div>

        <div className="calculate">
          <button onClick={calculate} disabled={!enableCalculate}>
            Calculate and Download...
          </button>
        </div>
      </div>
    </div>
  )
}

// show a structure
function Structures({ structures, editStructure }) {
  return (
    <div className="structure">
      <table>
        <thead>
          <tr>
            <th>Description</th>
            <th>Metric</th>
            <th>Goal</th>
            <th>Commission</th>
            <th>Base</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(structures).map(([key, structure]) => {
            return (
              <tr key={key}>
                <td>{structure.description}</td>
                <td>{structure.metric}</td>
                <td>{structure.goal}</td>
                <td>{structure.commission}</td>
                <td>{structure.base}</td>
                <td>
                  <button onClick={editStructure} id={key}>
                    Edit
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
