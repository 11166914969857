// getEmployee
// wrapper around the getObject dialog, a generic dialog for editing objects.
// used to create and edit employees.

import getObject from '../../packages/getObject'
import alert from '../../packages/alert'
import Entity from '../../models/entity'

export default async function getEmployee(
  title,
  instructions,
  employee,
  user,
  showEmail
) {
  let parentOptions = []
  parentOptions = parentOptions.concat(user.stores)
  parentOptions = parentOptions.concat(user.companies)
  parentOptions = parentOptions.map(option => ({
    label: option.name,
    value: option.id,
  }))
  console.log(parentOptions)

  const fieldTypes = {
    stores: {
      name: 'Stores/Company',
      type: 'multipleChoice',
      options: parentOptions,
      validate: async selected => {
        if (selected && selected.length > 0) return true
        await alert('Missing stores/company value')
        return false
      },
    },
    name: {
      name: 'Name (Last, First)',
      // coerce: value => value.trim(), // can't do this because prevents adding space in middle of name
      validate: async value => {
        if (value && value.length > 0) return true
        await alert('Missing name')
        return false
      },
    },
    number: {
      name: 'Employee Number',
      validate: async (number, modified) => {
        if (!modified) return true // don't check for duplicates if didn't change
        if (!number || number === '') return true // allow duplicate blanks
        // if (await Entity.getRow({ number })) {
        // limit check to person records, as ee numbers can collide with store numbers
        if (await Entity.getRow({ number, entityTypeId: 1 })) {
          await alert(`A user with the number ${number} already exists.`)
          return false
        }
        return true
      },
    },
    email: {
      coerce: value => value.trim().toLowerCase(),
      validate: async email => {
        if (!email || email === '') {
          await alert('Missing email')
          return false
        }
        if (await Entity.getRow({ email })) {
          await alert(`A user with the email ${email} already exists.`)
          return false
        }
        return true
      },
    },
    isActive: { name: 'Active', type: 'boolean' },
    isEmployee: { name: 'Employee', type: 'boolean' },
    isStoreAdmin: { name: 'Store Admin', type: 'boolean' },
    isCompanyAdmin: { name: 'Company Admin', type: 'boolean' },
    isCorporateAdmin: {
      name: 'Corporate Admin',
      type: 'boolean',
      readonly: !user.canModifyCorporateAdminFlag,
    },
  }

  const obj = {
    stores:
      employee.parents &&
      employee.parents.map(parent => ({
        label: parent.name,
        value: parent.id,
      })),
    name: employee.name,
    number: employee.number,
    isActive: employee.isActive,
    isEmployee: employee.isEmployee,
    isStoreAdmin: employee.isStoreAdmin,
    isCompanyAdmin: employee.isCompanyAdmin,
    isCorporateAdmin: employee.isCorporateAdmin,
  }
  if (showEmail) {
    obj.email = employee.email
  }
  console.log(employee)
  console.log(obj)

  const ret = await getObject(title, instructions, obj, fieldTypes) // bring up dialog

  if (ret.ok) {
    ret.employee = { ...employee, ...ret.obj } // merges in id and original email if needed
    ret.employee.parentIds = ret.obj.stores.map(store => store.value)
    delete ret.employee.stores
    ret.employee.entityTypeId = Entity.entityTypePerson
  }
  return ret
}
