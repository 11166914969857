import React from 'react'
import { observer } from 'mobx-react-lite'
import Menu, { SubMenu, MenuItem, Divider } from 'rc-menu' // https://github.com/react-component/menu
import 'rc-menu/assets/index.css'
import { useUser } from '../../services/user'
import './styles.scss'

export default observer(function ({ history }) {
  // get logged in user, if any
  const [user] = useUser() // don't pass history - so won't redirect

  const userName =
    (user && user.name.split(', ').reverse().join(' ')) || '(not logged in)'
  const userStores =
    user && user.parents && user.parents.map(parent => parent.name).join(', ')

  const displayCountUrl = '/count/stores'

  // make the menu component managed, so can close on click
  // see https://github.com/react-component/menu/issues/89
  const [activeKeys, setActiveKeys] = React.useState(null)

  // handle menu open/close
  function onOpenChange(openKeys) {
    setActiveKeys(openKeys)
  }

  // we use key to store the new url
  function handleClick({ key }) {
    setActiveKeys([]) // close menu
    history.push(key)
  }

  return (
    <span className="menu">
      <Menu
        mode="horizontal"
        openAnimation="slide-up"
        onClick={handleClick}
        onOpenChange={onOpenChange}
        openKeys={activeKeys}
        // openAnimation="none"
        // subMenuCloseDelay={0.001}
        // selectedKeys={['foo']} // get from url
        // triggerSubMenuAction={triggerSubMenuAction}
        // defaultOpenKeys={this.props.defaultOpenKeys}
        // overflowedIndicator={overflowedIndicator}
        // motion={this.props.motion}
      >
        <SubMenu title="" key="submenu">
          <MenuItem key="/account">
            <div className="menu-person">{userName}</div>
            <div className="menu-stores">{userStores}</div>
          </MenuItem>
          {user && <Divider />}
          {user && <MenuItem key="/account?">Account Info</MenuItem>}
          {user && <Divider />}
          {user && (
            <MenuItem key={displayCountUrl}>
              Display Count / Maintenance
            </MenuItem>
          )}
          {user && <MenuItem key="/">Inventory Count</MenuItem>}
          {user && user.isAdmin && <Divider />}
          {user && user.canMaintainInventories && (
            <MenuItem key="/maintain-inventories">
              Inventory Maintenance
            </MenuItem>
          )}
          {user && user.canViewDiscrepancies && (
            <MenuItem key="/inventory/discrepancies">
              Inventory Discrepancies
            </MenuItem>
          )}
          {user && user.canViewCategories && (
            <MenuItem key="/maintain-categories">Category Maintenance</MenuItem>
          )}
          {user && user.canViewItems && (
            <MenuItem key="/maintain-items">Item Maintenance</MenuItem>
          )}
          {user && user.canViewEmployees && (
            <MenuItem key="/maintain-employees">Employee Maintenance</MenuItem>
          )}
          {user && user.canViewStores && (
            <MenuItem key="/maintain-stores">Store Maintenance</MenuItem>
          )}
          {user && user.canManageCommissions && (
            <MenuItem key="/commissions">Commissions</MenuItem>
          )}
          {/* <MenuItem key="/maintain-tray-types">Tray Type Maintenance</MenuItem> */}
          {user && <Divider />}
          <MenuItem key="/about">About</MenuItem>
          <MenuItem key="/feedback">Feedback</MenuItem>
          {user && <Divider />}
          {user && <MenuItem key="/logout">Logout</MenuItem>}
        </SubMenu>
      </Menu>
    </span>
  )
})
