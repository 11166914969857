// a semi-generic card container
// user creates a CardComponent and passes that in here

// extending from original cards component to remove Header and Pagination
//. get rid of that version eventually


import React from 'react'
import Pressable from '../pressable2'
import './styles.scss'


export default ({ 
  cards, 
  CardComponent,
  clickCard, 
  pressCard, 
  highlightedCardId,
  highlightedCardIds = {}, // a dict or Map of ids - will supercede highlightedCardId
  handlers = {}, // handlers get passed to child cards //. incl click and press events
  noResultMessage = "No results",
  children, // optional child components, e.g. buttons
}) => {

  // const [timedOut, setTimedOut] = useState(false) //. implement this

  const loading = (cards === null)
  const noResults = !loading && cards && (cards.length === 0)
  // const showDescription = !loading && !noResults

  return (
    <>
      <div className="cards">
        {cards && cards.length > 0 && <div className="cards-list">
          {cards.map((card, index) => (
            <Pressable
              className="card-container"
              clickCard={clickCard}
              pressCard={pressCard}
              key={card.id}
              id={card.id}
              index={index}
            >
              <CardComponent
                card={card}
                highlighted={highlightedCardId === card.id || highlightedCardIds[card.id] || (highlightedCardIds.has && highlightedCardIds.has(card.id))}
                handlers={handlers}
                id={card.id}
              />
            </Pressable>
          ))}
        </div>}
        {children}
      </div>

      {loading && <div className="cards-message">Loading...</div>}
      {noResults && <div className="cards-message">{noResultMessage}</div>}
      {/* {timedOut && <div className="cards-message">Network problems?</div>} */}
    </>
  )
}
