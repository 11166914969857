import React from 'react'
import alert from '../alert'
import './styles.css'


export default function({ msg }) {
  async function clickButton(event) {
    event.preventDefault()
    await alert(msg)
  }
  return (
    <button className="Help" onClick={clickButton}>?</button>
  )
}
