import React from 'react'
import { useGlobals } from '../../globals'
import { withFirebase } from '../../services/Firebase'

export default withFirebase(function({ history, firebase }) {
  const globals = useGlobals()
  React.useEffect(() => {
    globals.user = null
    localStorage.removeItem('user')
    localStorage.removeItem('auditors')
    firebase.logout()
    history.push('/login')
  }, [globals.user, history])
  return null
})