// see models/entity.js addExtraUserInfo for info in the user object

import { useGlobals } from '../globals'
import Entity from '../models/entity'

// get user from globals or localstorage -
// if none in either place redirect to login page.
// usage:
//   import { useUser } from '../../services/user'
// in component:
//   const [user, updateUser] = useUser(history)
//   if (!user) return null
// note: updateUser is async
export function useUser(history) {

  const globals = useGlobals()

  // // get user from globals
  // if (globals.user) {
  //   return [globals.user, updateUser]
  // }
  // // get user from localstorage
  // const user = JSON.parse(localStorage.getItem('user') || "null")
  // if (user) {
  //   return [user, updateUser]
  // } 
  // // no user available - redirect to login page
  // if (history) history.push('/login')
  // return [null, updateUser]

  let user = null
  if (globals.user) {
    user = globals.user
  } else {
    user = JSON.parse(localStorage.getItem('user') || "null")
  }
  //. force re-login based on app date
  if (user && user.lastLogin && user.lastLogin > "2020-05-03") {
    return [user, updateUser]
  }

  // no user available or is stale - redirect to login page
  if (history) history.push('/login')
  return [null, updateUser]  


  // fn to update the user object stored in globals and localStorage by
  // adding relevant info - available companies, stores, permissions
  async function updateUser(user) {
    console.log('updateUser')
    // const user = { ...obj } // make copy so we don't modify the original object?
    await Entity.addExtraUserInfo(user) // add info to user object

    // general permissions
    user.isAdmin = user.isStoreAdmin || user.isCompanyAdmin || user.isCorporateAdmin
    user.canMaintainInventories = user.isAdmin
    user.canViewDiscrepancies = user.isAdmin
    user.canViewCategories = user.isAdmin
    user.canViewItems = user.isAdmin
    user.canViewEmployees = user.isAdmin
    user.canViewStores = user.isAdmin
    user.canEditCategories = user.isCompanyAdmin || user.isCorporateAdmin
    user.canEditItems = user.isCompanyAdmin || user.isCorporateAdmin
    user.canEditEmployees = user.isCompanyAdmin || user.isCorporateAdmin
    user.canEditStores = user.isCorporateAdmin
    user.canManageCommissions = user.isCompanyAdmin

    // fine-grained permissions
    user.canCloneCategoriesAndItems = user.isCompanyAdmin || user.isCorporateAdmin
    user.canEditCorporateCategories = user.isCorporateAdmin
    user.canMoveCategories = user.isCompanyAdmin || user.isCorporateAdmin
    user.canModifyCorporateAdminFlag = user.isCorporateAdmin
    user.canImportIVR = user.isAdmin
    user.canStageInventories = user.isAdmin
    user.canViewUnstagedInventories = user.isAdmin
    user.canCountStagedInventories = user.isEmployee
    user.canCloseInventory = user.isAdmin
    user.canClearUnstagedInventories = user.isAdmin
    user.canDeleteCompletedInventories = user.isCompanyAdmin || user.isCorporateAdmin
    user.canUpdateDisplayLog = user.isAdmin
    user.canMaintainDisplays = user.isAdmin
    user.canImportPlanograms = user.isCorporateAdmin

    globals.user = user // update global object
    localStorage.setItem('user', JSON.stringify(user)) // update localStorage
    console.log('new value', user)
  }
}
