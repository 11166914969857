// show a set of stores as cards

import React from 'react'
import Cards from '../../packages/cards'
import Entity from '../../models/entity'
import { useGlobals } from '../../globals'
import { useUser } from '../../services/user'
import Infobox from '../../packages/Infobox'
import { downloadStores } from '../../services/downloads'
import './styles.scss'


//. rework this as a display of stores for a companyId
// then have a ShowCompanies page above it

// const entityTypeId = Entity.entityTypeStore


export default function ShowStores({ match, history }) {

  // // get id from url
  // const companyId = Number(match.params.companyId)

  const [cards, setCards] = React.useState(null)

  const globals = useGlobals()

  const [user] = useUser(history)

  //. handle errors, timeout etc
  React.useEffect(() => {
    async function fetchStores() {
      // const rows = await Entity.getRows({ entityTypeId, isActive: true })
      const rows = await Entity.getStores({ id: user.id, isActive: true })
      setCards(rows)
      // set breadcrumbs
      // const company = await Entity.getRow({ id: companyId })
      // globals.breadcrumbs = "Display Count - " + company.name
      globals.breadcrumbs = "Display Count - Stores"
    }
    fetchStores()
  // }, [companyId, globals.breadcrumbs])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [companyId]) //. see ShowItems and ShowDisplays for warning explanation
  }, []) //. see ShowItems and ShowDisplays for warning explanation

  function clickCard(event) {
    //. make lib fn
    window.localStorage.removeItem('storeScrollTop') // reset scroll value
    const storeId = event.currentTarget.id
    history.push(`/count/store/${storeId}`)
  }

  function clickDownload() {
    downloadStores(cards)
  }

  if (!user) return null
  return (
    <div className="ShowStores">
      <div className="header">
        <Infobox text="Select a store from the list:" breakpoint={400} />
        <span className="buttons">
          <button onClick={clickDownload}>Download...</button>
        </span>
      </div>
      <Cards
        cards={cards}
        Card={StoreCard} // defined below
        clickCard={clickCard}
      />
    </div>
  )
}


function StoreCard({ card, clickCard, id, index }) {
  // store images are currently deployed with the web app itself - 
  // the file names should be the id and name, with spaces removed, 
  // eg '222TheGreene.jpg'
  // add ?v4 etc to bypass users cache if change images
  const imageUrl = `https://tallieo.web.app/images/stores/${id}${card.name}.jpg?v=4`.replace(/ /g, '')
  return (
    <div className="card StoreCard" onClick={clickCard} id={id}>
      <img src={imageUrl} alt="" onError={e=>{e.target.onerror = null; e.target.src="images/image.png"}} />
      <span className="StoreCard-name">{card.name}</span>
    </div>
  )
}
