import React from 'react'
import './styles.css'

export default function FileReader({
  label = 'Load File...',
  prompt = '(No file selected)',
  showFilename = true,
  binary = false,
  filetypes, // optional, eg ".csv"
  // setFile, // optional callback
  parser, // optional callback
  disabled, // optional
}) {
  const [prompt2, setPrompt2] = React.useState(prompt)

  function changeFile(event) {
    const file = event.target.files[0]
    if (!file) return
    // if (setFile) {
    //   setFile(file)
    // }
    if (showFilename) {
      setPrompt2(file.name)
    }
    if (parser) {
      const reader = new window.FileReader()
      reader.onload = fileReadingFinished
      reader.onerror = errorHandler
      if (binary) {
        reader.readAsArrayBuffer(file)
      } else {
        reader.readAsText(file)
      }
      function fileReadingFinished(event) {
        const s = event.target.result
        parser(s) // pass text to parser callback
      }
      function errorHandler(event) {
        if (event.target.error.name === 'NotReadableError') {
          alert('Cannot read file!')
        }
      }
    }
  }

  return (
    <span className="file-reader">
      <span className="file-reader-name">{prompt2}</span>
      <label className="file-reader-button">
        <input
          type="file"
          accept={filetypes}
          onChange={changeFile}
          disabled={disabled}
        />
        {label}
      </label>
    </span>
  )
}
