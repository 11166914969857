// getObject
// a generic dialog for editing objects
// data types currently handled: choice, multipleChoice, boolean, value.
//
// usage (making a wrapper fn around getObject):
//
// import getObject from '../../packages/getObject'
//
// export default async function getItem(title, obj, user) {
//   const fieldTypes = {
//     sourceId: {
//       name: "Source",
//       type: "choice",
//       options: user.sources.map(source => ({ id: source.id, name: source.name })),
//       coerce: value => Number(value),
//     },
//     name: { validate: value => value && value.trim() > "" },
//     code: { validate: value => value && value.trim() > "" },
//     price: { coerce: value => Number(value) },
//     isActive: { coerce: value => Boolean(value) },
//     image: { type: "image", upload: true },
//   }
//   const ret = await getObject(title, obj, fieldTypes)
//   return ret // with { ok, obj }
// }

import React from 'react'
import Select from 'react-select'
import { openDialog, closeDialog } from '../react-async-dialog'
import './styles.scss'

/**
 * show a dialog with given title, object, and fieldTypes.
 * returns { ok, obj }, with ok true/false, obj the new values.
 * objStart is the initial values for the object
 * fieldTypes is a dictionary of optional fieldType values, like
 *     sourceId: {
 *       name: "Source",
 *       type: "choice",
 *       options: user.sources.map(source => ({ id: source.id, name: source.name })),
 *       coerce: value => Number(value), //. don't do this - can't enter decimals
 *       validate: value => true/false,
 *     }
 */
export default async function getObject(
  title,
  instructions,
  objStart,
  fieldTypes = {},
  readonly = false
) {
  return new Promise(resolve => {
    openDialog(GetObject, {
      title,
      instructions,
      objStart,
      fieldTypes,
      readonly,
      resolve,
    })
  })
}

// this defines the dialog component
function GetObject({
  title,
  instructions,
  objStart,
  fieldTypes,
  readonly,
  resolve,
}) {
  const [obj, setObj] = React.useState(objStart)
  const [modified, setModified] = React.useState({})

  async function clickOK() {
    if (await validateValues()) {
      closeDialog()
      resolve({ ok: true, obj, modified })
    }
  }

  // validate all values
  async function validateValues() {
    for (const field of Object.keys(obj)) {
      const fieldType = fieldTypes[field]
      const validate = fieldType && fieldType.validate
      const value = obj[field]
      if (validate && !(await validate(value, modified[field]))) {
        return false
      }
    }
    return true
  }

  function clickCancel() {
    closeDialog()
    resolve({ ok: false })
  }

  function coerceAndSet(field, value) {
    const coerce = fieldTypes[field] && fieldTypes[field].coerce
    const fieldValue = coerce ? coerce(value) : value
    setObj(obj => ({ ...obj, [field]: fieldValue }))
    setModified(modified => ({ ...modified, [field]: true }))
  }

  function changeValue(event) {
    const field = event.target.id
    const value = event.target.value
    coerceAndSet(field, value)
  }

  function changeOption(event) {
    changeValue(event)
  }

  function changeBoolean(event) {
    const field = event.target.id
    const value = event.target.value === 'true' ? true : false // can't do Boolean('false')!
    coerceAndSet(field, value)
  }

  function changeSelect(field, selected) {
    coerceAndSet(field, selected)
  }

  function handleKeyDown(event) {
    if (event.key === 'Escape') {
      clickCancel()
    }
  }

  function loadImage(event, field, fieldType) {
    const file = event.target.files && event.target.files[0]
    if (file) {
      // show preview
      const img = document.getElementById('getObject-image')
      img.src = window.URL.createObjectURL(file)
      img.onload = function () {
        window.URL.revokeObjectURL(img.src) // free memory
      }
      // return image file data through the fieldType object
      fieldType.file = file
    }
  }

  return (
    <div className="getObject">
      <div className="title">{title}</div>
      <div className="instructions">{instructions}</div>
      <table className="row">
        <tbody>
          {obj &&
            Object.keys(obj).map((field, index) => {
              const fieldType = fieldTypes[field] || {}
              const disabled = readonly || fieldType.readonly
              return (
                <tr key={field}>
                  <td className="field">
                    {fieldType.name ||
                      field.charAt(0).toUpperCase() + field.substr(1)}
                  </td>
                  <td className="value">
                    {fieldType.type === 'choice' ? (
                      <select
                        name={field}
                        id={field}
                        value={obj[field]}
                        disabled={disabled}
                        onChange={changeOption}
                      >
                        {fieldType.options.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    ) : fieldType.type === 'multipleChoice' ? (
                      <Select
                        id={field}
                        onChange={selected => changeSelect(field, selected)}
                        options={fieldType.options}
                        value={obj[field]}
                        disabled={disabled}
                        isMulti={true}
                      />
                    ) : fieldType.type === 'boolean' ? (
                      <select
                        name={field}
                        id={field}
                        value={String(obj[field] || false)}
                        onChange={changeBoolean}
                        disabled={disabled}
                      >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    ) : fieldType.type === 'image' ? (
                      <div>
                        <img src={obj[field]} alt="" id="getObject-image" />
                        {fieldType.upload && (
                          <input
                            type="file"
                            id="inputfile"
                            accept=".jpg"
                            disabled={disabled}
                            onChange={event =>
                              loadImage(event, field, fieldType)
                            }
                          />
                        )}
                      </div>
                    ) : fieldType.type === 'text' ? (
                      <textarea
                        tabIndex="0"
                        autoFocus={index === 0}
                        autoComplete="off"
                        id={field}
                        rows={4}
                        disabled={disabled}
                        value={obj[field] || ''}
                        onKeyDown={handleKeyDown}
                        onChange={changeValue}
                      />
                    ) : (
                      <input
                        tabIndex="0"
                        autoFocus={index === 0}
                        autoComplete="off"
                        type="text"
                        id={field}
                        disabled={disabled}
                        value={obj[field] || ''}
                        onKeyDown={handleKeyDown}
                        onChange={changeValue}
                      />
                    )}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      <div className="buttons">
        <button onClick={clickCancel}>Cancel</button>
        <button onClick={clickOK} disabled={readonly}>
          OK
        </button>
      </div>
    </div>
  )
}
