import { gql } from "apollo-boost"
import apolloClient from '../../services/apolloClient'


export default class EntityParent {
  
  static async getRows({ entityId, parentId }, cached=false) {
    const query = gql`
      query GetEntityParents($entityId: Int, $parentId: Int) {
        __typename
        entityParent(where: {entityId: {_eq: $entityId}, parentId: {_eq: $parentId}}) {
          entityId
          parentId
          entity { entityTypeId }
          entityByParentid { entityTypeId }
        }
      }
    `
    const variables = { entityId, parentId }
    const fetchPolicy = cached ? "cache-first" : "network-only"
    console.log('getentityparents', variables)
    const { data } = await apolloClient.query({ query, variables, fetchPolicy })
    const rows = data.entityParent // entityParent is the table name
    return rows
  }


  static async addRow({ entityId, parentId }) {
    const variables = { entityId, parentId }
    try {
      const mutation = gql`
        mutation insert_entityParents($entityId: Int!, $parentId: Int!) {
          __typename
          insert_entityParent(objects: { entityId: $entityId, parentId: $parentId }) {
            returning {
              entityId, parentId
            }
          }
        }
      `
      console.log('insert_entityParents', variables)
      const ret = await apolloClient.mutate({ mutation, variables })
      return ret
    } catch(e) {
      alert(e.message)
    }
  }

  static async deleteRows({ entityId, parentId }) {
    if (!entityId && !parentId) {
      window.alert("Error: must specify entity or parent records to remove")
      return
    }
    const action = "delete_entityParent"
    const mutation = gql`
      mutation DeleteRow($entityId: Int, $parentId: Int) {
        __typename
        ${action}(where: {entityId: {_eq: $entityId}, parentId: {_eq: $parentId}}) {
          affected_rows
        }
      }
    `
    const variables = { entityId, parentId }
    console.log(action, variables)
    const ret = await apolloClient.mutate({ mutation, variables })
    // return ret && ret.data && ret.data[action] && (ret.data[action].affected_rows > 0)
    return ret
  }

  static async countRows({ entityId, parentId }) {
    const rows = await EntityParent.getRows({ entityId, parentId })
    console.log(rows)
    return rows.length
  }

}
