
//. refactor - currently have a component defined inside another component,
// to pass data between them - not good design


import React from 'react'
import moment from 'moment'
import iconCount from '../../assets/images/iconCount.png'
import iconDollar from '../../assets/images/iconDollar.png'
import iconPricetag from '../../assets/images/iconPricetag.png'
import * as grid from '../../packages/grid'
import * as lib from '../../lib'
import alert from '../../packages/alert'
import confirm from '../../packages/confirm'
import Inventory from '../../models/inventory'
import InventoryItem from '../../models/inventoryItem'
import { useAuditors } from '../../services/auditors'
import { useUser } from '../../services/user'
import Help from '../../packages/Help'
import './styles.scss'


//. put these inside the main fn
let breadcrumbs = '' // set later
let preview = false

const helpMsg = <div>
  <strong>Inventory Preview/Review</strong><br/>
  <br/>
  Store Count = Tray Count + Display Count<br/>
  Expected Count = value expected by POS system<br/>
  Count Discrepancy = Store Count - Expected Count<br/>
  <br/>
  Unit Cost = Wholesale Cost<br/>
  Store Cost = Store Count * Unit Cost<br/>
  Expected Cost = Expected Count * Unit Cost<br/>
  Cost Discrepancy = Store Cost - Expected Cost<br/>
  <br/>
  Unit Price = Retail Price<br/>
  Store Price = Store Count * Unit Price<br/>
  Expected Price = Expected Count * Unit Price<br/>
  Price Discrepancy = Store Price - Expected Price<br/>
  <br/>
  Shrink = - Cost Discrepancy / Expected Cost<br/>
</div>

const columnDefs = [
  // { field: 'itemId', headerName: "id", width: 80, editable: false },
  { field: 'code', headerName: "Style", width: 140, editable: false, pinned: 'left' },
  { field: 'name', headerName: "Description", editable: false, width: 180, pinned: 'left', tooltipValueGetter: params => params.value },
  { field: 'order', headerName: "Order", width: 70, editable: false, hide: true },
  
  { headerName: "Count", children: [
    { field: 'trayOnHand', headerName: "Tray Count", editable: false, width: 100, cellClass: 'center' },
    { field: 'displayOnHand', headerName: "Display Count", editable: false, width: 100, cellClass: 'center' },
    { field: 'total', headerName: "Store Count", valueGetter: getStoreCount, editable: false, width: 90, cellClass: 'center' },
    { field: 'externalOnHand', headerName: "Expected Count", editable: false, width: 110, cellClass: 'center' },
    { field: 'countDiscrepancyFixed', headerName: "Count Discrep", valueGetter: getCountDiscrepancy, editable: false, width: 100, cellClass: getColoredNumber },  
  ]},
  
  { headerName: "Cost", children: [
    { field: 'cost', headerName: "Unit Cost", editable: false, width: 80, valueFormatter: currencyFormatter, cellClass: 'right' },
    { field: 'storeCost', headerName: "Store Cost", valueGetter: getStoreCost, editable: false, width: 80, cellClass: 'right' },
    { field: 'externalCost', headerName: "Expected Cost", valueGetter: getExpectedCost, editable: false, width: 110, cellClass: 'right' },
    { field: 'costDiscrepancy', headerName: "Cost Discrep", valueGetter: getCostDiscrepancy, editable: false, width: 100, cellClass: getColoredDollars },
  ]},
  
  { headerName: "Price", children: [
    { field: 'price', headerName: "Unit Price", editable: false, width: 80, valueFormatter: currencyFormatter, cellClass: 'right' },
    { field: 'storePrice', headerName: "Store Price", valueGetter: getStorePrice, editable: false, width: 80, cellClass: 'right' },
    { field: 'externalPrice', headerName: "Expected Price", valueGetter: getExpectedPrice, editable: false, width: 110, cellClass: 'right' },
    { field: 'priceDiscrepancy', headerName: "Price Discrep", valueGetter: getPriceDiscrepancy, editable: false, width: 100, cellClass: getColoredDollars },
  ]},
  
  { field: 'alert', width: 80, editable: false, cellClass: 'alert-icons', cellRenderer: iconRenderer },
]

function currencyFormatter(params) {
  // return params.value ? lib.getDollarAmount(params.value) : ''
  return params.value ? lib.getDollarAmount(params.value, 2) : ''
}
function getColoredNumber(params) {
  const classes = 'center ' + (params.value===0 ? 'gray' : params.value < 0 ? 'red' : 'green')
  return classes  
}
function getColoredDollars(params) {
  const classes = 'right ' + (params.value==='$0' ? 'gray' : params.value.charAt(0)==='(' ? 'red' : 'green')
  return classes
}

function iconRenderer(params) {
  let renderString = ''
  const count = ` <img src=${iconCount} height="15px" title="Count Discrepancy" />`
  const pricetag = ` <img src=${iconPricetag} height="15px" title="No Price/Cost" />`
  const dollar = ` <img src=${iconDollar} height="15px" title="Price/Cost Discrepancy" />`
  if (params.data.countDiscrepancyFixed !== 0) renderString += count
  if (!params.data.price || !params.data.cost) renderString += pricetag
  if (params.data.priceDiscrepancy || params.data.costDiscrepancy) renderString += dollar
  return renderString
}

// count
function getStoreCount(params) {
  const value = params.data.displayOnHand + params.data.trayOnHand
  return value
}
function getCountDiscrepancy(params) {
  const value = params.data.countDiscrepancyFixed
  return (value < 0) ? value : (value > 0) ? '+' + value : ''
}

// cost
function getStoreCost(params) {  
  const value = params.data.storeCostTotal || (params.data.cost * (params.data.displayOnHand + params.data.trayOnHand)) || 0
  return lib.getDollarAmount(value)
}
function getExpectedCost(params) {
  const value = params.data.externalCostTotal || (params.data.cost * params.data.externalOnHand) || 0
  return lib.getDollarAmount(value)
}
function getCostDiscrepancy(params) {
  const value = params.data.costDiscrepancyTotal || (params.data.cost * params.data.countDiscrepancyFixed) || 0
  return lib.getDollarAmount(value)
}

// price
function getStorePrice(params) {  
  const value = params.data.storePriceTotal || (params.data.price * (params.data.displayOnHand + params.data.trayOnHand)) || 0
  return lib.getDollarAmount(value)
}
function getExpectedPrice(params) {
  const value = params.data.externalPriceTotal || (params.data.price * params.data.externalOnHand) || 0
  return lib.getDollarAmount(value)
}
function getPriceDiscrepancy(params) {
  const value = params.data.priceDiscrepancyTotal || (params.data.price * params.data.countDiscrepancyFixed) || 0
  return lib.getDollarAmount(value)
}


function getFinishedDate(date) {
  return date ? lib.formatDateTime(new Date(date)) : 'Not finished'
}
function getDuration(start, end) {
  if (!start._isValid || !end._isValid) {
    return 'Not finished'
  }
  // return moment.duration(end.diff(start)).humanize() // eg "28 minutes"
  const totalMilliseconds = end.diff(start)
  const minutes = moment.utc(totalMilliseconds).format("m")
  const hours = moment.duration(totalMilliseconds).asHours().toFixed()
  const s = `${hours}h ${minutes}m` // eg "28h 7m"
  return s
}
// function getOrder(params) {
//   const order = params.data.countDiscrepancyFixed < 0 ? params.data.countDiscrepancyFixed*1000 : -params.data.countDiscrepancyFixed
//   return order
// }

const gridOptions = {
  columnDefs,
  headerHeight: 50, // px (default 25)
}



export default function InventoryReview(props) {
  
  const inventoryId = Number(props.match.params.inventoryId)
  const variables = { inventoryId }
  preview = props.preview // set module-level variable to pass to Header

  // fetch inventory info
  const [row, setRow] = React.useState(null) // eslint-disable-line no-unused-vars
  React.useEffect(() => {
    async function fetchRow() {
      const row = await Inventory.getRow(variables)
      breadcrumbs = `Inventory Review - ${row.storeName}: ${row.categoryName}`
      setRow(row)
    }
    fetchRow()
  }, [variables])

  // //. make withRow hoc
  // function HeaderWithRow(props, gridApi, variables) {
  //   return Header({ ...props, row }, gridApi, variables)
  // }
  // function FooterWithRow(props, gridApi, variables, data) {
  //   console.log(props) // not even called - why?
  //   return Footer({ ...props, row }, gridApi, variables, data)
  // }

  function Header({ history }, gridApi, variables, setData, context) {

    const auditors = useAuditors()
    const { inventoryId } = variables
    const urlCorrect = `/inventory/${inventoryId}/count`
    const urlClose = `/`
    const [user] = useUser(history)

    const { row } = context
  
    const showCorrect = preview
    const isCompleted = row && (row.statusId === Inventory.statusCompleted)
    const showFinalize = (preview && user.canCloseInventory) && !isCompleted

    function clickCorrect(event) {
      event.preventDefault()
      history.push(urlCorrect)
    }
    
    async function clickFinalize(event) {
      event.preventDefault()
      if (!user.canCloseInventory) {
        await alert("Not permitted to close inventory - need an 'Admin' permission.")
        return
      }
      //. add description of what this will do
      if (await confirm("", "Are you sure you want to close and finalize this inventory? This will mark it as Completed and return you to the main screen.", "Yes, finalize", "No")) {
        await Inventory.finalize(inventoryId, auditors)
        history.push(urlClose)
      }
    }
  
    return (
      <div className="header">
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Started:</strong> {row && lib.formatDateTime(new Date(row.startTimestamp))}
              </td>
              <td>
                <strong>Finished:</strong> {row && getFinishedDate(row.endTimestamp)}
              </td>
              <td>
                <strong>Duration:</strong> {row && getDuration(moment(row.startTimestamp), moment(row.endTimestamp))}
              </td>
            </tr>
          </tbody>
        </table>
        <span className="buttons">
          {showCorrect && <span>
            <a onClick={clickCorrect} href={urlCorrect}>&#9664;&nbsp;Correct</a>
          </span>}
          {showFinalize && 
          <span>
            <a onClick={clickFinalize} href={urlClose}>Finalize&nbsp;&#9654;</a>
          </span>
          }
        </span>
        <Help msg={helpMsg} />
      </div>
    )
  }
  
  
  function Footer(props, gridApi, variables, data) {
  
    function getTotal(field) {
      if (data === null) return 0
      let total = 0
      data.forEach(item => {
        // const discrepancy = item.displayOnHand + item.trayOnHand - item.externalOnHand
        const discrepancy = item.countDiscrepancyFixed
        if (field === 'priceDiscrepancy') {
          total += discrepancy * item.price
        } else {
          total += Math.abs(discrepancy)
        }
      })
      return total
    }
  
    return (
      <div className="footer">
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Total Absolute Count Discrepancy:</strong> {getTotal("discrepancy")}
              </td>
              {/* <td>
                <strong>Total Price Discrepancy:</strong> {lib.getDollarAmount(getTotal("priceDiscrepancy"))}
              </td> */}
              <td>
                {row && row.auditor1Name && 
                  <>
                    {row.auditor2Name ? <strong>Auditor 1: </strong> : <strong>Auditor: </strong>}
                    {row && lib.reverseName(row.auditor1Name)}
                  </>
                }
              </td>
              <td>
                {row && row.auditor2Name && 
                  <>
                    <strong>Auditor 2: </strong>
                    {row && lib.reverseName(row.auditor2Name)}
                  </>
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
  
  // calculate totals for fixed bottom row
  function bottomRowCallback(rows) {
    let trayTotal = 0
    let displayTotal = 0
    let externalTotal = 0
    let storeCostTotal = 0
    let externalCostTotal = 0
    let costDiscrepancyTotal = 0
    let storePriceTotal = 0
    let externalPriceTotal = 0
    let priceDiscrepancyTotal = 0
    for (const row of rows) {
      // handle null price and cost
      const price = row.price || 0
      const cost = row.cost || 0

      trayTotal += row.trayOnHand
      displayTotal += row.displayOnHand
      externalTotal += row.externalOnHand

      storeCostTotal += cost * (row.displayOnHand + row.trayOnHand)
      externalCostTotal += cost * row.externalOnHand
      costDiscrepancyTotal += cost * row.countDiscrepancyFixed

      storePriceTotal += price * (row.displayOnHand + row.trayOnHand)
      externalPriceTotal += price * row.externalOnHand
      priceDiscrepancyTotal += price * row.countDiscrepancyFixed
    }
    const shrink = (100 * (externalCostTotal - storeCostTotal) / externalCostTotal).toFixed(1)
    return [
      { 
        code: 'Totals',
        name: `Shrink (cost): ${isNaN(shrink) ? 'n/a' : (shrink + '%')}`,
        displayOnHand: displayTotal,
        trayOnHand: trayTotal,
        externalOnHand: externalTotal,
        countDiscrepancyFixed: (displayTotal + trayTotal - externalTotal),
        storeCostTotal,
        costDiscrepancyTotal,
        externalCostTotal,
        storePriceTotal,
        externalPriceTotal,
        priceDiscrepancyTotal,
        alert: '',
      }
    ]
  }
  
  const g = grid.Grid(props, {
    gridOptions,
    Model: InventoryItem,
    variables,
    Header,
    Footer,
    breadcrumbs,
    context: { row },
    bottomRowCallback,
  })
  return (
    <div className="InventoryReview">
      {g}
    </div>
  )
}
