// show a set of display cases as cards

import React from 'react'
import Cards from '../../packages/cards'
import alert from '../../packages/alert'
import confirm from '../../packages/confirm'
import getChoice from '../../packages/getChoice'
import getString from '../../packages/getString'
import Infobox from '../../packages/Infobox'
import Entity from '../../models/entity'
import Location from '../../models/location'
import LocationItem from '../../models/locationItem'
import { useGlobals } from '../../globals'
import { useUser } from '../../services/user'
import { openDialog, closeDialog } from '../../packages/react-async-dialog'
import { downloadDisplays } from '../../services/downloads'
import ItemSearchDropdown from '../../parts/ItemSearchDropdown'
import './styles.scss'

// show display cases as opposed to categories or trays etc
const locationTypeId = Location.locationTypeDisplay

export default function ShowDisplays({ match, history }) {
  // get id from url
  const storeId = Number(match.params.storeId)

  const globals = useGlobals()
  const [user] = useUser(history) // redirect if not logged in

  // fetch displays
  const [cards, setCards] = React.useState(null)
  React.useEffect(() => {
    async function fetchData() {
      const rows = await Location.getRows({ locationTypeId, entityId: storeId })
      rows.sort((a, b) => (a.number < b.number ? -1 : 1))
      rows.sort((a, b) => (a.name < b.name ? -1 : 1))
      setCards(rows)
      // set breadcrumbs
      const store = await Entity.getRow({ id: storeId })
      const company = store.entityParents[0].entityByParentid
      const breadcrumbs = [
        { name: 'Display Count - ' + company.name, key: `/count/stores` },
        { name: store.name },
      ]
      globals.breadcrumbs = breadcrumbs
      // get the 'store' localstorage scrollvalue and scroll to it
      //. make lib fns
      const scrollTop = Number(
        window.localStorage.getItem('storeScrollTop') || '0'
      )
      // const el = document.getElementsByClassName('contents')[0]
      const el = document.getElementsByClassName('ShowDisplays')[0]
      if (el) {
        el.scrollTop = scrollTop
      }
    }
    fetchData()
    // }, [storeId, globals.breadcrumbs]) //. causes items to be reloaded and wipes out new changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId]) //. stuck with this warning for now

  function clickCard(evt) {
    const displayId = evt.currentTarget.id
    gotoDisplay(displayId, history)
  }

  async function pressCard(evt) {
    if (!user.canMaintainDisplays) {
      await alert('No permission to modify displays.')
      return
    }
    const displayId = Number(evt.currentTarget.id)
    const index = cards.findIndex(card => card.id === displayId)
    if (index !== -1) {
      const options = [
        { value: 'changeName', label: `Change Name` },
        { value: 'changeNumber', label: `Change Number` },
        { value: 'clear', label: 'Clear Contents' },
        { value: 'delete', label: 'Delete Display' },
      ]
      const ret = await getChoice('Modify Display', '', options, 'changeName')
      if (ret.ok) {
        if (ret.value === 'changeName') {
          changeDisplayName(cards, index)
        } else if (ret.value === 'changeNumber') {
          changeDisplayNumber(cards, index)
        } else if (ret.value === 'clear') {
          clearDisplay(cards, index)
        } else if (ret.value === 'delete') {
          deleteDisplay(cards, index)
        }
      }
    }
  }

  async function changeDisplayName(cards, index) {
    const card = cards[index]
    const ret = await getString('Change Display Name', '', card.name)
    if (ret.ok) {
      const changes = { name: ret.value }
      if (await Location.updateRow(card, changes)) {
        const newCards = [
          ...cards.slice(0, index),
          { ...card, name: ret.value },
          ...cards.slice(index + 1),
        ]
        setCards(newCards)
      }
    }
  }

  async function changeDisplayNumber(cards, index) {
    const card = cards[index]
    const ret = await getString('Change Display Number', '', card.number)
    if (ret.ok) {
      const changes = { number: ret.value }
      if (await Location.updateRow(card, changes)) {
        const newCards = [
          ...cards.slice(0, index),
          { ...card, number: ret.value },
          ...cards.slice(index + 1),
        ]
        setCards(newCards)
      }
    }
  }

  async function clearDisplay(cards, index) {
    const card = cards[index]
    if (card.uniqueItems > 0) {
      if (
        await confirm(
          'Clear Display',
          `Would you like to remove all items from ${card.name}? The display itself will remain. This cannot be undone!`,
          'Yes, remove all items',
          'No'
        )
      ) {
        if (await LocationItem.deleteRows({ locationId: card.id })) {
          card.uniqueItems = 0
          card.sumPieces = 0
          const newCards = [
            ...cards.slice(0, index),
            { ...card },
            ...cards.slice(index + 1),
          ]
          setCards(newCards)
        }
      }
    } else {
      await alert('The display is already empty')
    }
  }

  async function deleteDisplay(cards, index) {
    const card = cards[index]
    if (
      await confirm(
        'Delete Display',
        'Would you like to remove this display and its contents from this store? This cannot be undone!',
        'Yes, remove this display',
        'No'
      )
    ) {
      if (await LocationItem.deleteRows({ locationId: card.id })) {
        if (await Location.deleteRow({ locationId: card.id })) {
          const newCards = [...cards.slice(0, index), ...cards.slice(index + 1)]
          setCards(newCards)
        }
      }
    }
  }

  return (
    <div className="ShowDisplays">
      <Cards
        Header={DisplayHeader}
        headerContext={{ history }}
        cards={cards}
        Card={DisplayCard}
        clickCard={clickCard}
        pressCard={pressCard}
        setCards={setCards}
        Model={Location}
        parentId={storeId}
      />
    </div>
  )
}

function DisplayCard({ card }) {
  console.log(card.name)
  return (
    <div className="card DisplayCard">
      <span className="DisplayCard-number">{card.number}</span>
      <span className="DisplayCard-name">{card.name}</span>
      <span className="DisplayCard-unique">{card.uniqueItems} unique</span>
      <span className="DisplayCard-sum">
        {(card.sumPieces || 0) + ' total'}
      </span>
    </div>
  )
}

//. parentId -> storeId
function DisplayHeader(
  Model,
  setCards,
  limit,
  cards,
  parentId,
  setFilter,
  headerContext
) {
  const [user] = useUser()

  const instructions =
    'Select a display from the list' +
    (user.canUpdateDisplayLog
      ? ', or press and hold to rename, clear contents, or remove:'
      : ':')

  async function clickAddDisplay() {
    if (!user.canUpdateDisplayLog) {
      await alert("No permission to add displays - need 'Admin' permission.")
      return
    }
    const ret = await addDisplay(user, parentId)
    if (ret.ok) {
      const display = ret.display
      setCards([...cards, display])
    }
  }

  function clickDownload() {
    downloadDisplays(cards)
  }

  function selectItem(suggestion) {
    const displayId = suggestion.locationId
    const { history } = headerContext
    gotoDisplay(displayId, history)
  }

  const itemSearchVariables = {
    user,
    entityId: parentId,
  }

  return (
    <div className="ShowDisplays-header">
      <Infobox text={instructions} breakpoint={910} />
      <span className="buttons">
        <button onClick={clickAddDisplay}>Add&#x202f;Display...</button>
        <button onClick={clickDownload}>Download...</button>
        <ItemSearchDropdown
          // label="Search"
          placeholder="Search items"
          Card={ItemSearchCard}
          selectItem={selectItem}
          query={LocationItem.getItemDisplays}
          variables={itemSearchVariables}
        />
      </span>
    </div>
  )
}

// show new display dialog
function addDisplay(user, parentId) {
  return new Promise((resolve, reject) => {
    openDialog(AddDisplay, { user, resolve, parentId })
  })
}

function AddDisplay({ user, resolve, parentId }) {
  const storeId = parentId

  const handleClickedOK = async () => {
    const displayName = document.querySelector('#display-name').value
    const displayNumber = document.querySelector('#display-case').value

    if (!displayName) {
      return alert('Please fill in name')
    }

    const newDisplay = {
      name: displayName,
      number: displayNumber,
      locationTypeId, //defined above
      entityId: storeId,
    }

    const display = await Location.addRow(newDisplay)
    if (display) {
      closeDialog()
      resolve({ ok: true, display })
    } else {
      alert('ERROR')
    }
  }

  const handleClickedCancel = () => {
    closeDialog()
    resolve({ ok: false })
  }

  return (
    <div className="addDisplay">
      <h3>Add a New Display</h3>
      <div className="addDisplay-options">
        <div>
          <p>Display Name</p>
          <input type="text" autoFocus autoComplete="off" id="display-name" />
        </div>
        <div>
          {/* <p>Case Identifier</p> */}
          <p>Display Number/Id</p>
          <input type="text" autoComplete="off" id="display-case" />
        </div>
      </div>
      <div className="addDisplay-buttons">
        <button id="cancel" onClick={handleClickedCancel}>
          Cancel
        </button>
        <button id="ok" onClick={handleClickedOK}>
          OK
        </button>
      </div>
    </div>
  )
}

function ItemSearchCard(item) {
  return (
    <div className="ItemSearchCard suggestion">
      <img
        src={item.imageUrl}
        alt=""
        onError={e => {
          e.target.onerror = null
          e.target.src = 'images/image.png'
        }}
      />
      <div>
        <div>{item.code}</div>
        <div className="name">{item.name}</div>
        <div className="locationName">Location: {item.locationName}</div>
        {/* <div className="locationNumber">Location: {item.locationNumber}</div> */}
        <div className="currentCount">Count: {item.currentCount}</div>
      </div>
    </div>
  )
}

function gotoDisplay(displayId, history) {
  // save the scroll pos for return visit
  //. make lib fns
  // const el = document.getElementsByClassName('contents')[0]
  const el = document.getElementsByClassName('ShowDisplays')[0]
  window.localStorage.setItem('storeScrollTop', String(el.scrollTop))
  // goto the display
  history.push(`/count/display/${displayId}`)
}
