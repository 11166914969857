// used by displaycount and inventorycount to filter items

import React from 'react'
import './styles.scss'

export default function ({
  title,
  placeholder,
  filter,
  setFilter,
}) {

  function changeFilter(event) {
    const filter = event.currentTarget.value
    setFilter(filter)
  }

  function keydownFilter(event) {
    if (event.key === 'Escape') {
      setFilter('')
    }
  }

  return (
    <span className="FilterItems">
      {title && <span className="title">{title}&nbsp;</span>}
      <input
        type="text"
        placeholder={placeholder}
        value={filter}
        onChange={changeFilter}
        onKeyDown={keydownFilter}
      />
    </span>
  )
}
