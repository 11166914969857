/*
 * barcode scanner using video feed
 * currently, headers have a camera btn, which calls openScanner.
 * need to add <Scanner onClose={closeScanner} /> to the render tree.
 */

import * as zbarWasm from '@undecaf/zbar-wasm' // see https://github.com/undecaf/zbar-wasm/
import beep from './sounds/beep-07a.mp3'
import './styles.css'
export { Scanner } from './Scanner'

const usingOffscreenCanvas = haveOffscreenCanvas()

let containerEl
let videoEl
let canvasEl
let ctx
let animationFrameId

// open scanner
// options is { barcodeTypes, validPrefix, handleBarcode, handleItem, handleError }
// call this from an onClick handler
export async function openScanner(options) {
  console.log('openScanner', options)

  containerEl = document.getElementById('scanner-container')
  videoEl = document.getElementById('scanner-video')
  canvasEl = document.getElementById('scanner-canvas')

  showContainer()

  try {
    // just use EAN13 for now
    //. use options.barcodeTypes array and a map to zbar symbols
    const scanner = await zbarWasm.getDefaultScanner()
    scanner.setConfig(
      zbarWasm.ZBarSymbolType.ZBAR_EAN13,
      zbarWasm.ZBarConfigType.ZBAR_CFG_ENABLE,
      1
    )

    // get context once video is available
    videoEl.addEventListener('loadeddata', () => {
      console.log('video data loaded - get canvas context')
      ctx = getCanvasContext()
      console.log('ctx', ctx)
    })

    console.log('attach video stream to video element')
    await attachVideo() // will fire loadeddata event, caught above

    // check for barcode
    // when found, will call handleBarcode(), then handleItem() if okay.
    // else it will continue calling itself after each animation frame.
    console.log('call checkVideo')
    checkVideo(options)
  } catch (error) {
    //. do we need to await error? test
    console.log('error', error)
    closeScanner()
    await options.handleError(error.message)
  }
}

// check current video frame for barcode
async function checkVideo(options) {
  // make sure context and canvas is available
  if (ctx && canvasEl.height && canvasEl.width) {
    //
    // draw current video frame to canvas
    ctx.drawImage(videoEl, 0, 0)

    // get image data from canvas
    const imageData = ctx.getImageData(0, 0, canvasEl.width, canvasEl.height)

    // check for barcodes
    zbarWasm
      .scanImageData(imageData)
      .then(async symbols => {
        const symbol = symbols[0] // just want one barcode
        if (symbol) {
          const barcode = symbol.decode('utf-8')
          console.log('found barcode', barcode)

          playSound()

          console.log('calling checkPrefix')
          if (await checkPrefix(options, barcode)) {
            console.log('calling options.handleBarcode')
            const item = await options.handleBarcode(barcode)
            if (item) {
              closeScanner()
              console.log('calling options.handleItem')
              await options.handleItem(item)
              return // return without calling this function again
            }
          }
          // invalid barcode - keep scanning
          console.log('invalid barcode - keep scanning')
          // calls this function on next animation frame
          animationFrameId = window.requestAnimationFrame(() =>
            checkVideo(options)
          )
        } else {
          // no barcode found - keep scanning
          console.log('no barcode found - keep scanning')
          // calls this function on next animation frame
          animationFrameId = window.requestAnimationFrame(() =>
            checkVideo(options)
          )
        }
      })
      //. need async error here? test
      // .catch(error => {
      .catch(async error => {
        console.log('error', error)
        closeScanner()
        console.log('calling options.handleError')
        await options.handleError(error.message)
      })
    // return without calling requestAnimationFrame
    return
  } else {
    // no context or canvas yet - call requestAnimationFrame
    // calls this function on next animation frame
    animationFrameId = window.requestAnimationFrame(() => checkVideo(options))
  }
}

function playSound() {
  console.log('playing sound')
  const audio = new Audio(beep)
  audio.play()
}

// check prefix, if any
async function checkPrefix(options, barcode) {
  const { validPrefix } = options
  console.log('check prefix=', validPrefix)
  if (validPrefix) {
    const isValid = barcode.startsWith(validPrefix)
    if (!isValid) {
      console.log('invalid prefix')
      const msg = `Recognized barcode ${barcode} but doesn't have correct prefix ${validPrefix}.`
      await options.handleError(msg)
      return false // keep scanning if invalid prefix
    }
  }
  return true
}

// attach camera media stream to the video element
async function attachVideo() {
  // see https://developer.mozilla.org/en-US/docs/Web/API/MediaStream
  const mediaStream = await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: { facingMode: 'environment' },
  })
  videoEl.srcObject = mediaStream
}

// detach camera from video element
function detachVideo() {
  if (animationFrameId) {
    window.cancelAnimationFrame(animationFrameId)
    animationFrameId = null
  }
  if (videoEl?.srcObject) {
    videoEl.srcObject.getTracks().forEach(track => track.stop())
    videoEl.srcObject = null
  }
}

// get context for canvas - onscreen or off
function getCanvasContext() {
  console.log('getCanvasContext')
  canvasEl.width = videoEl.naturalWidth || videoEl.videoWidth || videoEl.width
  canvasEl.height =
    videoEl.naturalHeight || videoEl.videoHeight || videoEl.height
  if (usingOffscreenCanvas) {
    console.log('use offscreen canvas for context')
    const offscreenCanvas = new OffscreenCanvas(canvasEl.width, canvasEl.height)
    return offscreenCanvas.getContext('2d')
  }
  console.log('use canvasEl for context')
  return canvasEl.getContext('2d')
}

function haveOffscreenCanvas() {
  try {
    return Boolean(new OffscreenCanvas(1, 1).getContext('2d'))
  } catch {
    return false
  }
}

function showContainer() {
  containerEl?.classList.add('visible')
}

function hideContainer() {
  containerEl?.classList.remove('visible')
}

// close scanner
export function closeScanner() {
  detachVideo()
  hideContainer()
  ctx = null
}
