// file download services

import fileDownload from 'js-file-download'
import moment from 'moment'
import Papa from 'papaparse'
import Entity from '../models/entity'
import Location from '../models/location'
import LocationItem from '../models/locationItem'
import Progress from '../packages/progress'


const filename = "display-counts.csv"
const header = "Store,Display,Style,Barcode,Description,Color,Pieces"
const quotes = [true,true,true,true,true,true,true,false]
const csvConfig = { quotes }


export async function downloadStores(stores) {
  const progress = new Progress("Download Display Counts", "Gathering items for export...")
  let rows = []
  for (const store of stores) {
    const displays = await Location.getRows({ entityId: store.id })
    for (const display of displays) {
      const items = await LocationItem.getRows({ locationId: display.id })
      const status = 'Store ' + store.name + ' / Display ' + display.name
      progress.setStatus(status)
      for (const item of items) {
        const row = [store.name, display.name, item.code, item.barcode, 
          item.name, item.color, item.currentCount]
        rows.push(row)
        if (progress.cancelPressed) {
          progress.close()
          return
        }
      }
    }
  }
  console.log(rows);
  progress.close()
  const csvRows = Papa.unparse(rows, csvConfig)
  console.log(csvRows);
  const csv = header + '\n' + csvRows
  fileDownload(csv, filename)
}



export async function downloadDisplays(displays) {
  const progress = new Progress("Download Display Counts", "Gathering items for export...")
  let rows = []
  for (const display of displays) {
    const store = await Entity.getRow({ id: display.entity.id })
    const items = await LocationItem.getRows({ locationId: display.id })
    const status = 'Store ' + store.name + ' / Display ' + display.name
    progress.setStatus(status)
    for (const item of items) {
      const row = [store.name, display.name, item.code, item.barcode, 
        item.name, item.color, item.currentCount]
      rows.push(row)

      if (progress.cancelPressed) {
        progress.close()
        return
      }
    }
  }
  progress.close()
  const csvRows = Papa.unparse(rows, csvConfig)
  console.log(csvRows);
  const csv = header + '\n' + csvRows
  fileDownload(csv, filename)
}


// inventory discrepancy data
export async function downloadDiscrepancies(items) {
  const cols = "Store,Category,StartDate,EndDate,Style,Name,TrayCount,DisplayCount,StoreCount,ExpectedCount,CountDiscrepancy,UnitCost,StoreCost,ExpectedCost,CostDiscrepancy,UnitPrice,StorePrice,ExpectedPrice,PriceDiscrepancy".split(',')
  const progress = new Progress("Download Discrepancy Items", "Gathering items for export...")
  const rows = []
  rows.push(cols)
  for (const item of items) {
    const status = 'Item ' + item.itemName
    progress.setStatus(status)
    const row = cols.map(col => item[col])
    rows.push(row)
    if (progress.cancelPressed) {
      progress.close()
      return
    }
  }

  progress.close()
  const csv = Papa.unparse(rows)
  const filename = `InventoryDiscrepancies_${moment().format("YYYY-MM-DD-HH-mm")}.csv`
  fileDownload(csv, filename)
}


// download/export category/planogram
export async function exportPlanograms(source, categories) {
  const progress = new Progress("Export Planograms", "Gathering items for export...")
  let rows = []
  for (const category of categories) {
    const items = await LocationItem.getRows({ locationId: category.id })
    const status = `Source ${source.name} / Category ${category.name}`
    progress.setStatus(status)
    for (const item of items) {
      const row = [source.name, category.name, item.code, item.position]
      rows.push(row)
      if (progress.cancelPressed) {
        progress.close()
        return
      }
    }
  }
  progress.close()
  const csvRows = Papa.unparse(rows)
  const header = "Source,Tray Name,Style,Position"
  const csv = header + '\n' + csvRows
  const filename = `ExportPlanograms_${moment().format("YYYY-MM-DD-HH-mm")}.csv`
  fileDownload(csv, filename)
}
