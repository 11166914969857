// header with maintain display case buttons
// contains the barcode scanner code also

import React from 'react'
import Infobox from '../../../packages/Infobox'
import {
  Scanner,
  openScanner,
  closeScanner,
} from '../../../parts/ScannerZbarWasm'
import alertDialog from '../../../packages/alert'
import confirm from '../../../packages/confirm'
import Item from '../../../models/item'
import * as constants from '../../../constants'
import ItemSearchDropdown from '../../../parts/ItemSearchDropdown'
// import getString from '../../../packages/getString' //. _Dialog
// import { GoPlus as AddStyleIcon } from 'react-icons/go'
// import { GoDeviceCamera as CameraIcon } from 'react-icons/go'
import BarcodeIcon from '../../../assets/images/barcode3.png'
import FilterItems from '../FilterItems'
import { useUser } from '../../../services/user'
import './styles.scss'

const instructions =
  'Select item to edit, tap +/- to adjust count, or hold to remove.'

export default function DisplayCountHeader({
  displayId,
  items,
  saveCount,
  filter,
  setFilter,
}) {
  const [user] = useUser()

  // make sure we close the scanner when leave page
  React.useEffect(() => {
    return () => closeScanner()
  }, [])

  // camera button clicked - open barcode scanner
  function clickCamera() {
    const { barcodeTypes, validPrefix } = constants.scanner?.items ?? {}
    console.log('call openScanner', { barcodeTypes, validPrefix })
    openScanner({
      barcodeTypes, //. handle this
      validPrefix,
      handleBarcode,
      handleItem,
      handleError,
    })
  }

  // lookup item associated with barcode, if any
  async function handleBarcode(barcode) {
    console.log('handleBarcode', barcode)

    // check if item is in db
    const item = await Item.getRow({ barcode, user })
    if (!item) {
      const msg = `Couldn't find barcode ${barcode} in database, or is not visible to you or your company. Please check that the scanner read the number correctly.`
      await alertDialog(msg)
      return null // keep scanning if no such item
    }

    return item // will call handleItem with this
  }

  async function handleItem(item) {
    console.log('handleItem', item)
    console.log('calling getItemCount')
    await getItemCount(item)
  }

  async function handleError(error) {
    console.log('handleError', error)
    console.log('calling alertDialog')
    await alertDialog(error)
  }

  async function getItemCount(item) {
    console.log('getItemCount', item)

    const itemId = item.id

    // check if item is already in display
    const index = items.findIndex(i => i.id === itemId)
    const inList = index !== -1
    if (inList) {
      // yes, in list
      await alertDialog(`Item ${item.code} is already in this display.`)
    } else {
      // no, item not in this display - ask if want to add

      // get more item details from db
      //. don't we already have the item?
      // const item = await Item.getRow({ id: itemId, user })
      // console.log(item)

      // fill in missing values needed for the update api
      item.displayId = Number(displayId)
      item.initialCount = 0
      item.currentCount = 0
      item.delta = item.currentCount - item.initialCount

      // ask if user wants to add the item to the display
      // if so, get the delta and update db
      const imageStyle = {
        float: 'left',
        width: '5em',
        height: '5em',
        objectFit: 'contain',
        marginRight: '1em',
      }
      const message = (
        <span>
          <br />
          <img src={item.imageUrl} alt="" style={imageStyle} />
          {`Item ${item.code} "${item.name}" (barcode ${item.barcode}) is not currently in this display.`}
          <br />
          <br />
          Do you want to add it?
        </span>
      )
      if (
        await confirm('Add item to display', message, 'Yes, add item', 'No')
      ) {
        await saveCount(item, 1)
      }
    }
  }

  // user selected an item from the itemsearch dropdown - add to main category
  async function addItem(suggestion) {
    console.log('addItem from search dropdown', suggestion)
    const itemId = suggestion.id
    const item = await Item.getRow({ id: itemId, user })
    console.log(item)
    if (!item) {
      const str = JSON.stringify(suggestion)
      const msg = `Item not visible to you in the database: ${str}`
      await alertDialog(msg)
      return
    }
    console.log('calling getItemCount')
    await getItemCount(item)
  }

  // // user clicked on '+' button - enter a style
  // async function clickAddStyle() {
  //   const ret = await getString("Add item to display", "Enter style number (e.g. 190829CZ-50):")
  //   if (ret.ok) {
  //     const code = ret.value
  //     const item = await Item.getRow({ code, user })
  //     if (!item) {
  //       await alertDialog(`Couldn't find ${code} in database - please double check the style.`)
  //       return
  //     }
  //     console.log(item)
  //     await getItemCount(item)
  //   }
  // }

  const itemSearchVariables = {
    user,
  }

  return (
    <>
      <div className="display-count-header">
        <div className="top">
          {user.canUpdateDisplayLog && (
            <Infobox text={instructions} breakpoint={900} />
          )}
          <span className="filter">
            <FilterItems
              placeholder="Filter items"
              filter={filter}
              setFilter={setFilter}
            />
          </span>
          {user.canUpdateDisplayLog && (
            <span className="buttons">
              <ItemSearchDropdown
                // label="Add Item: "
                placeholder="Add item"
                selectItem={addItem}
                query={Item.getRows}
                variables={itemSearchVariables}
              />
              <button
                className="camera-button"
                onClick={clickCamera}
                title="Scan a barcode"
              >
                <img src={BarcodeIcon} alt="" /> Scan Item
              </button>
            </span>
          )}
        </div>
      </div>
      <Scanner onClose={closeScanner} />
    </>
  )
}
