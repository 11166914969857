import { useGlobals } from '../globals'


export const useAuditors = () => {
  const globals = useGlobals()
  if (globals.auditors) {
    return globals.auditors
  }
  const auditors = JSON.parse(localStorage.getItem('auditors') || "[]")
  return auditors
}

export function rememberAuditors(auditors) {
  localStorage.setItem('auditors', JSON.stringify(auditors))
}
