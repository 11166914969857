import React from 'react'
import { gql } from 'apollo-boost'
import apolloClient from '../../services/apolloClient'
import * as constants from '../../constants'
import LocationItem from '../locationItem'
import Location from '../location'

export default class Item {
  // get items that the given user can see.
  // user is required.
  static async getRows(
    { code, barcode, id, offset, limit, user = {}, isActive = true },
    cached = false
  ) {
    let rows = []
    const variables = { code, barcode, id, offset, limit, isActive }
    // console.log(variables)

    // get custom store items
    if (user.storeIds) {
      variables.sourceIds = user.storeIds
      const storeRows = await Item.getAllRows(variables, cached)
      rows = rows.concat(storeRows)
    }

    // get custom company items
    if (user.companyIds) {
      variables.sourceIds = user.companyIds
      const companyRows = await Item.getAllRows(variables, cached)
      rows = rows.concat(companyRows)
    }

    // get corporate (pandora) items
    if (user.corporateIds) {
      variables.sourceIds = user.corporateIds
      const corporateRows = await Item.getAllRows(variables, cached)
      rows = rows.concat(corporateRows)
    }

    rows.sort((a, b) => (a.code < b.code ? -1 : 1))
    return rows
  }

  static async getAllRows(
    { code, barcode, id, offset, limit, sourceIds, isActive = true },
    cached = false
  ) {
    const table = 'item'
    const query = gql`
      query GetItems($code: String, $barcode: String, $id: Int, $offset: Int, $limit: Int, $sourceIds: [Int!], $isActive: Boolean) {
        __typename
        ${table}(limit: $limit, offset: $offset, where: {
          code: {_ilike: $code}, 
          barcode: {_eq: $barcode}, 
          id: {_eq: $id}, 
          isActive: {_eq: $isActive}, 
          entity: {id: {_in: $sourceIds}}
        }, order_by: {code: asc}) {
          id
          name
          code
          color
          barcode
          price
          cost
          isActive
          source: entity { id, name }
        }
      }
    `
    const fetchPolicy = cached ? 'cache-first' : 'network-only'
    const variables = { code, barcode, id, offset, limit, sourceIds, isActive }
    const { data } = await apolloClient.query({ query, variables, fetchPolicy })
    const rows = data[table]
    rows.forEach(row => flattenRow(row))
    return rows
  }

  // user is required in variables
  static async getRow(variables) {
    const rows = await Item.getRows(variables)
    console.log(variables, rows)
    if (rows && rows.length === 1) {
      const item = rows[0]
      return item
    }
    return null
  }

  static async updateRow(data, changes = {}) {
    // merge existing and new values
    const merged = { ...data, ...changes }
    const { id, sourceId, name, code, color, barcode, price, isActive } = merged
    const variables = {
      id,
      sourceId,
      name,
      code,
      color,
      barcode,
      price,
      isActive,
    }
    try {
      const mutation = gql`
        mutation UpdateItem(
          $id: Int!
          $sourceId: Int
          $name: String
          $code: String
          $barcode: String
          $price: numeric
          $color: String
          $isActive: Boolean
        ) {
          __typename
          update_item(
            _set: {
              sourceId: $sourceId
              name: $name
              code: $code
              barcode: $barcode
              price: $price
              color: $color
              isActive: $isActive
            }
            where: { id: { _eq: $id } }
          ) {
            affected_rows
          }
        }
      `
      console.log('update_item', variables)
      const ret = await apolloClient.mutate({ mutation, variables })
      console.log(ret)
      return ret
    } catch (e) {
      alert(e.message)
    }
  }

  // // used by ivr import - need it for speed
  // static async updatePrice({ id, price }) {
  //   const variables = { id, price }
  //   try {
  //     const mutation = gql`
  //       mutation UpdatePrice($id: Int!, $price: numeric) {
  //         __typename
  //         update_item(_set: {price: $price}, where: {id: {_eq: $id}}) {
  //           affected_rows
  //         }
  //       }
  //     `
  //     console.log('update_item', variables)
  //     const ret = await apolloClient.mutate({ mutation, variables })
  //     console.log(ret)
  //     return ret
  //   } catch(e) {
  //     alert(e.message)
  //   }
  // }

  // used by ivr import to update price and cost fields - need it for speed
  static async updateValue({ id, field, value }) {
    const variables = { id, [field]: value }
    try {
      const mutation = gql`
        mutation UpdatePrice($id: Int!, $${field}: numeric) {
          __typename
          update_item(_set: {${field}: $${field}}, where: {id: {_eq: $id}}) {
            affected_rows
          }
        }
      `
      console.log('update_item', mutation, variables)
      const ret = await apolloClient.mutate({ mutation, variables })
      console.log(ret)
      return ret
    } catch (e) {
      alert(e.message)
    }
  }

  // returns id of new item
  static async addRow(data) {
    const { name, code, color, barcode, price, cost, isActive, sourceId } = data
    const variables = {
      name,
      code,
      color,
      barcode,
      price,
      cost,
      isActive,
      sourceId,
    }
    try {
      const mutation = gql`
        mutation AddItem(
          $name: String!
          $code: String!
          $barcode: String
          $price: numeric
          $cost: numeric
          $color: String
          $isActive: Boolean
          $sourceId: Int!
        ) {
          __typename
          insert_item(
            objects: {
              name: $name
              code: $code
              barcode: $barcode
              price: $price
              cost: $cost
              color: $color
              isActive: $isActive
              sourceId: $sourceId
            }
          ) {
            returning {
              id
            }
          }
        }
      `
      const ret = await apolloClient.mutate({ mutation, variables })
      console.log(ret)
      const id =
        ret &&
        ret.data &&
        ret.data.insert_item &&
        ret.data.insert_item.returning[0].id
      return id
    } catch (e) {
      alert(e.message)
    }
  }

  static async deleteRow({ itemId }) {
    try {
      const action = 'delete_item'
      const mutation = gql`
        mutation DeleteRow($itemId: Int!) {
          __typename
          ${action}(where: {id: {_eq: $itemId}}) { affected_rows }
        }
      `
      const variables = { itemId }
      const ret = await apolloClient.mutate({ mutation, variables })
      return ret
    } catch (e) {
      alert(e.message)
    }
  }

  /**
   * get maps from item code/style and item id to item record.
   * includes inactive items.
   * this is used by planogram and ivr import.
   * may return warnings if >1 item records have same code/style.
   * see also getMapCodeToItem in importInventory.js.
   */
  static async getMapCodeToItemSimple({ user }, duplicateCodes) {
    const items = await Item.getRows({ user, isActive: null }) // get all items, incl inactives
    const mapCodeToItem = {}
    for (const item of items) {
      if (mapCodeToItem[item.code]) {
        // const msg = <div>Style has duplicate records visible: {item.code}<br/><br/></div>
        // warnings.push(msg)
        duplicateCodes[item.code] = 1
      }
      mapCodeToItem[item.code] = item
    }
    return mapCodeToItem
  }

  /**
   * get all items visible to user into list, including inactive ones.
   * used by inventory import.
   */
  static async getMapIdToItem({ user }) {
    const items = await Item.getRows({ user, isActive: null }) // get all 10k items, incl inactives
    const mapIdToItem = {}
    for (const item of items) {
      mapIdToItem[item.id] = item
    }
    return mapIdToItem
  }

  static async countRows(variables) {
    const rows = await Item.getRows(variables)
    return rows.length
  }

  static async clone({ id, user }) {
    const item = await Item.getRow({ id, user })
    const cloneId = await Item.addRow({ ...item, name: item.name + ' (copy)' })
    const clone = await Item.getRow({ id: cloneId, user })
    return clone
  }

  // get list of categories this item belongs to
  static async getCategories({ id }) {
    const categories = []
    const locationItems = await LocationItem.getRows({ itemId: id }) // list of { locationId, itemId, ... }
    if (locationItems && locationItems.length > 0) {
      const locationIds = locationItems.map(
        locationItem => locationItem.locationId
      )
      // filter down to just categories
      for (const locationId of locationIds) {
        const location = await Location.getRow({ id: locationId })
        if (location.locationTypeId === Location.locationTypeCategory) {
          categories.push(location)
        }
      }
    }
    return categories
  }
}

//. better way?
function flattenRow(row) {
  row.imageUrl =
    constants.baseImageUrlSmall + row.code + '.jpg' + constants.itemImageSuffix
  row.largeImageUrl =
    constants.baseImageUrlLarge + row.code + '.jpg' + constants.itemImageSuffix
  row.sourceId = row.source.id
  row.sourceName = row.source.name
}
