
const locale = 'en-us'
const currencyCode = 'USD'


export function formatDateLong(date) {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
  const s = date.toLocaleDateString(locale, options)
  return s  
}

export function formatDateTime(date) {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
  const s = date.toLocaleDateString(locale, options).replace(',', '')
  return s  
}

export function getDateTime(value) {
  return value ? formatDateTime(new Date(value)) : ''
}

export function getDollarAmount(value, places=0) {
  var absValue = Math.abs(value)
  const currencyFormat = { style: 'currency', currency: currencyCode, minimumFractionDigits: places, maximumFractionDigits: places }
  var returnString = Number(absValue).toLocaleString(locale, currencyFormat)
  return value < 0 ? '(' + returnString + ')' : returnString
}


export function groupby(objs, groupbyProp) {
  const d = {}
  for (let obj of objs) {
    const value = obj[groupbyProp]
    if (!d[value]) {
      d[value] = []
    }
    d[value].push(obj)
  }
  return d
}


export function reverseName(name) {
  return name && name.split(',').reverse().join(' ')
}


// update a displayed image
// kind of a roundabout way but tried several other methods with no luck
export function showImageFile(file, selector) {
  console.log('showImageFile', selector)
  const img = document.querySelector(selector)
  img.src = window.URL.createObjectURL(file)
  img.onload = function() {
    window.URL.revokeObjectURL(img.src) // free memory
  }
}


export function getNumberFromCurrency(currency) {
  return Number(currency.replace(/[$,]/g,""))
}
