// digital ocean spaces access


import AWS from 'aws-sdk'


const regionName = 'nyc3'
export const bucketName = 'tallieo'
const accessKeyId = 'VDMGUH2FYWFXLBDHIWVX'
const accessSecretKey = 'KmVgyYSf5ev4dp4AdBlW40GLvolQQsHk1PbQC1Pm7TI'

const endpointUrl = `${regionName}.digitaloceanspaces.com`

const spacesEndpoint = new AWS.Endpoint(endpointUrl)

export const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: accessKeyId,
  secretAccessKey: accessSecretKey,
})

export const bucketUrl = `https://${bucketName}.${endpointUrl}/`


// upload file to digitalocean spaces
// from https://stackoverflow.com/questions/46634433/how-to-upload-file-to-digital-ocean-spaces-using-javascript
export function uploadFile(path, file) {
  const params = { Body: file, Bucket: bucketName, Key: path }
  s3.putObject(params)
    .on('build', request => {
      request.httpRequest.headers.Host = bucketUrl
      request.httpRequest.headers['Content-Length'] = file.size
      request.httpRequest.headers['Content-Type'] = file.type // or file.mimetype?
      request.httpRequest.headers['x-amz-acl'] = 'public-read'
    })
    .send((err, data) => {
      if (err) return alert(JSON.stringify(err))
      console.log(data)
      const imageUrl = `${bucketUrl}/${path}`
      console.log(imageUrl)
    })
}
