import React from 'react'
import Cards from '../../packages/cards3'
import Entity from '../../models/entity'
import { useGlobals } from '../../globals'
import './styles.scss'
import { useRecoilState } from 'recoil'
import { orderState, wishlistState } from '../../recoil/order'
import useAppName from '../../hooks/useAppName'


const userId = 217 //. entity id - our sandraholding company account, for now


export default function OrderStores({ history }) {

  const globals = useGlobals()
  globals.breadcrumbs = "Stores"

  const appName = useAppName() // pull from url - order or wishlist

  const [cards, setCards] = React.useState(null)
  const [order, setOrder] = useRecoilState(orderState)
  const [wishlist, setWishlist] = useRecoilState(wishlistState)

  React.useEffect(() => {
    async function fetchData() {
      const rows = await Entity.getStores({ id: userId, isActive: true }, true)
      // remove the sandra holding warehouse stores
      const cards = rows.filter(row => !row.name.includes('Warehouse'))
      setCards(cards)
    }
    fetchData()
  }, [])

  function clickCard(event) {
    const storeId = Number(event.currentTarget.id)
    const store = cards.find(card=>card.id===storeId)
    // save to recoil and localstorage
    if (appName==='order') {
      setOrder({ ...order, store }) 
    } else {
      setWishlist({ ...wishlist, store })
    }
    // jump to categories page
    history.push(`/${appName}/categories/${storeId}`) // /order or /wishlist
  }

  return (
    <div className="OrderStores">
      <div className="header">
        {appName==='order'
          ? <span>Select a store near you:</span>
          : <span>Select the store you are waiting in line for:</span>
        }
      </div>
      <Cards
        cards={cards}
        CardComponent={StoreCard} // defined below
        clickCard={clickCard}
      />
    </div>
  )
}

function StoreCard({ card, clickCard, id, index }) {
  // store images are currently deployed with the web app itself - 
  // the file names should be the id and name, with spaces removed, 
  // eg '222TheGreene.jpg'
  // add ?v4 etc to bypass users cache if change images
  const imageUrl = `https://tallieo.web.app/images/stores/${id}${card.name}.jpg?v=4`.replace(/ /g, '')
  return (
    <div className="card3 StoreCard" onClick={clickCard} id={id}>
      <img src={imageUrl} alt="" onError={e=>{e.target.onerror = null; e.target.src="images/image.png"}} />
      <span className="StoreCard-name">{card.name}</span>
      {/* <span className="address">{card.address}</span> */}
      {/* <span className="phone"> */}
        {/* <a href={`tel:${card.phone}`}> */}
          {/* {card.phone} */}
        {/* </a> */}
      {/* </span> */}
    </div>
  )
}
