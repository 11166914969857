// lets user search for inventories with discrepancies in counts.
// uses a view in the db.

import React from 'react'
import moment from 'moment'
import { DateRangePicker } from 'react-dates'
import Select from 'react-select'
import { Link } from "react-router-dom"
import * as grid from '../../packages/grid'
import * as lib from '../../lib'
import alert from '../../packages/alert'
import Entity from '../../models/entity'
import Inventory from '../../models/inventory'
import ViewInventoryDiscrepancy from '../../models/viewInventoryDiscrepancy'
import { useUser } from '../../services/user'
import { downloadDiscrepancies } from '../../services/downloads'
import Help from '../../packages/Help'
import './styles.scss'


const breadcrumbs = "Inventory Discrepancies"

const helpMsg = <div>
  <strong>Inventory Discrepancies</strong><br/>
  <br/>
  Select a date range (midnight to midnight), then the store(s) you wish to view a report for, then click Search. <br/>
  <br/>
  Then select one or more inventories and click Download Report to get a .csv file.<br/>
  <br/>
  Note: the value shown for count discrepancies is the absolute value, so the total reflects any deviation from 0.<br/>
  <br/>
</div>

const columnDefs = [
  { field: 'endTimestamp', headerName: "Inventory End Date", width: 240, valueGetter: getEndDateTime, checkboxSelection: true },
  { field: 'absDiscrepancy', headerName: "Abs Count Discrep", width: 130, cellClass: 'right', cellClassRules: { "orange": "x > 0" } },
  { field: 'costDiscrepancy', headerName: "Cost Discrep", valueGetter: getCostDiscrepancy, width: 130, cellClass: params => params.value.charAt(0) === '$' ? 'green right' : 'red right' },
  { field: 'priceDiscrepancy', headerName: "Price Discrep", valueGetter: getPriceDiscrepancy, width: 130, cellClass: params => params.value.charAt(0) === '$' ? 'green right' : 'red right' },
  { field: 'storeName', headerName: "Store", width: 200 },
  { field: 'locationName', headerName: "Category", width: 300 },
  { field: 'action', width: 120, cellRendererFramework: renderActionCell },
]

function renderActionCell(params) {
  return params.data.inventoryId ? <Link to={`/inventory/${params.data.inventoryId}/review`} target="_blank" ><button>View</button></Link> : null
}

function getEndDateTime(params) {
  // return lib.getDateTime(params.data.endTimestamp)
  // return typeof(params.data.endTimestamp)==='string' ? params.data.endTimestamp : lib.getDateTime(params.data.endTimestamp)
  return params.data.endTimestamp ? lib.getDateTime(params.data.endTimestamp) : 'Totals'
}
function getCostDiscrepancy(params) {
  return lib.getDollarAmount(params.data.costDiscrepancy, 2)
}
function getPriceDiscrepancy(params) {
  return lib.getDollarAmount(params.data.priceDiscrepancy, 2)
}

const gridOptions = {
  columnDefs,
  rowSelection: 'multiple',
  getRowNodeId: row => row.inventoryId,
  headerHeight: 50, // px (default 25)
}


function Header({ history }, gridApi, variables, setData) {

  const [user] = useUser(history)

  const today = moment().startOf('day')
  const tomorrow = moment().add(1, 'day').startOf('day')
  const [startDate, setStartDate] = React.useState(today)
  const [endDate, setEndDate] = React.useState(tomorrow)
  const [focusedInput, setFocusedInput] = React.useState(null)
  const [storeOptions, setStoreOptions] = React.useState(null)
  const [storeIds, setStoreIds] = React.useState(null)

  // first time through, get list of stores for the store filter control
  React.useEffect(() => {
    async function fetchStores() {
      // const stores = await Entity.getRows({ entityTypeId: Entity.entityTypeStore })
      const stores = await Entity.getStores({ id: user.id })
      const storeOptions = stores.map(store => ({ label: store.name, value: store.id }))
      setStoreOptions(storeOptions)
    }
    fetchStores()
  }, [])

  function onDatesChange({ startDate, endDate }) {
    // the date picker always returns days with time of noon - convert them to midnight
    setStartDate(startDate && startDate.startOf('day'))
    setEndDate(endDate && endDate.startOf('day'))
  }

  function onSelectChange(selectedOptions) {
    if (selectedOptions === null) return setStoreIds(null)
    setStoreIds(selectedOptions.map(option => option.value))
  }

  async function clickSearch() {
    gridApi.showLoadingOverlay()
    async function fetchRows() {
      const variables = { startDate, endDate, storeIds }
      // if no stores selected, restrict query to available stores -
      // otherwise fetches ALL stores, across different companies.
      if (!storeIds || storeIds.length === 0) {
        variables.storeIds = storeOptions.map(option => option.value)
      }
      const rows = await ViewInventoryDiscrepancy.getRows(variables)
      setData(rows)
      gridApi.setRowData(rows)
      const bottomRow = bottomRowCallback(rows)
      gridApi.setPinnedBottomRowData(bottomRow)
      if (rows.length === 0) return gridApi.showNoRowsOverlay()
      gridApi.hideOverlay()
    }
    fetchRows()
  }

  function clickSelectAll() {
    gridApi.selectAll()
  }

  function clickSelectNone() {
    gridApi.deselectAll()
  }

  async function clickExport() {
    const inventories = gridApi.getSelectedRows()
    if (inventories.length < 1) { 
      await alert("Please select one or more inventories to download.")
      return
    }
    const inventoriesIds = inventories.map(inventory => inventory.inventoryId)
    const items = await Inventory.getDiscrepancyItems({ inventoriesIds })
    downloadDiscrepancies(items) // from services/downloads.js
  }

  return (
    <div className="header">
      <div className="select-dates">
        <div>Select Date Range: </div>
        <DateRangePicker
          startDate={startDate} // momentPropTypes.momentObj or null,
          startDateId="start_date" // PropTypes.string.isRequired,
          endDate={endDate} // momentPropTypes.momentObj or null,
          endDateId="end_date" // PropTypes.string.isRequired,
          onDatesChange={onDatesChange} // PropTypes.func.isRequired,
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
          small
          enableOutsideDays
          isOutsideRange={() => false}
          hideKeyboardShortcutsPanel
        />
      </div>
      <div className="select-stores">
        <div>Select Stores: </div>
        <Select
          defaultValue={[]}
          isMulti
          onChange={onSelectChange}
          isLoading={storeOptions === null}
          options={storeOptions}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: theme.colors,
          })}
        />
      </div>
      <button onClick={clickSearch}>Search</button>
      <button onClick={clickSelectAll}>Select All</button>
      <button onClick={clickSelectNone}>Select None</button>
      <button onClick={clickExport}>Download...</button>
      <Help msg={helpMsg} />
    </div>
  )
}


// calculate totals for fixed bottom row
function bottomRowCallback(rows) {
  let absDiscrepancyTotal = 0
  let costDiscrepancyTotal = 0
  let priceDiscrepancyTotal = 0
  for (const row of rows) {
    absDiscrepancyTotal += row.absDiscrepancy
    costDiscrepancyTotal += row.costDiscrepancy
    priceDiscrepancyTotal += row.priceDiscrepancy
  }
  return [
    {
      absDiscrepancy: absDiscrepancyTotal,
      costDiscrepancy: costDiscrepancyTotal,
      priceDiscrepancy: priceDiscrepancyTotal,
    }
  ]
}


export default function InventoryDiscrepancies(props) {
  const variables = null // null means don't fetch anything initially
  const g = grid.Grid(props, {
    gridOptions,
    Model: ViewInventoryDiscrepancy,
    variables,
    Header,
    // Footer,
    breadcrumbs,
    overlays: {
      loading: '<span class="ag-overlay-loading-center">Looking for Inventories...</span>',
      noRows: '<span class="ag-overlay-loading-center">No Inventories to Show</span>',
    },
    bottomRowCallback,
  })
  return (
    <div className="InventoryDiscrepancies">
      {g}
    </div>
  )
}
