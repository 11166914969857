// login 0 or more auditors

import React from 'react'
import { FaInfoCircle as IconInfo } from 'react-icons/fa'
import { TiDeleteOutline as DeleteIcon } from 'react-icons/ti'
import { useGlobals } from '../../globals'
import Inventory from '../../models/inventory'
import Entity from '../../models/entity'
import { useAuditors, rememberAuditors } from '../../services/auditors'
import { withFirebase } from '../../services/Firebase'
import alert from '../../packages/alert'
import './styles.scss'


export default withFirebase(function InventoryBegin({ history, match, firebase }) {

  const inventoryId = match.params.inventoryId
  const backUrl = "/"
  const continueUrl = `/inventory/${inventoryId}/count`

  const globals = useGlobals()
  globals.breadcrumbs = "Inventory Login"

  const auditorsLoggedin = useAuditors() // get from globals or localstorage
  const [numAuditors, setNumAuditors] = React.useState(0)
  const [auditors, setAuditors] = React.useState(auditorsLoggedin)
  console.log(auditors)

  React.useEffect(() => {
    async function fetchData() {

      // get inventory and associated store
      const inventory = await Inventory.getRow({ inventoryId })
      console.log(inventory)
      const { storeId } = inventory
      const store = await Entity.getRow({ id: storeId })
      const { numAuditors } = store
      setNumAuditors(numAuditors)

      // if no auditors for this store required skip the auditor login
      if (!numAuditors) {
        history.push(continueUrl)
      }

      // get auditor records
      const auditors = []
      for (let i = 0; i < numAuditors; i++) {
        const id = i===0 ? inventory.auditor1Id : inventory.auditor2Id
        const auditor = await Entity.getRow({ id }) || {}
        auditors.push(auditor)
      }
      setAuditors(auditors)
    }
    fetchData()
  }, [inventoryId, history, continueUrl])

  // save auditors to an inventory record and set status
  async function saveAuditors(inventoryId, auditors) {
    const data = await Inventory.getRow({ inventoryId })
    const auditor1Id = auditors[0] && auditors[0].id
    const auditor2Id = auditors[1] && auditors[1].id
    const statusId = Inventory.statusStarted
    const changes = { auditor1Id, auditor2Id, statusId }
    await Inventory.updateRow(data, changes)
  }

  async function clickLogin(i) {
    const email = document.querySelector("#email" + i).value
    const password = document.querySelector("#password" + i).value
    let isAlreadyLoggedIn = false

    auditors.forEach(async auditor => {
      if (auditor.email === email) {
        isAlreadyLoggedIn = true
        alert(`You can't login using the same auditor`)
      }
    })

    if (isAlreadyLoggedIn) {
      return 
    }

    // const user = await Entity.login({ email, password })
    // if (!user) {
    //   if (numAuditors > 1) {
    //     alert(`Email and/or password for auditor ${i} is invalid`)
    //   } else {
    //     alert("Email and/or password is invalid")
    //   }
    //   return
    // }
    // const newAuditors = numAuditors===1 ? [user] : i===0 ? [user, auditors[1]] : [auditors[0], user]
    // setAuditors(newAuditors)

    firebase.login(email, password)
      .then(async () => {
        const user = await Entity.getRow({ email })
        console.log('user')
        console.log(user)
        if (!user) {
          if (numAuditors > 1) {
            alert(`Email and/or password for auditor ${i} is invalid`)
          } else {
            alert("Email and/or password is invalid")
          }
          return
        }
        const newAuditors = numAuditors===1 ? [user] : i===0 ? [user, auditors[1]] : [auditors[0], user]
        setAuditors(newAuditors)
      })
      .catch(error => alert(error.message))
  }

  function removeAuditor(i) {
    const user = {}
    const newAuditors = numAuditors===1 ? [user] : i===0 ? [user, auditors[1]] : [auditors[0], user]
    setAuditors(newAuditors)
  }

  async function clickContinue() {
    if ((numAuditors===1 && !auditors[0].id) || (numAuditors===2 && !auditors[1].id)) {
      await alert("Please login with the required number of auditors")
      return
    }
    rememberAuditors(auditors)
    saveAuditors(inventoryId, auditors)
    history.push(continueUrl)
  }

  function clickBack(event) {
    event.preventDefault()
    history.push(backUrl)
  }

  return (
    <div className="inventoryBegin">

      <div className="info">
        <IconInfo />&nbsp;
        {numAuditors === 2
          ? <span>TWO AUDITORS ARE REQUIRED TO AUTHENTICATE</span>
          : <span>AN AUDITOR IS REQUIRED TO AUTHENTICATE</span>
        }
      </div>

      <table>
        <tbody>
          {auditors.map((auditor, i) => {
            return (
              <tr key={'auditor' + i}>
                <td>{numAuditors>1 && ("Auditor " + String(i+1) + ":")}</td>
                <td>
                  {auditor.id
                    ? auditor.name
                    : <input type="email" name="email" placeholder="Email" id={"email" + i} />
                  }
                </td>
                <td>
                  {auditor.id
                    ? "<" + auditor.email + ">"
                    : <input type="password" name="password" placeholder="Password" id={"password" + i} />
                  }
                </td>
                <td>
                  {auditor.id
                    ? <DeleteIcon className="auditor-logout" title="Logout Auditor" onClick={() => removeAuditor(i)} />
                    : <button onClick={e => clickLogin(i)}>Login</button>
                  }
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <div className="continue">
        <span onClick={clickBack} className="back-button"> 
          &#9664;&nbsp;Back&nbsp; 
        </span>
        <span onClick={clickContinue}>Continue&nbsp;&#9654;</span>
      </div>
    </div>
  )
})
