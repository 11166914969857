import React from 'react'
import { useGlobals } from '../../globals'
import { Link } from "react-router-dom"
import './styles.scss'


export default function OrderThankyou() {
  const globals = useGlobals()
  globals.breadcrumbs = "Order Request complete"
  return (
    <div className="OrderThankyou">
      <br/>
      Thank you for submitting your order request! <br/>
      <br/>
      An associate from our store will call you to finalize your order, 
      provide personalized service and collect payment within 24 hours!<br/>
      <br/>
      <br/>
      <Link className="continue" to="/order">
        <button>Back to Start</button>
      </Link>
      <br/>
    </div>
  )
}
