// this is the header for the inventory count page

import React from 'react'
import confirm from '../../../packages/confirm'
import Infobox from '../../../packages/Infobox'
import Item from '../../../models/item'
import FilterItems from '../FilterItems'
import Inventory from '../../../models/inventory'
import * as constants from '../../../constants'
import alertDialog from '../../../packages/alert'
import { useUser } from '../../../services/user'
import BarcodeIcon from '../../../assets/images/barcode3.png'
import {
  Scanner,
  openScanner,
  closeScanner,
} from '../../../parts/ScannerZbarWasm'
import './styles.scss'

const instructions = 'Select item to edit, or tap +/- to adjust count.'

export default function InventoryCountHeader({
  history,
  inventoryId,
  filter,
  setFilter,
  items,
  getCount,
}) {
  const [user] = useUser()

  //. optional auditor signin
  // const urlBack = `/inventory/${inventoryId}/begin`
  const urlBack = `/`
  const urlReview = `/inventory/${inventoryId}/preview`

  // make sure we close the scanner when leave page
  React.useEffect(() => {
    return () => closeScanner()
  }, [])

  // camera button clicked - open barcode scanner
  function clickCamera() {
    const { barcodeTypes, validPrefix } = constants.scanner?.items ?? {}
    console.log('call openScanner', { barcodeTypes, validPrefix })
    openScanner({
      barcodeTypes, //. handle this
      validPrefix,
      handleBarcode,
      handleItem,
      handleError,
    })
  }

  // return item if valid barcode, else null
  async function handleBarcode(barcode) {
    console.log('handleBarcode', barcode)

    // check if item is in db
    console.log('check if barcode is in db')
    const item = await Item.getRow({ barcode, user })
    console.log('item is', item)
    if (!item) {
      const msg = `Couldn't find barcode ${barcode} in database, or is not visible to you or your company. Please check that the scanner read the number correctly.`
      await alertDialog(msg)
      return null // keep scanning if no such item
    }

    // check if item is in inventory
    console.log('check if item is in inventory')
    const index = items.findIndex(i => i.id === item.id)
    if (index === -1) {
      const msg = `Item ${item.code} is not in this inventory.`
      await alertDialog(msg)
      return null // keep scanning if not in list
    }

    // got a valid item - will call handleItem
    console.log('got a valid item - will call handleItem')
    return item
  }

  // bring up item dialog
  async function handleItem(item) {
    console.log('handleItem', item)

    // get index in list
    const index = items.findIndex(i => i.id === item.id)

    // bring up item dialog
    console.log('calling getCount')
    getCount(index)
  }

  async function handleError(error) {
    console.log('handleError', error)
    await alertDialog(error)
  }

  async function clickReview(event) {
    event.preventDefault()
    if (
      await confirm(
        '',
        'Proceed to Inventory Review? This will gather the display log counts and include them in the store on-hand totals.',
        'Yes, continue',
        'No'
      )
    ) {
      await Inventory.review(inventoryId)
      history.push(urlReview)
    }
  }

  function clickBack(event) {
    event.preventDefault()
    history.push(urlBack)
  }

  return (
    <div className="inventory-count-header">
      <div className="top">
        <Infobox text={instructions} breakpoint={650} />
        <span className="buttons">
          <button
            className="camera-button"
            onClick={clickCamera}
            title="Scan a barcode"
          >
            <img src={BarcodeIcon} alt="" /> Scan Item
          </button>
        </span>
        <span className="buttons">
          <span className="back-button">
            <a onClick={clickBack} href={urlBack}>
              &#9664;&nbsp;Back
            </a>
          </span>
          <span className="review-button">
            <a onClick={clickReview} href={urlReview}>
              Review&nbsp;&#9654;
            </a>
          </span>
        </span>
      </div>
      <div className="bottom">
        <FilterItems
          title="Style"
          placeholder="Enter style to filter"
          filter={filter}
          setFilter={setFilter}
        />
      </div>
      <Scanner onClose={closeScanner} />
    </div>
  )
}
