import React from 'react'
import Select from 'react-select'
import Entity from '../../models/entity'
import EntityParent from '../../models/entityParent'
import Item from '../../models/item'
import Location from '../../models/location'
import Inventory from '../../models/inventory'
import * as grid from '../../packages/grid'
import { useUser } from '../../services/user'
import { openDialog, closeDialog } from '../../packages/react-async-dialog'
import alert from '../../packages/alert'
import confirm from '../../packages/confirm'
import './styles.scss'


const breadcrumbs = "Store Maintenance"
const className = "MaintainStores"
const entityTypeId = Entity.entityTypeStore


function RenderAction(params) {
  const [user] = useUser()
  async function clickDelete() {
    console.log(params)
    const row = params.data
    const entityId = row.id
    if (await confirm("Remove Store", "Are you sure you want to remove this store? This can only be done if there are no associated records in other tables.")) {
      //. move all this code into model? deleteStore method?
      // // check if an auditor
      // const count1 = await Inventory.countRows({ auditor1Id: entityId })
      // const count2 = await Inventory.countRows({ auditor2Id: entityId })
      // if (count1 || count2) {
      //   await alert("Employee is an auditor in the inventory records and can't be deleted.")
      //   return
      // }
      // check if in various tables
      if (await Location.countRows({ entityId })) {
        await alert("Store has a display or category and can't be deleted.")
        return
      }
      if (await Item.countRows({ sourceId: entityId })) {
        await alert("Store has a custom item and can't be deleted.")
        return
      }
      if (await Inventory.countRows({ storeId: entityId })) {
        await alert("Store has inventory records and can't be deleted.")
        return
      }
      // remove entityParent records
      const parentIds = row.parents.map(parent => parent.id)
      for (const parentId of parentIds) {
        await EntityParent.deleteRows({ entityId, parentId })
      }
      // delete main record
      if (await Entity.deleteRow({ id: entityId })) {
        // //. update user object
        // await updateUser()
        // remove from grid also
        const transaction = { remove: [row] }
        params.api.updateRowData(transaction)
      } else {
        await alert("Error removing store record " + entityId)
      }
    }
  }
  return <button onClick={clickDelete} disabled={!user.canEditStores}>Delete</button>
}


export default function MaintainStores(props) {

  const [user] = useUser(props.history)

  const editable = user.canEditStores

  const columnDefs = [
    { field: 'name', width: 300, editable },
    { field: 'number', width: 130, editable },
    { field: 'isActive', width: 120, editable, cellClass: 'center', cellRenderer: grid.renderCheckbox, cellRendererParams: { Model: Entity } },
    { field: 'email', width: 280, editable },
    { field: 'numAuditors', width: 150, editable, cellClass: 'center' },
    { field: 'action', width: 100, cellRendererFramework: RenderAction, editable: false },
  ]
  
  const gridOptions = {
    columnDefs,
  }
  
  const companyIds = user.companyIds

  const g = grid.Grid(props, {
    gridOptions,
    Model: Entity,
    variables: { entityTypeId, parentIds: companyIds },
    breadcrumbs,
    Header,
    className,
    //. editCallback,
  })

  if (!user) return null
  return g
}


function Header({ history }, gridApi, variables, setData) {

  const [user, updateUser] = useUser(history)

  async function clickAddStore() {
    if (!user.canEditStores) {
      await alert("No permission to add new stores.")
      return
    }
    const ret = await addStore(user) // defined below
    if (ret.ok) {
      const store = ret.store
      const transaction = { add: [store] }
      gridApi.updateRowData(transaction)
      // add extra info, update globals and localStorage
      await updateUser(user)
    }
  }

  return (
    <div className="header">
      <button onClick={clickAddStore}>Add Store...</button>
    </div>
  )
}


//. make getStore fn using getObject dialog

// show new store dialog
function addStore(user) {
  return new Promise((resolve, reject) => {
    openDialog(AddStore, { user, resolve })
  })
}


function AddStore({ user, resolve }) {

  const companyOptions = user.companies.map(company => ({ label: company.name, value: company.id }))

  // const [companyOptions, setCompanyOptions] = React.useState(null)
  const [companyId, setCompanyId] = React.useState(companyOptions[0].value)
  const [storeName, setStoreName] = React.useState(null)
  const [storeNumber, setStoreNumber] = React.useState(null)
  const [storeEmail, setStoreEmail] = React.useState(null)
  const [isStoreActive, setIsStoreActive] = React.useState(null)

  // React.useEffect(() => {
  //   async function fetchCompanies() {
  //     // const companies = await Entity.getRows({ entityTypeId: Entity.entityTypeTenant })
  //     // console.log(companies);
  //     // const companyOptions = companies.map(company => ({ label: company.name, value: company.id }))
  //     const companyOptions = user.companies.map(company => ({ label: company.name, value: company.id }))
  //     setCompanyOptions(companyOptions)
  //   }
  //   fetchCompanies()
  // }, [])

  function onSelectChange(selected) {
    if (selected === null) return setCompanyId(null)
    setCompanyId(selected.value)
  }

  const handleClickedOK = async () => {
    if (companyId === null || storeName === null) {
      return alert('Please fill in company and name')
    }
    const newStore = {
      name: storeName,
      entityTypeId: Entity.entityTypeStore,
      parentIds: [companyId],
      email: storeEmail,
      number: storeNumber,
      isActive: isStoreActive,
    }
    const store = await Entity.addRow(newStore)
    if (store) {
      console.log(store)
      closeDialog()
      resolve({ ok: true, store })
    } else {
      alert('ERROR')
    }
  }

  const handleClickedCancel = () => {
    closeDialog()
    resolve({ ok: false })
  }
  
  return (
    <div className='addStore'>
      <h3>Add New Store</h3>
      <div className="select-company">
        <p>Company</p>
        <Select
          defaultValue={companyOptions[0]}
          placeholder={'Select Company'}
          onChange={onSelectChange}
          isLoading={companyOptions === null}
          options={companyOptions}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              // primary: 'rgb(255, 220, 216)',
              // primary25: 'rgba(252, 220, 216, 0.7)',
              // primary50: 'rgba(252, 220, 216, 0.5)',
            },
          })}
        />
      </div>
      <div className="addStore-options">
        <div>
          <p>Name</p>
          <input type="text" autoFocus
            onChange={event => setStoreName(event.target.value)} />
        </div>
        <div>
          <p>Number</p>
          <input  type="text" onChange={event => setStoreNumber(event.target.value)} />
        </div>
        <div>
          <p>Email</p>
          <input type="text"  onChange={event => setStoreEmail(event.target.value)} />
        </div>
        <div>
          <p>Is Active?</p>
          <input type="checkbox" onClick={e => setIsStoreActive(e.target.checked)} />
        </div>
      </div>
      <div className="addStore-buttons">
        <button id="cancel" onClick={handleClickedCancel}>Cancel</button>
        <button id="ok" onClick={handleClickedOK}>OK</button>
      </div>
    </div>
  )
}
