
export function transformCategories(categories) {

  let rows = [...categories]

  // remove some
  rows = rows.filter(row => !row.name.includes("RETIRED"))
  rows = rows.filter(row => !row.name.includes("Last Chance"))

  // make sure charms are sorted first
  rows.forEach(row => {
    const lowercase = row.name.toLowerCase()
    if (lowercase.startsWith("tray")) {
      row.name = "aaaCharms: " + row.name.slice(8)
    } else if (row.name.startsWith("Charms")) {
      row.name = 'aaa' + row.name
    } else if (row.name.startsWith("RM Tray ")) {
      row.name = "aaaCharms: " + row.name.slice(12)
    } else if (row.name.startsWith("RM: ")) {
      row.name = "aaaCharms: " + row.name.slice(4)
    } else if (row.name.startsWith("ROSEMONT ")) {
      row.name = row.name.slice(9)
    } else if (row.name.startsWith("RM ")) {
      row.name = row.name.slice(3)
    }
    if (row.name.toLowerCase()==="gold count") {
      row.name = "Gold"
    }
  })

  // sort
  rows.sort((a,b)=>a.name.localeCompare(b.name))

  // remove 'aaa'
  rows.forEach(row => {
    if (row.name.startsWith("aaa")) {
      row.name = row.name.slice(3)
    }
  })

  return rows
}
