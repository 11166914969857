import React from 'react'
import Cards from '../../packages/cards2'
import Pagination from '../../packages/pagination'
import Item from '../../models/item'
import getItem from '../../parts/getItem'
import * as lib from '../../lib'
// import alert from '../../packages/alert'
import { useGlobals } from '../../globals'
import { useUser } from '../../services/user'
import Help from '../../packages/Help'
import Entity from '../../models/entity'
import './styles.scss'

const pageSize = 16
const breadcrumbs = 'Item Maintenance'

const helpMsg = (
  <div>
    Select Source to filter items by corporation or company.
    <br />
    <br />
    Enter a Code to filter items by code/style number. <br />
    <br />
    {/* Press and hold to delete item (with confirmation).  */}
    To create or edit items, see the Category Maintenance page.
    <br />
  </div>
)

export default function MaintainItems({ history }) {
  const globals = useGlobals()
  globals.breadcrumbs = breadcrumbs

  const [user] = useUser(history)

  const sources = user.sources
  const [sourceId, setSourceId] = React.useState(sources[0].id)
  const [cards, setCards] = React.useState(null) // null indicates loading
  const [filter, setFilter] = React.useState('')
  const [page, setPage] = React.useState(0)

  const variables = {
    sourceIds: [sourceId],
    code: '%' + filter + '%',
    offset: page * pageSize,
    limit: pageSize,
    isActive: null, // show active AND inactive items
  }

  // fetch items
  React.useEffect(() => {
    async function fetchItems() {
      const rows = await Item.getAllRows(variables)
      setCards(rows)
    }
    fetchItems()
  }, [variables])

  async function clickCard(event) {
    const index = Number(event.currentTarget.dataset.index) // get card's 'data-index' value
    const item = cards[index]
    const categories = await Item.getCategories({ id: item.id })
    const categoryNames = categories.map(category => category.name).join(', ')
    const readonly =
      !user.canEditItems ||
      (item.sourceId === Entity.corporationPandora &&
        !user.canEditCorporateCategories)
    const title = readonly ? 'View Item (no edit permission)' : 'Edit Item'
    const ret = await getItem(
      title,
      `Categories: ${categoryNames}`,
      item,
      user,
      readonly
    )
    if (ret.ok) {
      // refresh image
      if (ret.imageFile) {
        lib.showImageFile(ret.imageFile, `.ItemCard#item${item.id} img`)
      }
      const data = ret.item
      data.id = item.id
      if (await Item.updateRow(data)) {
        // update ui
        const newCards = [...cards]
        newCards[index] = { ...ret.item }
        setCards(newCards)
      }
    }
  }

  // delete item
  async function pressCard(event) {
    // const index = Number(event.currentTarget.dataset.index) // get card's 'data-index' value
    // const item = cards[index]
    // const readonly = !user.canEditItems || (item.sourceId===Entity.corporationPandora && !user.canEditCorporateCategories)
    // if (readonly) {
    //   await alert("No permission to remove item.")
    //   return
    // }
  }

  if (!user) return null
  return (
    <div className="MaintainItems">
      <Header
        sources={sources}
        sourceId={sourceId}
        setSourceId={setSourceId}
        filter={filter}
        setFilter={setFilter}
      />
      <Cards
        cards={cards}
        CardComponent={ItemCard} // defined below
        clickCard={clickCard}
        pressCard={pressCard}
      />
      <Pagination
        page={page}
        setPage={setPage}
        preventNext={cards === null || cards.length === 0}
      />
    </div>
  )
}

function ItemCard({ card, clickCard, pressCard, id }) {
  return (
    <div
      className="card ItemCard"
      onClick={clickCard}
      onPress={pressCard}
      id={'item' + id}
    >
      <img
        src={card.imageUrl}
        alt=""
        onError={e => {
          e.target.onerror = null
          e.target.src = 'images/image.png'
        }}
      />
      <span className="code">{card.code}</span>
      <span className="name">{card.name}</span>
      <span className="price">${card.price || 0}</span>
    </div>
  )
}

function Header({ filter, setFilter, sources, sourceId, setSourceId }) {
  async function changeFilter(event) {
    const value = event.target.value
    setFilter(value)
  }

  async function clearFilter() {
    const el = document.querySelector('#item-filter')
    el.value = ''
    setFilter('')
    el.focus()
  }

  async function handleKeyDown(event) {
    if (event.key === 'Escape') {
      clearFilter()
    }
  }

  return (
    <div className="header">
      <SourceDropdown
        sources={sources}
        sourceId={sourceId}
        setSourceId={setSourceId}
      />
      <span className="right">
        <span className="item-maint-search">Code:</span>
        <input
          id="item-filter"
          value={filter}
          onChange={changeFilter}
          onKeyDown={handleKeyDown}
        />
        <button onClick={clearFilter}>x</button>
        <Help msg={helpMsg} />
      </span>
    </div>
  )
}

// see also MaintainCategories
function SourceDropdown({ sources, sourceId, setSourceId }) {
  async function changeSource(event) {
    const sourceId = event.currentTarget.value
    setSourceId(sourceId)
  }
  return (
    <span>
      <span>Source:&nbsp;</span>
      <select
        name="source"
        id="category-source"
        value={sourceId}
        onChange={changeSource}
      >
        {sources.map(source => (
          <option value={source.id} key={source.id + source.name}>
            {source.name}
          </option>
        ))}
      </select>
    </span>
  )
}
