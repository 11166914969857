import React from 'react'
import * as grid from '../../packages/grid'
import Progress from '../../packages/progress'
import Entity from '../../models/entity'
import Location from '../../models/location'
import LocationItem from '../../models/locationItem'
import FileReader from '../../packages/file-reader'
import { useUser } from '../../services/user'
import alert from '../../packages/alert'
import confirm from '../../packages/confirm'
import Help from '../../packages/Help'
import getString from '../../packages/getString'
import getChoice from '../../packages/getChoice'
import { exportPlanograms } from '../../services/downloads'
import './styles.scss'


const breadcrumbs = "Category Maintenance"
const className = "MaintainCategories"

const generalHelpMsg = <div>
  <strong>Category Maintenance</strong><br/>
  <br/>
  Select a Source, which can be Pandora, a company, or a store. <br/>
  <br/>
  Double click on a name to rename category (if have editing permissions).<br/>
  <br/>
  Click Clone to copy a category and its item references to another source. <br/>
</div>

const importHelpMsg = <div>
  <strong>Import Planogram</strong><br/>
  <br/>
  This will import a planogram csv file to the currently selected Source. <br/>
  <br/>
  This button might be disabled if you do not have permission to import a planogram here.<br/>
  <br/>
  The planogram file is obtained from the planogram Excel file by deleting the first row (mostly blank) and saving as a csv file. The only columns it needs are: Tray Name, Position, Style, Description, Price, UPC - others could be removed if desired. It obtains the Color field by taking everything after the comma in the Description field. <br/>
  <br/>
  eg <br/>
  Tray Name,Position,Style,Description,Price,UPC<br/>
  Clips,1,798614C01,"Sparkling Sunburst, Clear CZ",35,5700302841794<br/>
  Clips,2,790210,sunburst,30,5700302003963<br/>
  Clips,3,798560C00,Beaded Hearts,35,5700302832723<br/>
  Clips,4,790267,beveled,30,5700302003970<br/>
  <br/>
  It first goes through and looks up the item styles - if there are any it doesn't have in the db it creates them - then it goes through and creates the categories, then it assigns the items to the categories. 
</div>


// render the action column with buttons
function RenderActions(params) {

  const [user] = useUser()
  const locationId = params.data && params.data.id
  const { sourceId } = params.context

  //. might need separate urls for view and edit? as with review/preview inventories
  function clickEdit() {
    // save selected source for return
    window.localStorage.setItem(`user${user.id}.lastSelectedSourceId`, String(sourceId))
    // goto edit page
    params.context.history.push("/edit-category/" + locationId)
  }
  
  async function clickDelete() {
    if (!user.canEditCategories) {
      await alert("No permission to delete categories.")
      return
    }
    if (sourceId===Entity.corporationPandora && !user.canEditCorporateCategories) {
      await alert("No permission to delete corporate categories.")
      return
    }
    if (await confirm("Delete Category", (
      <div>
        Are you sure you want to delete this category?<br/>
        <ul>
        <li>Items referenced by another category will NOT be deleted.</li>
        <li>Items NOT referenced by another category but used in an inventory will be moved to the Retired category.</li>
        <li>Items NOT referenced by another category and NOT used in an inventory will be deleted.</li>
        <li>Finally, any references to the category in the inventory records will be blanked out.</li>
        </ul>
        This cannot be undone. Continue?
      </div>
    ), "Yes, delete category")) {
      const progress = new Progress("Delete Category", "Deleting category...", false)
      if (await Location.deleteRow({ locationId }, msg => progress.setStatus(msg))) {
        // remove from grid also
        const transaction = { remove: [params.data] }
        params.api.updateRowData(transaction)
      }
      progress.close()
    }
  }

  async function clickClone() {
    if (!user.canCloneCategoriesAndItems) {
      await alert("No permission to clone categories.")
      return
    }
    let sourceOptions = user.sources
      .map(source => ({ label: source.name, value: source.id }))
    if (!user.canEditCorporateCategories) {
      sourceOptions = sourceOptions.filter(source => source.value !== Entity.corporationPandora)
    }
    const defaultValue = sourceOptions[0].value
    const ret = await getChoice("Copy Category", "Where would you like to copy the category and its item references? It will have '(copy)' appended to the name.", sourceOptions, defaultValue)
    if (ret.ok && ret.value) {
      const targetEntityId = Number(ret.value)
      const progress = new Progress("Copy Category", "Copying item references...", false)
      const newLocation = await Location.clone({ locationId, user }, targetEntityId, msg => progress.setStatus(msg))
      // add to grid also
      if (newLocation && targetEntityId === sourceId) {
        const itemCount = await LocationItem.countRows({ locationId: newLocation.id })
        const row = { ...newLocation, uniqueItems: itemCount }
        const transaction = { add: [row] }
        params.api.updateRowData(transaction)
      }
      progress.close()
    }
  }

  // not needed yet
  // async function clickMove() {
  //   if (!user.canMoveCategories) {
  //     await alert("No permission to move categories.")
  //     return
  //   }
  //   let sourceOptions = user.sources
  //     .map(source => ({ label: source.name, value: source.id }))
  //   if (!user.canEditCorporateCategories) {
  //     sourceOptions = sourceOptions.filter(source => source.value !== Entity.corporationPandora)
  //   }
  //   sourceOptions = sourceOptions.filter(source => source.value !== sourceId)
  //   const defaultValue = sourceOptions[0].value
  //   const ret = await getChoice("Move Category", "Where would you like to move the category?", sourceOptions, defaultValue)
  //   if (ret.ok && ret.value) {
  //     const targetEntityId = Number(ret.value)
  //     const ok = await Location.move({ locationId, user }, targetEntityId)
  //     // remove from grid
  //     if (ok) {
  //       const location = { id: locationId }
  //       const transaction = { remove: [location] }
  //       params.api.updateRowData(transaction)
  //     }
  //   }
  // }

  //. just say View if it's readonly for the user?
  // note: could disable buttons here but it's not very visible,
  // and giving an explanation why they can't do something is nicer.
  return (
    <span>
      <button onClick={clickEdit}>View / Edit</button>
      <button onClick={clickClone}>Clone...</button>
      {/* <button onClick={clickMove}>Move...</button> */}
      <button onClick={clickDelete}>Delete...</button>
    </span>
  )  
}



export default function MaintainCategories(props) {

  const [user] = useUser(props.history)

  // this controls what type of records are shown - active, inactive, or both (when null)
  const [isActive, setIsActive] = React.useState(true)

  const [sourceId, setSourceId] = React.useState(0)
  React.useEffect(() => {
    // reselect last selected source
    // stored with user, as otherwise could leak between logins
    if (user) {
      const sourceId = Number(window.localStorage.getItem(`user${user.id}.lastSelectedSourceId`) || "0")
        || (user && user.sources && user.sources[0] && user.sources[0].id) || 0
      setSourceId(sourceId)
    }
  }, []) //. if put `user` here it refreshes in endless loop - why?

  const editable = user.canEditCategories && !(sourceId===Entity.corporationPandora && !user.canEditCorporateCategories)
  const columnDefs = [
    { field: "id", width: 65, hide: true }, // need this for transactions (add/remove rows)
    { field: 'name', width: 450, editable },
    { field: 'isActive', width: 110, editable, cellClass: 'center', cellRenderer: grid.renderCheckbox, cellRendererParams: { Model: Location } },
    { field: 'uniqueItems', width: 150, cellClass: 'center', editable: false },
    { field: 'actions', width: 430, editable: false, cellRendererFramework: RenderActions },
  ]
  
  const gridOptions = {
    columnDefs,
  }
  
  const variables = { locationTypeId: Location.locationTypeCategory, entityId: sourceId, isActive }

  function Header(props, gridApi, variables, setData) {

    async function clickAddCategory() {
      if (!user.canEditCategories) {
        await alert("No permission to add categories.")
        return
      }
      if (sourceId===Entity.corporationPandora && !user.canEditCorporateCategories) {
        await alert("No permission to add corporate categories.")
        return
      }
      const ret = await getString("Add New Category", "Enter new category name")
      if (ret.ok) {
        const entityId = sourceId
        const data = { name: ret.value, locationTypeId: Location.locationTypeCategory, entityId }
        const category = await Location.addRow(data)
        if (category) {
          const source = await Entity.getRow({ id: sourceId })
          if (source) {
            category.entityName = source.name
          }
          const transaction = { add: [category] }
          gridApi.updateRowData(transaction)
          gridApi.ensureNodeVisible(category)
        }  
      }
    }

    async function changeSource(event) {
      // bug: forgot to call Number() - messes with equality checks
      const sourceId = Number(event.currentTarget.value)
      setSourceId(sourceId)
      variables.entityId = sourceId
      const rows = await Location.getRows(variables)
      gridApi.setRowData(rows)
    }

    async function parsePlanogramCsv(csv) {
      console.log(csv.slice(0,100))
      const ret = await getString("Import Planograms", "Enter optional prefix for category names (e.g. 'SS20: ', or leave blank):")
      if (ret.ok) {
        const categoryPrefix = ret.value
        if (await Location.importPlanograms(csv, user, sourceId, categoryPrefix)) {
          const rows = await Location.getRows(variables)
          gridApi.setRowData(rows)
        }
      }
    }

    console.log(user)
    const userParentIds = user.parents.map(parent => parent.id)
    const canImportPlanograms = user &&
      (user.canImportPlanograms || userParentIds.includes(sourceId))

    const status = isActive===true ? 'active' : isActive===false ? 'inactive' : 'both'

    async function changeStatus(e) {
      console.log(e, e.currentTarget)
      const status = e.currentTarget.value
      const isActive = status==='active' ? true : status==='inactive' ? false : null
      setIsActive(isActive)
      variables.isActive = isActive
      const rows = await Location.getRows(variables)
      gridApi.setRowData(rows)
    }

    async function clickExportPlanograms() {
      const source = user.sources.find(source => source.id===sourceId)
      //. would rather do setRows in source change, then use rows here
      variables.entityId = sourceId
      variables.isActive = isActive
      const rows = await Location.getRows(variables)
      await exportPlanograms(source, rows)
    }

    if (!user) return null
    return (
      <div className="header">
        <span className="left">
          <SourceDropdown sources={user.sources} sourceId={sourceId} changeSource={changeSource} />
          <span className="show-status">
            <span>Show&nbsp;</span>
            <select name="show-status" id="show-status" value={status} onChange={changeStatus}>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
              <option value="both">Both</option>
            </select>
          </span>
        </span>
        <span className="right">
          {canImportPlanograms && <>
            <FileReader
              label="Import Planograms..."
              filetypes=".csv"
              parser={parsePlanogramCsv} // callback that starts the import
              disabled={!canImportPlanograms}
            />
            <Help msg={importHelpMsg} />
          </>}
          <button onClick={clickExportPlanograms}>
            Export Planograms...
          </button>
          <button onClick={clickAddCategory}>Add New Category...</button>
          <Help msg={generalHelpMsg} />
        </span>
      </div>
    )
  }


  // pass sourceId through grid context
  const props2 = { ...props, sourceId }
  
  const g = grid.Grid(props2, { 
    gridOptions, 
    Model: Location, 
    variables, 
    breadcrumbs,
    className,
    Header,
  })

  if (!user) return null
  return g
}


// see also MaintainItems
function SourceDropdown({ sources, sourceId, changeSource }) {
  return (
    <span>
      <span>Source&nbsp;</span>
      <select name="source" id="category-source" value={sourceId} onChange={changeSource}>
        {sources.map(source => <option value={source.id} key={source.id + source.name}>
          {source.name}
        </option>)}
      </select>
    </span>
  )
}
