// global constants
// usage:
//   import * as constants from '../constants'
//   constants.environment // => 'development' eg

// choose db based on window url
const hostname = window.location.hostname // eg "tallieo-test.web.app" or "localhost"
const isProduction = hostname.startsWith('tallieo.')
// const isProduction = true //...
export const environment = isProduction ? 'production' : 'development'
console.log(hostname, environment)

// graphql/db
// these are the hasura graphql endpoints, which interface with the dbs in the postgres instance.
const graphqlEndpoints = {
  development: 'https://api-test.tallieo.com/v1/graphql', // test db - testdb
  production: 'https://api.tallieo.com/v1/graphql', // live db - defaultdb
}
export const graphqlEndpoint = graphqlEndpoints[environment]
console.log(graphqlEndpoint)

// hasura
//. remove this to .env and change it
export const hasuraAdminSecret = 'AKd3uf' // for both dbs - set in docker-compose.yaml

// images
const baseImageUrl =
  'https://tallieo.nyc3.digitaloceanspaces.com/images/pandora/'
export const baseImageUrlSmall = baseImageUrl + 'small/'
export const baseImageUrlLarge = baseImageUrl + 'large/'
export const itemImageSuffix = '?v3' // increment this to bypass cached images

// feature flags
export const show = {
  // plusMinusCorners: true,
  // showScanner: true, //. use this
}

// barcode scanner
export const scanner = {
  items: {
    barcodeTypes: ['ean13'], // barcode types to look for
    // validPrefix: '5700302', // will ignore other barcodes - no longer needed
  },
  // badges: {
  //   barcodeTypes: ['qr'], // barcode types to look for
  // },
  // searchboxSize: 1, // 100%
}

// cards
export const longPressDelay = '750' // ms - for card long press events

// accounts
export const defaultPassword = 'password' // for new users
