// apollo client is a graphql client
// it interacts with hasura, which wraps the postgres database by
// translating graphql queries to sql.


import ApolloClient from "apollo-boost"
// import ApolloClient from "apollo-client"
// import { WebSocketLink } from 'apollo-link-ws'
// import { HttpLink } from 'apollo-link-http'
// import { split } from 'apollo-link'
// import { getMainDefinition } from 'apollo-utilities'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'

import * as constants from '../constants'


// if just using plain queries and mutations can use apollo-boost, as here -
const client = new ApolloClient({
  uri: constants.graphqlEndpoint,
  headers: {
    "x-hasura-admin-secret": constants.hasuraAdminSecret,
  },
  // override the object id for some tables
  cache: new InMemoryCache({
    dataIdFromObject: object => {
      switch (object.__typename) {
        // case 'locationItem': return `locationItem:${object.locationId}:${object.itemId}`;
        case 'locationItem': {
          const dataId = `locationItem:${object.locationId}:${object.itemId}`
          // console.log(dataId)
          return dataId
        }
        default: return defaultDataIdFromObject(object); // fall back to default
      }
    }
  }),
})

export default client

// but if using subscription queries also need to do all this stuff, 
// and use "apollo-client" instead of "apollo-boost", above...


// const httpLink = new HttpLink({
//   uri: constants.graphqlEndpoint,
// })

// // Create a WebSocket link:
// const wsLink = new WebSocketLink({
//   uri: constants.graphqlEndpointWebsocket,
//   options: {
//     reconnect: true
//       connectionParams: {
//         headers: {
//           "x-hasura-admin-secret": constants.hasuraAdminSecret,
//         }
//       }
//   }
// })


// // using the ability to split links, you can send data to each link
// // depending on what kind of operation is being sent
// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const { kind, operation } = getMainDefinition(query)
//     return kind === 'OperationDefinition' && operation === 'subscription'
//   },
//   wsLink,
//   httpLink,
// )

// // Instantiate client
// const client = new ApolloClient({
//   link,
//   cache: new InMemoryCache()
// })

// export default client
