import React from 'react'
import { gql } from 'apollo-boost'
import Papa from 'papaparse'
import apolloClient from '../../services/apolloClient'
import Inventory from '../inventory'
import LocationItem from '../locationItem'
import InventoryItem from '../inventoryItem'
import Item from '../item'
import alert from '../../packages/alert'
import confirm from '../../packages/confirm'
import Progress from '../../packages/progress'
import * as constants from '../../constants'

export default class Location {
  // enums
  static locationTypeDisplay = 1 // display cases
  // static locationTypeTray = 2 //. Custom
  static locationTypeCategory = 3 // trays / categories

  static locationIdRetired = 1 // the Retired category

  // variables is { locationTypeId, entityId, id, isActive }
  static async getRows(variables, cached = false) {
    const query = gql`
      query GetLocations(
        $locationTypeId: Int
        $entityId: Int
        $id: Int
        $isActive: Boolean
      ) {
        __typename
        location(
          where: {
            locationTypeId: { _eq: $locationTypeId }
            entityId: { _eq: $entityId }
            id: { _eq: $id }
            isActive: { _eq: $isActive }
          }
          order_by: { name: asc }
        ) {
          id
          name
          number
          isActive
          position
          locationTypeId
          exampleItemId
          item {
            code
          }
          locationItems_aggregate {
            aggregate {
              count(distinct: true)
              sum {
                currentCount
              }
            }
          }
          entity {
            id
            name
          }
        }
      }
    `
    // note: we have to specify network-only query so it doesn't read from the cache -
    // otherwise when user hits Back button they would see stale data.
    const fetchPolicy = cached ? 'cache-first' : 'network-only'
    console.log('location.getrows', variables)
    const { data } = await apolloClient.query({ query, variables, fetchPolicy })
    const rows = data.location // location is the table name
    rows.forEach(row => flattenRow(row))
    return rows
  }

  static async getRow({ id }) {
    const rows = await Location.getRows({ id })
    if (rows && rows.length === 1) {
      const location = rows[0]
      return location
    }
    return null
  }

  static async updateRow(data, changes) {
    // merge existing and new values
    // const { id, name, number, isActive, entityId } = data
    // const variables = { id, name, number, isActive, entityId, ...changes }
    const { id, name, number, isActive } = data
    const variables = { id, name, number, isActive, ...changes }
    // remove non-db values (cause graphql error) //. better way?
    delete variables.__typename
    delete variables.entityParents
    delete variables.parents
    try {
      // mutation UpdateLocation($id: Int!, $isActive: Boolean, $name: String, $number: String, $entityId: Int) {
      // update_location(_set: {name: $name, number: $number, isActive: $isActive, entityId: $entityId}, where: {id: {_eq: $id}}) {
      const mutation = gql`
        mutation UpdateLocation(
          $id: Int!
          $isActive: Boolean
          $name: String
          $number: String
        ) {
          __typename
          update_location(
            _set: { name: $name, number: $number, isActive: $isActive }
            where: { id: { _eq: $id } }
          ) {
            returning {
              id
              name
              number
            }
          }
        }
      `
      console.log('location.updateRow', variables)
      const ret = await apolloClient.mutate({ mutation, variables })
      return ret
    } catch (e) {
      alert(e.message)
    }
  }

  static async addRow({
    name,
    number,
    locationTypeId,
    entityId,
    isActive = true,
  }) {
    try {
      const action = 'insert_location'
      const mutation = gql`
        mutation AddRow($name: String!, $number: String, $locationTypeId: Int!, $entityId: Int!, $isActive: Boolean) {
          __typename
          ${action}(objects: {name: $name, number: $number, locationTypeId: $locationTypeId, entityId: $entityId, isActive: $isActive}) {
            returning { id }
          }
        }
      `
      const variables = { name, number, locationTypeId, entityId, isActive }
      const ret = await apolloClient.mutate({ mutation, variables })
      console.log('ret', ret)
      if (ret && ret.data && ret.data[action] && ret.data[action].returning) {
        const row = ret.data[action].returning[0]
        console.log('row', row)
        const location = { ...variables, id: row.id }
        console.log('location', location)
        return location
      }
      throw new Error('Should have gotten one record back for new row')
    } catch (e) {
      alert(e.message)
    }
  }

  static async countRows(variables) {
    const rows = await Location.getRows(variables)
    return rows.length
  }

  // called by MaintainCategories and ShowDisplays
  static async deleteRow({ locationId }, updateStatus = msg => {}) {
    try {
      // get all the items referenced by this category
      const items = await LocationItem.getRows({ locationId })
      // deal with each item -
      // Items referenced by another category will NOT be deleted.
      // Items used in an inventory AND not referenced by any other category will be moved to the Retired category.
      // Items not used in an inventory AND not referenced by any other category will be deleted.
      for (const item of items) {
        console.log('rm', item)
        updateStatus(`Removing item reference ${item.code}: ${item.name}...`)
        const itemId = item.id

        const categoryRefs = await LocationItem.getRows({ itemId })
        const isReferencedByOtherCategory = categoryRefs.some(
          ref => ref.locationId !== locationId
        )
        const isUsedInInventory =
          (await InventoryItem.countRows({ itemId })) > 0
        console.log(isReferencedByOtherCategory, isUsedInInventory)
        if (isReferencedByOtherCategory) {
          console.log('remove reference', itemId)
          // remove this reference but keep the item
          await LocationItem.deleteRows({ locationId, itemId })
        } else {
          if (isUsedInInventory) {
            console.log('move to retired', itemId)
            // move item to Retired category
            const data = await LocationItem.getRow({ locationId, itemId })
            await LocationItem.updateRow(data, {
              locationId: Location.locationIdRetired,
            })
          } else {
            // remove this reference and delete item
            console.log('remove and delete', itemId)
            await LocationItem.deleteRows({ locationId, itemId })
            await Item.deleteRow({ itemId })
          }
        }
      }

      // now remove any references from the inventory table (set to null)
      console.log('clear category references', locationId)
      if (await Inventory.clearCategoryReferences({ locationId })) {
        // now the main record
        const action = 'delete_location'
        const mutation = gql`
          mutation DeleteRow($locationId: Int!) {
            __typename
            ${action}(where: {id: {_eq: $locationId}}) { affected_rows }
          }
        `
        const variables = { locationId }
        console.log(action, variables)
        const ret = await apolloClient.mutate({ mutation, variables })
        // return ret && ret.data && ret.data[action] && (ret.data[action].affected_rows > 0)
        // return ret && ret.data && ret.data[action] && ret.data[action].affected_rows // don't do this - could be zero = falsy
        return ret
      }
    } catch (e) {
      alert(e.message)
    }
  }

  // static async find(storeId, itemId) {
  //   // first look for store custom location
  //   // then for corporate category
  // }

  // get active locations (displays/categories) for the given entities
  static async getLocations({ entityIds }, cached = false) {
    const query = gql`
      query GetLocations($entityIds: [Int!], $locationTypeId: Int) {
        __typename
        location(
          where: {
            entityId: { _in: $entityIds }
            locationTypeId: { _eq: $locationTypeId }
            isActive: { _eq: true }
          }
        ) {
          id
          name
          locationItems {
            itemId
          }
        }
      }
    `
    const variables = {
      entityIds,
      locationTypeId: Location.locationTypeCategory,
    }
    const fetchPolicy = cached ? 'cache-first' : 'network-only'
    const { data } = await apolloClient.query({ query, variables, fetchPolicy })
    const locations = data.location // location is the table name
    return locations
  }

  /**
   * get maps from item codes/styles to categories and items.
   * include all categories associated with user's corporations, companies,
   * and a given store.
   * note that store categories take precedence over company categories,
   * which do so over corporate categories.
   * returns { mapCodeToCategories, mapCodeToItems }
   * this is used by ivr import, where the array of cats and items are
   * condensed down to one of each, with errors/warnings produced as needed.
   */
  static async getCodeMaps({ user, store }, mapIdToItem) {
    const mapCodeToCategories = {} // {'123x':[{},{}]}
    const mapCodeToItems = {} // {'123x':[]}
    // add categories for diff levels.
    // order of this array is important - put pandora first, then company, then store categories.
    // that way, store categories can override company and pandora categories, eg.
    // note just do the one store
    const entityIdArray = [user.corporateIds, user.companyIds, [store.id]]
    const cached = false
    for (const entityIds of entityIdArray) {
      const levelCategories = {}
      const levelItems = {}
      // get categories and their items avail for this level
      const locations = await Location.getLocations({ entityIds }, cached)
      for (const location of locations) {
        for (const locationItem of location.locationItems) {
          const itemId = locationItem.itemId
          const item = mapIdToItem[itemId]
          if (!item) {
            // what does this mean? what causes this?
            // 2022-03-28 this happened when had merged A001 from pandora into
            // todd bettman's by mistake, so it wasn't visible to Metzger when they did
            // an ivr import.
            // added the continue stmt and it worked okay running locally.
            // so - if this happens again, check that the item is visible to the store doing
            // the importing.
            console.error(`mapIdToItem missing item`, itemId)
            // continue //. added 2022-03-28
            alert(
              `Error: itemId ${itemId} not visible - please have a company admin import this IVR, and/or contact Tallieo support.`
            )
          }
          const code = item.code
          if (levelCategories[code]) {
            levelCategories[code].push(location)
          } else {
            levelCategories[code] = [location]
          }
          if (levelItems[code]) {
            levelItems[code].push(item)
          } else {
            levelItems[code] = [item]
          }
        }
      }
      // go through all codes and set to mapCode* arrays.
      // note that we replace the entire array at once, so lower level mappings
      // override higher level ones.
      for (const code of Object.keys(levelCategories)) {
        mapCodeToCategories[code] = levelCategories[code]
      }
      for (const code of Object.keys(levelItems)) {
        mapCodeToItems[code] = levelItems[code]
      }
    }
    return { mapCodeToCategories, mapCodeToItems }
  }

  static async clone(
    { locationId, user },
    targetEntityId,
    updateStatus = msg => {}
  ) {
    try {
      // make a new location record with entity=targetid
      const oldLocation = await Location.getRow({ id: locationId })
      const name = oldLocation.name + ' (copy)'
      const locationTypeId = Location.locationTypeCategory
      const entityId = targetEntityId
      const newLocation = await Location.addRow({
        name,
        locationTypeId,
        entityId,
        isActive: true,
      })

      // iterate over locationItem records, make copy of each item for new location
      const rows = await LocationItem.getRows({ locationId })
      let i = 1
      const imax = rows.length
      for (const row of rows) {
        updateStatus(`Copying item ${i} of ${imax} - ${row.code}: ${row.name}`)
        // copy reference only
        await LocationItem.addRow({
          locationId: newLocation.id,
          itemId: row.itemId,
          position: row.position,
        })
        // copy item and add reference
        // const clonedItemId = await Item.clone({ id: row.itemId, user }, targetEntityId)
        // await LocationItem.addRow({ locationId: newLocation.id, itemId: clonedItemId, position: row.position })
        i += 1
      }

      // return new location record
      return newLocation
    } catch (e) {
      alert(e.message)
    }
  }

  // not needed yet
  // static async move({ locationId, user }, targetEntityId) {
  //   try {
  //     // change this location's entityId to targetEntityId
  //     const location = await Location.getRow({ id: locationId })
  //     await Location.updateRow(location, { entityId: targetEntityId })
  //   } catch(e) {
  //     alert(e.message)
  //     return false
  //   }
  //   return true
  // }

  // import a planogram
  // returns true if completed okay, else false
  static async importPlanograms(csv, user, sourceId, categoryPrefix) {
    const itemSourceId = sourceId // eg Entity.corporationPandora
    const locationEntityId = sourceId // eg Entity.corporationPandora

    const progress = new Progress(
      'Import Planograms',
      'Importing planograms',
      false
    )

    progress.setStatus('Step 1/5: Get CSV data...')
    const result = Papa.parse(csv, { header: true, skipEmptyLines: true })

    // just keep some columns
    let rows = result.data.map(row => ({
      trayName: categoryPrefix + row['Tray Name'].trim(),
      position: Number(row['Position']),
      code: row['Style'] || row['Style '],
      description: row['Description'] || row['Description '] || '(unknown)',
      price: Number(row['Retail Price'] || row['Price'] || 0),
      cost: Number(row['Wholesale Cost'] || row['Cost'] || 0),
      barcode: row['UPC'],
    }))
    console.log(rows.slice(0, 10))

    // filter out empty rows
    rows = rows.filter(row => !!row.code)

    // split description to get color
    rows.forEach(row => {
      const [name, color] = row.description.split(',').map(s => s.trim())
      row.name = name
      row.color = color
    })
    console.log({ rows })

    // lookup items
    progress.setStatus('Step 2/5: Lookup items...')
    const duplicateCodes = {}
    const mapCodeToItem = await Item.getMapCodeToItemSimple(
      { user },
      duplicateCodes
    )
    const codes = rows
      .map(row => (duplicateCodes[row.code] ? row.code : null))
      .filter(code => !!code)
    if (
      !(await confirm(
        `Warning: the following codes have duplicate items visible - please check them before or after doing import:` +
          codes.join(', ')
      ))
    ) {
      progress.close()
      return false
    }

    let i = 1
    let nMissing = 0
    for (const row of rows) {
      progress.setSubStatus(`${i} of ${rows.length}: ${row.code}`)
      const item = mapCodeToItem[row.code] // can be undefined
      row.item = item
      i += 1
      if (!item) nMissing += 1
    }

    // create new items
    progress.setStatus('Step 3/5: Create missing items...')
    const newCodes = [] // track for message at end
    i = 1
    for (const row of rows) {
      if (!row.item) {
        progress.setSubStatus(`${i} of ${nMissing}: ${row.code}`)
        const data = {
          name: row.name,
          sourceId: itemSourceId,
          color: row.color,
          barcode: row.barcode,
          price: row.price,
          cost: row.cost,
          isActive: true,
          code: row.code,
        }
        const id = await Item.addRow(data)
        row.item = { ...data, id }
        newCodes.push(row.code)
        i += 1
      }
    }

    // create categories
    progress.setStatus('Step 4/5: Create categories...')
    const newCategories = {}
    for (const row of rows) {
      const category = newCategories[row.trayName]
      if (category) {
        row.category = category
      } else {
        // create category
        progress.setSubStatus(row.trayName)
        const locationTypeId = Location.locationTypeCategory
        const category = await Location.addRow({
          name: row.trayName,
          locationTypeId,
          entityId: locationEntityId,
          isActive: true,
        })
        row.category = category
        newCategories[row.trayName] = category
      }
    }

    // create locationItem records
    progress.setStatus('Step 5/5: Add items to categories...')
    i = 1
    for (const row of rows) {
      progress.setSubStatus(`${i} of ${rows.length}`)
      const locationId = row.category.id
      const itemId = row.item.id
      const position = row.position
      await LocationItem.addRow({ locationId, itemId, position })
      i += 1
    }

    progress.close()

    const msg = `Finished importing. Items created: ${newCodes.join(
      ', '
    )}. Categories created: ${Object.keys(newCategories).join('; ')}.`
    await alert(msg)
    return true
  }
}

function flattenRow(row) {
  row.uniqueItems = row.locationItems_aggregate.aggregate.count
  row.sumPieces = row.locationItems_aggregate.aggregate.sum.currentCount
  row.entityName = row.entity.name
  row.exampleImageUrl =
    row.item &&
    constants.baseImageUrlSmall +
      row.item.code +
      '.jpg' +
      constants.itemImageSuffix
}
