import React from 'react'
import { useGlobals } from '../../globals'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/pandora_transparent.png'
import './styles.scss'
import useAppName from '../../hooks/useAppName'

// holiday promotion
const now = new Date().getTime()
const start = new Date('2021-12-09').getTime()
const end = new Date('2021-12-13').getTime()
const showPromotion = now >= start && now <= end
const promotion = (
  <>
    <br />
    <hr />
    <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
      Free Bangle Promotion December 9th-13th for orders $150 or more
    </span>
    <br />
    While supplies last. Store associate will include your free bangle when they
    call you to collect payment information.
    <br />
    <hr />
  </>
)

export default function OrderStart() {
  const appName = useAppName() // 'order' or 'wishlist'
  const globals = useGlobals()
  // if (appName === "order") {
  //   // window.location = "/#/wishlist"
  //   window.location = "https://www.memorablecharms.com/"
  //   return null
  // }
  globals.breadcrumbs = appName === 'order' ? 'Order Request' : 'Wishlist'
  const text =
    appName === 'order' ? (
      <>
        <strong>Please fill out the following order request form.</strong>
        <br />
        {showPromotion && promotion}
        <br />
        Once your order request is submitted, an associate from your Pandora
        store will call you to finalize your order, provide personalized service
        and collect payment within 24 hours!
        <br />
        <br />
        For your convenience you can:
        <br />
        <br />
        • Pick your order up next day in store
        <br />
        <br />
        or
        <br />
        <br />
        • Ship straight to your door! Free shipping on orders $49 or more
        <br />
        <br />
        <br />
      </>
    ) : (
      <>
        Create your Pandora Wishlist while you wait in line; once it's your
        turn, show your associate in-store for faster checkout!
        <br />
        <br />
        <br />
      </>
    )
  return (
    <div className="OrderStart">
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      {text}
      <div className="continue">
        <Link to={`/${appName}/stores/`}>
          <button>Continue</button>
        </Link>
      </div>
      <br />
    </div>
  )
}
