// ask user for a string value

import React from 'react'
import { openDialog, closeDialog } from '../react-async-dialog'
import './styles.scss'


export default function getString(title, message, defaultValue) {
  return new Promise(resolve => {
    openDialog(GetString, { title, message, defaultValue, resolve })
  })
}


function GetString({ title, message, defaultValue, resolve }) {
  const handleClickedOK = () => {
    const el = document.getElementById("getString-input")
    closeDialog()
    resolve({ ok: true, value: el.value })
  }
  const handleClickedCancel = () => {
    closeDialog()
    resolve({ ok: false })
  }
  function handleKeyPress(evt) {
    if (evt.key === "Enter") {
      handleClickedOK()
    }
  }
  return (
    <div className='getString'>
      <h3>{title}</h3>
      <p>{message}</p>
      <div>
        <input 
          autoFocus 
          autoComplete="off" 
          type="text" 
          id="getString-input" 
          onKeyPress={handleKeyPress} 
          defaultValue={defaultValue}
        />
      </div>
      <div className="getString-buttons">
        <button id="cancel" onClick={handleClickedCancel}>Cancel</button>
        <button id="ok" onClick={handleClickedOK}>OK</button>
      </div>
    </div>
  )
}
