// feedback via Google Forms

import React from 'react'
import { useGlobals } from '../../globals'
import './styles.scss'

export default function() {
  const globals = useGlobals()
  globals.breadcrumbs = "Feedback"
  return (
    <div className="feedback">
      <iframe title="feedback" src="https://docs.google.com/forms/d/e/1FAIpQLSdQVwAoie31ltXnCaylg2IwIOkS4oXLVoGENyy4amok6IZpXg/viewform?embedded=true" width="400" height="550" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  )
}
