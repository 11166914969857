// main page showing current inventories and import ivr button

import React from 'react'
import moment from 'moment'
import Cards from '../../packages/cards'
import Inventory from '../../models/inventory'
import importInventory from '../../models/inventory/importInventory'
import * as lib from '../../lib'
import { useGlobals } from '../../globals'
import { useUser } from '../../services/user'
import FileReader from '../../packages/file-reader'
import alert from '../../packages/alert'
import './styles.scss'


const statusColors = {
  Unstaged: 'gray',
  Staged: 'yellowgreen',
  Started: 'orange',
  Paused: 'purple',
  Completed: 'green',
}


export default function InventoryCount({ history }) {

  // clear header
  const globals = useGlobals()
  globals.breadcrumbs = ""
  
  const [user] = useUser(history) // redirects if not logged in
  
  const [inventories, setInventories] = React.useState([])
  const [storeInventories, setStoreInventories] = React.useState([])
  const [inventoryRange, setInventoryRange] = React.useState("today")

  // fetch inventories
  React.useEffect(() => {
    if (user) {
      fetchInventories()
    }
  }, [inventoryRange])

  //. catch and display errors and timeouts
  async function fetchInventories() {
    const storeIds = (user && user.storeIds) || [] // eg [184,189,192]
    const filterType = inventoryRange === "today" ? 'today' : 'past'
    const variables = { storeIds, filterType }
    let inventories = await Inventory.getRows(variables) // get data
    inventories.sort((a,b) => a.categoryName < b.categoryName ? -1 : 1)
    // normal users can't see unstaged inventories
    if (!user.canViewUnstagedInventories) {
      inventories = inventories.filter(inventory => inventory.statusId !== Inventory.statusUnstaged)
    }
    setInventories(inventories)
  }

  React.useEffect(() => {
    const storeInventories = lib.groupby(inventories, 'storeName')
    setStoreInventories(storeInventories)
  }, [inventories])

  // import ivr
  // called when user clicks on Import Inventory Counts button
  async function parseCsv(s) {
    console.log(s.slice(0,100))
    // await Inventory.importInventoryCounts(s, user) // does the import
    await importInventory(s, user) // does the import
    await fetchInventories()
  }

  async function clickCard(event) {
    if (!user.canCountStagedInventories) {
      await alert("No permission to update staged inventories. Ask admin to add 'Employee' permission.")
      return
    }
    const inventoryId = event.currentTarget.id
    const inventory = await Inventory.getRow({ inventoryId })
    // if inventory is finished, goto the review page
    if (inventory.endTimestamp && inventory.statusId === Inventory.statusCompleted) {
      history.push(`/inventory/${inventoryId}/review`)
    } else if (inventory.statusId === Inventory.statusUnstaged) {
      await alert("Inventory must be staged before opening - click the start button (black triangle).")
    } else {
      // otherwise go to the auditor login page
      history.push(`/inventory/${inventoryId}/begin`)
    }
  }

  async function clickDelete(event, inventoryId) {
    event.stopPropagation()
    if (window.confirm("Are you sure you want to remove this category?")) {
      if (await Inventory.deleteRow({ inventoryId })) {
        setInventories(inventories.filter(inventory => inventory.id !== inventoryId))
      }
    }
  }

  // stage the inventory
  async function clickStage(event, inventoryId) {
    event.stopPropagation()
    if (!user.canStageInventories) {
      await alert("No permission to stage inventories.")
      return
    }
    document.body.style.cursor = 'wait' // show hourglass
    if (await Inventory.stage(inventoryId)) {
      await fetchInventories()
    }
    document.body.style.cursor = 'default'
  }

  function selectInventoryRange(event) {
    const value = event.currentTarget.value
    setInventoryRange(value)
  }

  const date = lib.formatDateLong(new Date())
  const storeNames = Object.keys(storeInventories)
  const showUploadButton = user && user.canImportIVR
  console.log(storeNames)

  if (!user) return null
  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <div className="left">
          <div className="instructions">
            Inventories for:&nbsp;
            <select name="range" id="inventory-range" value={inventoryRange} onChange={selectInventoryRange}>
              <option value="today">Today - {date}&nbsp;</option>
              <option value="past">Past / unfinished</option>
            </select>
          </div>
        </div>
        {showUploadButton && <div className="upload-file">
          <FileReader
            label="Import inventory counts..."
            filetypes=".csv"
            prompt=""
            showFilename={false}
            parser={parseCsv} // callback that starts the ivr import
          />
          {/* <button onClick={clickClearAll}>Clear All...</button> */}
        </div>}
      </div>

      {inventories.length === 0 && inventoryRange==="today" && <div><br/>No inventories currently staged</div>}
      {storeNames.map(storeName => {
        const cards = storeInventories[storeName]
        const header = (
          <div className="store-header">
            <div className="name">{storeName}</div>
          </div>
        )
        return (
          <div className="store" key={storeName}>
            {header}
            <Cards
              Card={InventoryCard} // defined below
              cards={cards}
              clickCard={clickCard}
              handlers={{ clickDelete, clickStage }}
            />
          </div>
        )
      })}
    </div>
  )
}


function InventoryCard({ card, handlers }) {
  const [user] = useUser()
  const isUnstaged = card.statusId === Inventory.statusUnstaged
  const statusIcon = card.endTimestamp ? <span>&#10004;</span> : null // checkmark
  const statusName = Inventory.getStatusName(card.statusId)
  const statusColor = statusColors[statusName]
  const dimmed = false //Math.random() > 0.7
  return (
    <div className={"card InventoryCount" + (dimmed ? " dimmed" : "")} >
      <span className="card-category">{card.categoryName}</span>
      <span className="card-store">{card.storeName}</span>
      <span className={"card-status " + statusColor}>{statusIcon} {statusName}</span>
      {user.isAdmin && <span className="card-play">
        <button disabled={!isUnstaged} title="Stage inventory" onClick={e=>handlers.clickStage(e, card.id)}>&#9654;</button>
      </span>}
      {/* {user.isAdmin && <span className="card-delete">
        <button title="Delete inventory" onClick={e=>handlers.clickDelete(e, card.id)}>x</button>
      </span>} */}
      <span className="card-start">
        Imported {moment(card.startTimestamp).format('LT')}
      </span>
    </div>
  )
}
