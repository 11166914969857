import React from 'react'
import Papa from 'papaparse'
import moment from 'moment'
import Inventory from './index'
import InventoryItem from '../inventoryItem'
import Item from '../item'
import Location from '../location'
import LocationItem from '../locationItem'
import alert from '../../packages/alert'
import confirm from '../../packages/confirm'
import Progress from '../../packages/progress'
import getChoice from '../../packages/getChoice'
import * as lib from '../../lib'

// import inventory value report (ivr) file.
// called from Dashboard/InventoryCount with contents of csv-ish file.
// this handles two file formats now - ivr csv from kwi and edge csv.
// ivr = inventory value report - dump of items from kwi POS system.
// ivr csv needs STORE, STYLE #, QUANTITY, RETAIL, W-COST columns.
// edge csv needs Vendor Style, Qty, Retail columns (no wholesale cost avail?).
// all other columns are ignored.
export default async function importInventory(s, user) {
  const progress = new Progress(
    'Import Inventory Counts',
    'Importing inventory counts',
    false
  )

  try {
    // get file type - throws error if unknown
    const filetype = getFileType(s) // 'kwi' or 'edge'

    // check for today's date - throws error if not
    if (filetype === 'kwi') {
      validateDate(s)
    }

    // ask user which store to use, if >1 avail
    const store = await chooseStore(user.stores)
    if (!store) return

    progress.setStatus('Step 1/4: Get CSV data and item dictionary...')

    // get rows with { itemId, locationId, price, cost, quantity }
    const warnings = {
      noCategory: [],
      multipleCategories: [],
      duplicateCodes: [],
      missingCodes: [],
    }
    const rows = await getImportRows(s, user, warnings, filetype, store)
    if (!rows) return // exit if user hit cancel

    // show message with any warnings, allow to cancel out
    if (!(await showWarnings(warnings))) return

    progress.setStatus('Step 2/4: Create inventory records...')
    const { mapLocationToInventoryId, rowsByInventoryItem } =
      await createInventoryRecords(store.id, rows)

    progress.setStatus('Step 3/4: Create inventory item records...')
    for (const locationId of Object.keys(mapLocationToInventoryId)) {
      const inventoryId = mapLocationToInventoryId[locationId]
      await addInventoryItems(inventoryId, locationId, rowsByInventoryItem)
    }

    progress.setStatus('Step 4/4: Update prices and costs...')
    await updatePricesAndCosts(rows, progress)
    progress.setSubStatus('')
  } catch (e) {
    const msg = (
      <span>
        Error processing inventory count file - please correct,{' '}
        <b>refresh the page (hit F5)</b> and try again. <br />
        <br /> {e.message}
      </span>
    )
    await alert(msg)
  } finally {
    progress.close()
  }
}

// create inventory records for the given rows' unique categories
async function createInventoryRecords(storeId, rows) {
  const locationIds = getUniqueLocationIds(rows)
  const mapLocationToInventoryId = {}
  const startTimestamp = new Date()
  for (const locationId of locationIds) {
    const statusId = Inventory.statusUnstaged
    const inventory = await Inventory.addRow({
      storeId,
      locationId,
      startTimestamp,
      statusId,
    })
    mapLocationToInventoryId[locationId] = inventory.id
  }
  // save the resulting inventoryIds to the rows
  for (const row of rows) {
    row.inventoryId = mapLocationToInventoryId[row.locationId]
  }
  const rowsByInventoryItem = {}
  for (const row of rows) {
    const key = row.inventoryId + ',' + row.itemId
    rowsByInventoryItem[key] = row
  }
  return { mapLocationToInventoryId, rowsByInventoryItem }
}

// get list of unique locations/categories from ivr
function getUniqueLocationIds(rows) {
  const locationIdDict = {}
  for (const row of rows) {
    locationIdDict[row.locationId] = 1
  }
  const locationIds = Object.keys(locationIdDict)
  return locationIds
}

// add records to inventoryItem table corresponding to locationItem table
async function addInventoryItems(inventoryId, locationId, rowsByInventoryItem) {
  const rows = await LocationItem.getRows({ locationId })
  const inventoryItems = rows.map(row => {
    const itemId = row.itemId
    const ivrItem = rowsByInventoryItem[inventoryId + ',' + itemId]
    const quantity = (ivrItem && ivrItem.quantity) || 0
    const price = (ivrItem && ivrItem.price) || row.price || 0
    const cost = (ivrItem && ivrItem.cost) || row.cost || 0
    return { inventoryId, itemId, quantity, price, cost }
  })
  await InventoryItem.addRows(inventoryItems)
}

/**
 * get rows with { itemId, locationId, price, quantity }
 * collects warning messages in arrays.
 * throws js errors if bad mistakes found.
 */
async function getImportRows(s, user, warnings, filetype, store) {
  console.log('getImportRows')

  // parse the csv rows
  let rows = parseCsv(filetype, s)
  console.log({ rows })

  // check store numbers - throws error if unexpected found
  if (filetype === 'kwi') {
    checkStoreNumbers(rows, store.number)
  }

  // const code = 'EG792016CZ-6510'

  // get all 10k items into dict for speed
  console.log(`get all items into dict`)
  const mapIdToItem = await Item.getMapIdToItem({ user })
  // console.log(Object.values(mapIdToItem).filter(item => item.code === code))

  // get map from code to item - we don't care about duplicates -
  // just need this to know if a code is in the db or not.
  console.log(`get map from code to item`)
  const duplicateCodes = {}
  const mapCodeToItemSimple = await Item.getMapCodeToItemSimple(
    { user },
    duplicateCodes
  )
  // console.log(mapCodeToItemSimple[code])

  // get mappings from code/style to categories and items
  console.log(`get mappings to items`)
  const codeMaps = await Location.getCodeMaps({ user, store }, mapIdToItem)
  const { mapCodeToCategories, mapCodeToItems } = codeMaps
  // console.log(mapCodeToCategories[code], mapCodeToItems[code])

  // get singular mappings and check for duplications/errors
  console.log(`get singular mappings`)
  const mapCodeToCategory = getMapCodeToCategory(
    mapCodeToCategories,
    rows,
    warnings
  )
  const mapCodeToItem = getMapCodeToItem(
    mapCodeToItems,
    mapCodeToItemSimple,
    rows,
    warnings
  )
  // console.log(mapCodeToCategory[code], mapCodeToItem[code])

  // set location (category/tray) and itemId associated with item code
  await addLocationIds(rows, mapCodeToCategory)
  await addItemIds(rows, mapCodeToItem)

  // remove rows with no categories.
  // these have already had warnings added.
  rows = rows.filter(row => row.locationId)
  rows = rows.filter(row => row.itemId)

  // flag rows with price or cost changes
  await flagPriceChanges(rows, mapCodeToItem)
  await flagCostChanges(rows, mapCodeToItem)

  return rows
}

/**
 * get singular mapping from item code to a category,
 * and collect warnings if multiple categories visible.
 */
function getMapCodeToCategory(mapCodeToCategories, rows, warnings) {
  const mapCodeToCategory = {}
  for (const row of rows) {
    const code = row.style
    const arr = mapCodeToCategories[code]
    if (!arr) {
      // code is not in any visible category - add to warnings
      const warning = { code }
      warnings.noCategory.push(warning)
    } else if (arr.length > 1) {
      // code is in too many categories - ambiguous
      // console.log({ row }) // { itemId, style, storeNumber, quantity, price, cost }
      const catNames = arr.map(cat => cat.name).join('; ')
      const warning = { code, catNames }
      warnings.multipleCategories.push(warning)
    } else {
      // item is just in ONE category - good
      const category = arr[0]
      mapCodeToCategory[code] = category
    }
  }
  return mapCodeToCategory
}

/**
 * get singular mapping from item code to item object,
 * and collect warnings if multiple items visible.
 */
function getMapCodeToItem(mapCodeToItems, mapCodeToItemSimple, rows, warnings) {
  const mapCodeToItem = {}
  for (const row of rows) {
    const code = row.style
    const arr = mapCodeToItems[code]
    if (!arr) {
      // code is not visible -
      // could be not in db or just not in a visible category.
      if (!mapCodeToItemSimple[code]) {
        const warning = { code }
        // will subtract these from noCategory
        warnings.missingCodes.push(warning)
      }
    } else if (arr.length > 1) {
      // have more than one item record for this code
      if (arr.every(item => item === arr[0])) {
        // if all items are the same, just return that item
        const item = arr[0]
        mapCodeToItem[code] = item
      } else {
        // otherwise we have ambiguity - add to warnings
        const itemNames = arr.map(item => item.name).join('; ')
        const warning = { code, itemNames }
        warnings.duplicateCodes.push(warning)
      }
    } else {
      // just have one item record for this code - good
      const item = arr[0]
      mapCodeToItem[code] = item
    }
  }
  // subtract missingCodes from noCategory warnings
  const missingCodesSet = new Set(warnings.missingCodes.map(mc => mc.code))
  warnings.noCategory = warnings.noCategory.filter(
    warning => !missingCodesSet.has(warning.code)
  )
  return mapCodeToItem
}

// retrieve date from first line to match today's date
function validateDate(s) {
  const filedateStr = s.slice(0, s.indexOf('--')).trim() // extract date from first line, eg "1/3/20"
  const filedate = moment(filedateStr)
  const today = moment()
  if (!filedate.isValid()) {
    throw new Error(
      `Unable to find a valid date value at the beginning of the report.`
    )
  } else if (!today.isSame(filedate, 'day')) {
    throw new Error(
      `The date at the beginning of the report (${filedateStr}) does not match today's date.`
    )
  }
}

// get rows with info for diff filetypes -
// kwi: { storeNumber, style, quantity, price, cost }
// edge: { style, quantity, price }
function parseCsv(filetype, s) {
  let csv
  if (filetype === 'kwi') {
    csv = s.slice(s.indexOf('STORE,')) // header starts at 3rd row
  } else {
    // edge
    csv = s
  }
  const result = Papa.parse(csv, { header: true, skipEmptyLines: true })
  let rows
  if (filetype === 'kwi') {
    // just keep some columns
    rows = result.data.map(row => ({
      storeNumber: row.STORE,
      style: row['STYLE #'] || row['STYLE'], // added STYLE 2022-06-10
      quantity: Number(row.QUANTITY),
      price: Number(row.RETAIL),
      cost: Number(row['W-COST']),
    }))
    // just keep rows with numeric store numbers != 0
    rows = rows.filter(row => {
      const n = Number(row.storeNumber)
      return !Number.isNaN(n) && n !== 0
    })
    // just keep rows without leading space in code/style
    rows = rows.filter(row => !row.style.startsWith(' '))
  } else {
    // Edge
    rows = result.data.map(row => ({
      style: row['Vendor Style'],
      quantity: Number(row.Qty),
      price: lib.getNumberFromCurrency(row.Retail), // parse '$50.00' -> 50
      // cost: // not in Edge csv
    }))
  }
  // "The parsed document is invalid.  Make sure you selected the correct store
  // and verify the expected document layout."
  return rows
}

// lookup storenum to get storeId for each row and set.
// throws error if doesn't match expectedStoreId, or unknown store num.
function checkStoreNumbers(rows, expectedStoreNumber) {
  for (const row of rows) {
    if (row.storeNumber !== expectedStoreNumber) {
      throw new Error(`Unexpected store number ${row.storeNumber}.`)
    }
  }
}

// find price changes and set flags
async function flagPriceChanges(rows, itemDict) {
  for (const row of rows) {
    const item = itemDict[row.style]
    if (item && item.price !== row.price) {
      row.priceChanged = true
    }
  }
}

// find cost changes and set flags
async function flagCostChanges(rows, itemDict) {
  for (const row of rows) {
    const item = itemDict[row.style]
    if (item && item.cost !== row.cost) {
      row.costChanged = true
    }
  }
}

// set locationId (ie category id) for each row of imported items.
// do this by getting a dictionary from itemId to locationId.
// if no category visible for an item, add to warning.
// async function addLocationIds(rows, mapItemIdToLocationId, warnings) {
async function addLocationIds(rows, mapCodeToCategory) {
  for (const row of rows) {
    const location = mapCodeToCategory[row.style]
    if (location) {
      row.locationId = location.id
    }
  }
}

// // lookup item codes/styles to get ids
// async function addItemIds(rows, mapCodeToItem, warnings) {
//   for (const row of rows) {
//     const item = mapCodeToItem[row.style]
//     row.itemId = item && item.id
//     if (item === undefined) {
//       const msg = `Item style not in database: ${row.style}`
//       warnings.push(msg)
//     }
//   }
// }

// set itemId for each row of imported items.
async function addItemIds(rows, mapCodeToItem) {
  for (const row of rows) {
    const item = mapCodeToItem[row.style]
    if (item) {
      row.itemId = item.id
    }
  }
}

function getFileType(s) {
  const firstline = s.slice(0, s.indexOf('\n'))
  if (firstline.includes('INVENTORY VALUE REPORT')) {
    return 'kwi'
  } else if (firstline.includes('Vendor Style')) {
    return 'edge'
  }
  throw new Error('Unknown file format')
}

// ask user for store to use if >1 visible
async function chooseStore(stores) {
  let storeId
  const options = stores.map(s => ({
    value: s.id,
    label: s.name,
  }))
  if (options.length > 1) {
    const defaultValue = options[0].value
    const ret = await getChoice(
      'Import Inventory Counts',
      "Select the store where you'd like to import the inventory counts:",
      options,
      defaultValue
    )
    if (ret.ok) {
      storeId = ret.value
    } else {
      return undefined
    }
  } else {
    storeId = options[0].value
  }
  storeId = Number(storeId)
  const store = stores.find(s => Number(s.id) === storeId)
  return store
}

async function showWarnings(warnings) {
  const { noCategory, multipleCategories, duplicateCodes, missingCodes } =
    warnings
  if (
    noCategory.length > 0 ||
    multipleCategories.length > 0 ||
    duplicateCodes.length > 0 ||
    missingCodes.length > 0
  ) {
    const style = { textAlign: 'left', paddingRight: '1em' }
    const msg = (
      <div className="import-inventory-warnings">
        <br />
        {multipleCategories.length > 0 && (
          <div>
            <div>
              The following items were detected in multiple categories - please
              correct them before importing. Otherwise counts could end up in
              the wrong category:
            </div>
            <br />
            <table>
              <thead>
                <tr>
                  <th style={style}>Code</th>
                  <th style={style}>Categories</th>
                </tr>
              </thead>
              <tbody>
                {multipleCategories.map(w => (
                  <tr key={w.code}>
                    <td style={style}>{w.code}</td>
                    <td style={style}>{w.catNames}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
          </div>
        )}
        {duplicateCodes.length > 0 && (
          <div>
            <hr />
            <div>
              The following codes have more than one version available - please
              correct them before importing. These will NOT be imported, as the
              item to use is ambiguous.
            </div>
            <br />
            <table>
              <thead>
                <tr>
                  <th style={style}>Code</th>
                  <th style={style}>Names</th>
                </tr>
              </thead>
              <tbody>
                {duplicateCodes.map(w => (
                  <tr key={w.code}>
                    <td style={style}>{w.code}</td>
                    <td style={style}>{w.itemNames}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
          </div>
        )}
        {noCategory.length > 0 && (
          <div>
            <hr />
            <div>
              The following item codes are in the database but not in any
              category visible to you, and will be ignored. You could add these
              items to a category if desired:
            </div>
            <br />
            {noCategory.map(w => (
              <div key={w.code}>{w.code}</div>
            ))}
            <br />
          </div>
        )}
        {missingCodes.length > 0 && (
          <div>
            <hr />
            <div>
              The following items are not in the database, or are not visible to
              you, and will be ignored. You could create these items if desired:
            </div>
            <br />
            {missingCodes.map(w => (
              <div key={w.code}>{w.code}</div>
            ))}
            <br />
          </div>
        )}
      </div>
    )
    const ok = await confirm('Import Inventory Counts', msg, 'Okay, continue')
    return ok
  }
  return true
}

async function updatePricesAndCosts(rows, progress) {
  const changedRows = rows.filter(row => row.priceChanged || row.costChanged)
  let i = 1
  let imax = changedRows.length
  for (const row of changedRows) {
    progress.setSubStatus(`${i} of ${imax}: ${row.style}`)
    if (row.priceChanged) {
      await Item.updateValue({
        id: row.itemId,
        field: 'price',
        value: row.price,
      })
    }
    if (row.costChanged) {
      await Item.updateValue({
        id: row.itemId,
        field: 'cost',
        value: row.cost,
      })
    }
    i += 1
  }
}
