import { useLocation } from 'react-router-dom'


// get appname from url - e.g. /order -> 'order', /wishlist/stores -> 'wishlist'
export default function useAppName() {
  const location = useLocation()
  const i = location.pathname.indexOf('/', 1)
  const appName = location.pathname.slice(1, i > -1 ? i : undefined)
  return appName
}
