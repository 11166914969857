// a semi-generic card container
// user creates a CardComponent and passes that in here

// extending cards2 to try to provide more control over ncols displayed
//. get rid of that version eventually


import React from 'react'
import Pressable from '../pressable2'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import './styles.scss'


export default ({ 
  cards, 
  CardComponent,
  clickCard, 
  pressCard, 
  highlightedCardId,
  highlightedCardIds = {}, // a dict or Map of ids - will supercede highlightedCardId
  handlers = {}, // handlers get passed to child cards //. incl click and press events
  noResultMessage = "No results",
  children, // optional child components, e.g. buttons
  ncols, // optional - number of columns to display
  context = {}, // optional extra data to pass to cards
}) => {

  // const [timedOut, setTimedOut] = useState(false) //. implement this

  const loading = (cards === null)
  const noResults = !loading && cards && (cards.length === 0)
  // const showDescription = !loading && !noResults

  const { height, width } = useWindowDimensions()

  // const style = { width: "calc(calc(100% - 1em) / 1)" }
  // const style = { width: "100%" }
  const style = { width: "100%" }
  if (!ncols) {
    if (width > 900) {
      style.width = "calc(calc(100% - 4em) / 4)"
      style.margin = "0 0.5em 1em 0.5em"
    } else if (width > 700) {
      style.width = "calc(calc(100% - 3em) / 3)"
      style.margin = "0 0.5em 1em 0.5em"
    } else if (width > 450) {
      style.width = "calc(calc(100% - 2em) / 2)"
      style.margin = "0 0.5em 1em 0.5em"
    }
  }
  console.log(ncols, style)

  return (
    <>
      <div className="cards3">
        {cards && cards.length > 0 && <div className="cards3-list">
          {cards.map((card, index) => (
            <Pressable
              className="card3-container"
              clickCard={clickCard}
              pressCard={pressCard}
              key={card.id}
              id={card.id}
              index={index}
              style={style}
            >
              <CardComponent
                card={card}
                highlighted={highlightedCardId === card.id || highlightedCardIds[card.id] || (highlightedCardIds.has && highlightedCardIds.has(card.id))}
                handlers={handlers}
                id={card.id}
                context={context}
              />
            </Pressable>
          ))}
        </div>}
        {children}
      </div>

      {loading && <div className="cards3-message">Loading...</div>}
      {noResults && <div className="cards3-message">{noResultMessage}</div>}
      {/* {timedOut && <div className="cards-message">Network problems?</div>} */}
    </>
  )
}
