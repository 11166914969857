// Show a progress dialog with status message and a Cancel button

import React from 'react'
import { openDialog, closeDialog } from '../react-async-dialog'
import './styles.css'


export default class Progress {
  constructor(title, message, showCancel=true) {
    this.cancelPressed = false
    openDialog(ProgressComponent, {
      title, 
      message, 
      clickCancel: showCancel && this.clickCancel.bind(this),
    })
    this.statusEl = document.querySelector(".reactAsyncDialog-container .progress-status")
    this.subStatusEl = document.querySelector(".reactAsyncDialog-container .progress-substatus")
  }
  setStatus(status) {
    this.statusEl.textContent = status
  }
  setSubStatus(subStatus) {
    this.subStatusEl.textContent = subStatus
  }
  clickCancel() {
    this.cancelPressed = true
  }
  close() {
    closeDialog()
  }
}


function ProgressComponent({ title, message, status, substatus, clickCancel }) {
  return (
    <div className="progress">
      <div className="progress-title">{title}</div>
      <div className="progress-message">{message}</div>
      <div className="progress-status">{status}</div>
      <div className="progress-substatus">{substatus}</div>
      {clickCancel && <button id="cancel" onClick={clickCancel}>Cancel</button>}
    </div>
  )
}
