import React from 'react'
import { openDialog, closeDialog } from '../react-async-dialog'
import './styles.scss'


export default async function alert(message) {
  return new Promise(resolve => {
    openDialog(Alert, { message, resolve })
  })
}


function Alert({ message, resolve }) {

  function clickOK() {
    closeDialog()
    resolve(true)
  }
  
  return (
    <div className="alert">
      <div className="message">{message}</div>
      <button id="ok" onClick={clickOK}>OK</button>
    </div>
  )
}
