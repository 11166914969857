import React from 'react'
import Cards from '../../packages/cards3'
import { Link } from 'react-router-dom'
import { useGlobals } from '../../globals'
import { MdFavorite as HeartSolid } from 'react-icons/md'
import './styles.scss'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  orderState,
  summaryState,
  minimumForFreeShipping,
  wishlistState,
} from '../../recoil/order'
import showImage from '../../packages/showImage'
import useAppName from '../../hooks/useAppName'

const quantityOptions = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
]

export default function OrderFavorites() {
  const appName = useAppName() // 'order' or 'wishlist'

  const globals = useGlobals()
  globals.breadcrumbs = 'Favorites'

  const [cards, setCards] = React.useState(null)
  const [order, setOrder] = useRecoilState(orderState)
  const [wishlist, setWishlist] = useRecoilState(wishlistState)
  const { items } = appName === 'order' ? order : wishlist
  const nitems = Object.keys(items).length
  const summary = useRecoilValue(summaryState)
  const context = { appName }
  const [disableContinue, setDisableContinue] = React.useState(true)

  // scroll to top on page load
  React.useEffect(() => {
    document.querySelector('.orderApp-contents').scrollTop = 0
  }, [])

  // when array of items changes, update cards
  React.useEffect(() => {
    const cards = Object.values(items)
    setCards(cards)
    updateDisableContinue()
  }, [items])

  // remove item from favs
  function clickHeart(event) {
    const itemId = Number(event.currentTarget.id)
    const itemsCopy = { ...items }
    delete itemsCopy[itemId]
    if (appName === 'order') {
      setOrder({ ...order, items: itemsCopy })
    } else {
      setWishlist({ ...wishlist, items: itemsCopy })
    }
    updateDisableContinue()
  }

  function changeQuantity(event) {
    const itemId = Number(event.currentTarget.id)
    const quantity = Number(event.currentTarget.value)
    if (appName === 'order') {
      setOrder({
        ...order,
        items: { ...items, [itemId]: { ...items[itemId], quantity } },
      })
    } else {
      setWishlist({
        ...wishlist,
        items: { ...items, [itemId]: { ...items[itemId], quantity } },
      })
    }
    updateDisableContinue()
  }

  function changeSize(event) {
    const itemId = Number(event.currentTarget.id)
    const size = event.currentTarget.value
    if (appName === 'order') {
      setOrder({
        ...order,
        items: { ...items, [itemId]: { ...items[itemId], size } },
      })
    } else {
      setWishlist({
        ...wishlist,
        items: { ...items, [itemId]: { ...items[itemId], size } },
      })
    }
    updateDisableContinue()
  }

  function updateDisableContinue() {
    console.log(Object.values(items))
    const disableContinue =
      nitems === 0 ||
      Object.values(items).some(item => item.size === 'none' || !item.size)
    setDisableContinue(disableContinue)
  }

  // show image in dialog
  // copied - keep in synch with OrderItems
  async function clickImage(event) {
    event.stopPropagation()
    const itemId = Number(event.currentTarget.id)
    const card = cards.find(card => card.id === itemId)
    const title = card.name
    const message = 'Scroll to see complete image, if needed.'
    const imageUrl = card.largeImageUrl
    await showImage(title, message, imageUrl)
  }

  return (
    <div className="OrderFavorites">
      <div className="header">
        {appName === 'wishlist' && (
          <div>
            Create your Pandora Wishlist while you wait in line; once it's your
            turn, show your associate in-store for faster checkout!
            <br />
            <br />
          </div>
        )}
        {nitems > 0
          ? 'Tap image to enlarge, tap heart to remove.'
          : 'No items favorited.'}
      </div>

      {nitems > 0 && (
        <div className="items">
          <Cards
            cards={cards}
            CardComponent={ItemCard} // defined below
            noResultMessage=""
            ncols={1}
            handlers={{ changeQuantity, changeSize, clickImage, clickHeart }}
            context={context}
          />
          <p>Please note: selection available in store may vary.</p>
        </div>
      )}

      {appName === 'order' && nitems > 0 && (
        <table className="totals">
          <thead></thead>
          <tbody>
            <tr>
              <td>Subtotal</td>
              <td>{summary.subtotal}</td>
            </tr>
            <tr>
              <td>Shipping</td>
              <td>{summary.shipping}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>{summary.total}</td>
            </tr>
          </tbody>
        </table>
      )}

      {appName === 'order' && (
        <div className="continue">
          <p>TAX NOT INCLUDED</p>
          <p>
            Product availability varies by store. Pandora and Pandora retailers
            are not liable for printing errors or changes in item numbers and
            prices.
          </p>
          <p>
            See store associate to confirm current pricing and product
            availability.
          </p>
          <p>
            Shipping is FREE on orders over ${minimumForFreeShipping}, or make
            an easy in-store pickup.
          </p>
          {disableContinue && (
            <p>Please select a size for each item to continue.</p>
          )}
          <Link to="/order/address" disabled={disableContinue}>
            <button disabled={disableContinue}>Continue to Contact Info</button>
          </Link>
        </div>
      )}
    </div>
  )
}

function ItemCard({ card, id, handlers, context }) {
  const item = card
  const { appName } = context
  return (
    <div className="card3">
      <span className="image" onClick={handlers.clickImage} id={id}>
        <img
          src={item.imageUrl}
          alt=""
          onError={e => {
            e.target.onerror = null
            e.target.src = 'images/image.png'
          }}
        />
      </span>
      <div className="contents">
        <div className="name">{card.name}</div>
        <div className="code">{card.code}</div>

        <table className="sizeAndQuantity">
          <tbody>
            {appName === 'order' && (
              <tr>
                <td>Quantity</td>
                <td>
                  <select
                    name="quantity"
                    id="quantity"
                    defaultValue={card.quantity}
                    onChange={handlers.changeQuantity}
                    id={id}
                  >
                    {quantityOptions.map(quantity => {
                      return (
                        <option value={quantity} key={'qty' + quantity}>
                          {quantity}
                        </option>
                      )
                    })}
                  </select>
                </td>
              </tr>
            )}
            <tr>
              <td>Size/Price</td>
              <td>
                <select
                  className="size-price"
                  name="size"
                  id="size"
                  defaultValue={card.size}
                  onChange={handlers.changeSize}
                  id={id}
                >
                  <option value="none">Size</option>
                  {Object.keys(card.sizeTable).map(size => {
                    const price = card.sizeTable[size]
                    return (
                      <option value={size} key={'size' + size}>
                        {/* {size || ''}{' '} */}
                        {size === 'sizeless' ? '' : size || ''}{' '}
                        {price ? '($' + price + ' each)' : '(price TBD)'}
                      </option>
                    )
                  })}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <span className="heart red" onClick={handlers.clickHeart} id={id}>
        <HeartSolid />
      </span>
    </div>
  )
}
