import { gql } from 'apollo-boost'
import apolloClient from '../../services/apolloClient'


export default class ViewInventoryDiscrepancy {  
  
  // get finished inventories inbetween the start and end dates
  static async getRows({ startDate, endDate, storeIds }, cached=false) {
    const query = gql`
      query GetInventoryDiscrepancies(
        $startDate: timestamptz, 
        $endDate: timestamptz, 
        $storeIds: [Int!]
      ) {
        viewInventoryDiscrepancy(where: {
          storeId: {_in: $storeIds}, 
          endTimestamp: {_is_null: false, _gte: $startDate, _lte: $endDate}
        }, order_by: {endTimestamp: desc}) {
          inventoryId
          endTimestamp
          storeName
          storeId
          locationName
          absDiscrepancy
          priceDiscrepancy
          costDiscrepancy
        }
      }
    `
    const variables = { startDate, endDate, storeIds }
    const fetchPolicy = cached ? "cache-first" : "network-only"
    const { data } = await apolloClient.query({ query, variables, fetchPolicy })
    const rows = data.viewInventoryDiscrepancy // viewInventoryDiscrepancy is the view name
    return rows
  }

}
