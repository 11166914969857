import React from 'react'
import './styles.scss'


export default function Pagination({
  page, // current page
  setPage, 
  pageCount=null, // optional - number of pages
  preventNext,
}) {

  function clickFirst() {
    setPage(0)
  }

  function clickPrevious() {
    setPage(page => page - 1)
  }

  function clickNext() {
    console.log(preventNext);
    setPage(page => page + 1)
  }

  function clickLast() {
    setPage(pageCount - 1)
  }

  return (
    <div className="pagination">
      <span>
        <button disabled={page===0} onClick={clickFirst}>&laquo; First</button>
        <button disabled={page===0} onClick={clickPrevious}>&#8249; Prev</button>
      </span>
      <span className="page">Page {page + 1}</span>
      <span>
        <button disabled={page===pageCount-1 || preventNext} onClick={clickNext}>Next &#8250;</button>
        <button disabled={pageCount===null || page===pageCount-1} onClick={clickLast}>Last &raquo;</button>
      </span>
    </div>
  )
}
