// getItem
// wrapper around the getObject dialog, 
// which is a generic dialog for editing objects.
// used to create and edit items.

import getObject from '../../packages/getObject'
import * as digitalOcean from '../../services/digitalOcean' // to fetch image
import * as constants from '../../constants'
import Entity from '../../models/entity'


export default async function getItem(title, instructions, item, user, readonly) {

  const allowPandora = user.canEditCorporateCategories || item.sourceId===Entity.corporationPandora
  const sources = allowPandora ? user.sources : user.sources.filter(source => source.id!==Entity.corporationPandora)
  const sourceOptions = sources.map(source => ({ value: source.id, label: source.name }))
  
  const fieldTypes = {
    sourceId: {
      name: "Source",
      type: "choice",
      // options: user.sources.map(source => ({ value: source.id, label: source.name })),
      options: sourceOptions,
      coerce: value => Number(value),
    },
    code: { validate: value => value && value.trim() > "" },
    name: { validate: value => value && value.trim() > "" },
    // price: { coerce: value => Number(value) }, // bug: can't enter "." or "1." etc with these
    // cost: { coerce: value => Number(value) },
    isActive: { type: 'boolean' },
    image: { type: "image", upload: true },
  }
  const obj = {
    sourceId: item.sourceId,
    code: item.code,
    name: item.name,
    color: item.color,
    barcode: item.barcode,
    price: item.price,
    cost: item.cost,
    isActive: item.isActive,
    image: constants.baseImageUrlLarge + item.code + '.jpg',
  }
  
  const ret = await getObject(title, instructions, obj, fieldTypes, readonly) // bring up dialog
  
  ret.item = ret.obj
  if (ret.ok) {
    // the dialog returns any uploaded file through the type object
    const file = fieldTypes.image.file
    if (file) {
      const filename = ret.item.code + '.jpg'
      digitalOcean.uploadFile("images/pandora/large/" + filename, file)
      const resizedFile = await resizeImage(file, 100, 100)
      digitalOcean.uploadFile("images/pandora/small/" + filename, resizedFile)
      // put the file on the return object also - used by EditCategory.jsx etc
      ret.imageFile = file
    }
  }
  return ret
}


// resize image - from stackoverflow
async function resizeImage(file, maxWidth, maxHeight) {
  const img = document.createElement("img")
  img.src = await new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = (e) => resolve(e.target.result)
    reader.readAsDataURL(file)
  })
  await new Promise(resolve => img.onload = resolve)
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")
  ctx.drawImage(img, 0, 0)
  let width = img.naturalWidth
  let height = img.naturalHeight
  if (width > height) {
    if (width > maxWidth) {
      height *= maxWidth / width
      width = maxWidth
    }
  } else {
    if (height > maxHeight) {
      width *= maxHeight / height
      height = maxHeight
    }
  }
  canvas.width = width
  canvas.height = height
  const ctx2 = canvas.getContext("2d")
  ctx2.drawImage(img, 0, 0, width, height)
  const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg', 0.95))
  const resizedFile = new File([blob], file.name, file)
  return resizedFile
}
